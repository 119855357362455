import React, { useState } from "react";
import {
  Paper,
  Typography,
} from "@mui/material";
import { MoreVert as MoreIcon } from "@mui/icons-material";
import classnames from "classnames";

// styles
import useStyles from "./styles";

export default function BottomActionBar({
  ...props
}) {
  var classes = useStyles();

  return (
    <div className={classes.bottomActionBarContainer}>
      {props.buttonsStack && props.buttonsStack}
    </div>
  );
}
