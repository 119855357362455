/** @flow */
import * as React from 'react';
import styled, { withTheme } from 'styled-components';
import ClipLoader from 'react-spinners/ClipLoader';

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const getSize = (size?: string) => {
  switch (size) {
    case 'large':
      return 150;
    case 'medium':
      return 80;
    case 'small':
      return 40;
    case 'xsmall':
      return 16;
    default:
      return 80;
  }
};

type Props = {
  size?: 'large' | 'medium' | 'small' | 'xsmall';
  color?: string;
  wrapperStyle?: Object;
  style?: Object;
  theme: Object;
}

function LoadingSpinner({ color, size, style, theme, wrapperStyle }: Props) {
  return (
    <Wrapper style={wrapperStyle}>
      <ClipLoader
        style={style}
        size={getSize(size)}
        color={color || theme.colors.primary}
        loading
      />
    </Wrapper>
  );
}

export default withTheme(LoadingSpinner);
