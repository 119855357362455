// @flow
import type { Node } from 'react';
import * as React from 'react';
import _ from 'lodash';

type Flag = 'RADIO_CONFIGURATION'
| 'SALES_PROMOTIONS'
| 'TARGET_LINE'
| 'QUALITATIVE'
| 'STREAMING_METRICS'
| 'FOLLO_WITHOUT_FOLLO'
| 'EDIT_RADIOCUSTOMER'
| 'UI_V2'
| 'METRICS_V2';

const DEV_FLAGS: { [flag: Flag]: boolean } = {
  FOLLO_WITHOUT_FOLLO: true,
  RADIO_CONFIGURATION: false,
  SALES_PROMOTIONS: false,
  TARGET_LINE: false,
  QUALITATIVE: false,
  STREAMING_METRICS: false,
  EDIT_RADIOCUSTOMER: true,
  UI_V2: false,
  METRICS_V2: false,
};

const ALWAYS_DISABLED_DEV_FLAGS = ['UI_V2', 'METRICS_V2'];

export function isDevFlagEnabled(flag: Flag): boolean {
  const isDev = process.env.REACT_APP_ENABLE_DEV_FLAGS === 'true';
  const isAlwaysDisabled = ALWAYS_DISABLED_DEV_FLAGS.includes(flag);
  return !isAlwaysDisabled && (isDev || Boolean(_.get(DEV_FLAGS, flag, true)));
}

type Props = {
  children: Node,
  flag: Flag,
  fallback?: Node,
};

function DevFlag(props: Props) {
  if (!isDevFlagEnabled(props.flag)) {
    return props.fallback || null;
  }

  return (
    <React.Fragment>
      {props.children}
    </React.Fragment>
  );
}

export default DevFlag;
