import './bluebirdConfig';

export const apiSettings = {
  url: process.env.REACT_APP_APIHOST,
  headers: {
    'X-Api-Key': process.env.REACT_APP_PUBLIC_APIKEY,
  },
};

export const payPalSetting = {
  clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
  clientSecret: process.env.REACT_APP_PAYPAL_SECRET,
};

export const websocketSettings = {
  url: process.env.REACT_APP_WEBSOCKETHOST,
};

export const version = {
  hashCommit: process.env.REACT_APP_COMMIT_HASH,
  tagName: process.env.REACT_APP_TAG_NAME,
  dateTime: process.env.REACT_APP_DEPLOY_DATETIME,
};

const cdnHost = process.env.REACT_APP_CDNHOST;
export const cdn = {
  maps: `${cdnHost}/maps`,
};

export const sentry = {
  dsn: process.env.REACT_APP_SENTRY_DSN,
  env: process.env.REACT_APP_SENTRY_ENV,
};

export const head = {
  titleTemplate: '%s - StatsRadio™',
  defaultTitle: "StatsRadio™ - Intelligence d'affaires pour radiodiffuseurs",
  meta: [
    {
      name: 'description',
      content:
        'StatsRadio™ modernise un des médias les plus efficaces en fournissant des statistiques d’écoute en temps réel. Nous avons créé un outil d’analyse d’audience simple et efficace accessible via une plateforme web.', // eslint-disable-line
    },
  ],
};

export const statsRadioFreshdeskWidgetId = process.env.REACT_APP_FRESHDESK_WIDGET_ID;
