import React, { useState } from "react";
import {
  Grid,
  FormControl,
  FormLabel,
  FormGroup,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,  
  Button,
  ButtonGroup
} from "@mui/material";
import { useTheme } from "@mui/styles";
import classnames from "classnames";
import { withTranslation } from 'react-i18next';

// styles
import useStyles from "./styles";

// components
import Widget from "../../components/Widget/Widget";
import { Typography } from "../../components/Wrappers/Wrappers";

import BottomActionBar from "../../components/BottomActionBar/BottomActionBar";
import PageTitle from "../../components/PageTitle/PageTitle";
import AgeRangeReaches from "../../common/domain/AgeRangeReaches";
import AgeRangeCard from "../../components/AgeRangeCard/AgeRangeCard"
import GenderCard from  "../../components/GenderCard/GenderCard"
//import type { GenderReachData } from '../../common/domain/GenderReach';
import mock from "../step01/mock";
import i18n from '../../i18n/i18n'

import {SauverStep02,CampaignContextSec}  from "../../context/CampaignContext";
import { obtInfoAdsSeller } from "../../context/UserContext";

var EnErreurStep02={
  ageRangeError: false,
  demographyError: false,
};


function Step02(props) {
  const { t } = props;
  var classes = useStyles();
  var IsDemographPresent:Boolean;
  var styleDemographie;
  var styleAgeGroup;

  var [error, setError] = useState(EnErreurStep02);
  var [infoSeller, setInfoSeller] = useState(obtInfoAdsSeller());

  const [AgeValue,setAgeValue] = useState(CampaignContextSec.age);
  const [Demography, setDemography] = useState({
    men: CampaignContextSec.genders.men,
    women: CampaignContextSec.genders.women,
  });

  const colors={
     female: '#5bbcb0',
     male: '#108479',
   }

  const pieData = [ 
    { name: 'Hommes', value: 10045, color: 'male' },
    { name: 'Femmes', value: 20455, color: 'female' },
  ];

  const [GenderData, setGenderData] = useState({
    male: 10045,
    female: 20455,
  });

  const { men, women } = Demography;

   
   var reachByAgeRange=AgeRangeReaches.parse({values:[{range:"18-24", reach:722, metadata:false},
   {range:"25-34", reach:1534, metadata:false},
   {range:"35-44", reach:2396, metadata:false},
   {range:"45-54", reach:4049, metadata:false},
   {range:"55-64", reach:3397, metadata:false},
   {range:"65+", reach:2106, metadata:false}]});

  const animate=true;

  if (infoSeller === undefined)  {
    IsDemographPresent=false;
    styleAgeGroup = {
      paperContainer: {
        backgroundImage: ``,
        backgroundRepeat: "no-repeat",
      },
    };
    
    styleDemographie = {
      paperContainer: {
        backgroundImage: ``,
        backgroundRepeat: "no-repeat",
      },
    };    

  }
  else {
    IsDemographPresent=true;
     styleAgeGroup= {
      paperContainer: {
        backgroundImage: `url(${infoSeller.obtImagAgeGroups(i18n.language)})`,  
        backgroundRepeat: "no-repeat",
      },
    };
    
    styleDemographie = {
      paperContainer: {
        backgroundImage: `url(${infoSeller.obtImagDemography(i18n.language)})`,
        backgroundRepeat: "no-repeat",
      },
    };    
  }

  if (CampaignContextSec.CampaignSave === true){
    props.history.push('/app/step07');
    return;
  }

  const ResetErr = () => {
    var tmp=  { ageRangeError: error.ageRangeError,
                demographyError: error.demographyError,
              };
     return tmp;
   }
   
  const CheckBoxCheckCountEx = (checkBox:object, exclu:string):integer =>{
    var Nb=0;
    Object.keys(checkBox).forEach(function(key, index) {
      if (key !== exclu)
      {
        if (checkBox[key] == true){
         Nb=Nb+1;
        }
      }
    });
   return Nb;
  }

  const handleAgeChange = (event) => {
    var TmpErr=ResetErr();
    TmpErr.ageRangeError=false;
    setAgeValue(event.target.value);
    setError(TmpErr);
  };

  const handleChangeDemongraphyCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
     var TmpErr=ResetErr();
     TmpErr.demographyError=false;
     setDemography({
        ...Demography,
        [event.target.name]: event.target.checked,
      });
      if (CheckBoxCheckCountEx(Demography,event.target.name)==0 && event.target.checked ==false){
        TmpErr.demographyError=true;
       }
       CampaignContextSec.IndUpdate=true;        
       setError(TmpErr);
  };   

  const IsStep02NotValid = () => {
      return AgeValue.length == 0|| CheckBoxCheckCountEx(Demography,'') == 0 ||
             error.ageRangeError || error.demographyError
  }

  var stat=mock.bigStat[0];

  return (
      <React.Fragment>
        <PageTitle title={t('sidebar.libStep02Title')} />

        <Grid container spacing={4}>
          <Grid item md="auto" sm="auto" xs={12}>
            <Widget
              title={t('step02Page.libEnterInfoStepTitle')} 
              upperTitle
              disableWidgetMenu
            >

      <div className={classes.formContainer}>
      <div className={classes.form}>
      <div className={classes.dashedBorder}>

        <div className={classes.controlContainer}>
           <div className={classnames(classes.controlCell, classes.borderRight)}>

              <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                      <FormLabel id="age-label" component="legend">{t('step02Page.radSelectAgeTitle')}</FormLabel>
                      {error.ageRangeError && (
                           <FormHelperText error>{t('step02Page.messSelectAge01')}<br></br>{t('step02Page.messSelectAge02')}</FormHelperText> 
                        )}       

                       <RadioGroup
                            aria-labelledby="age-label"
                                 size="lg"
                                 sx={{ gap: 1.5 }}
                                 value={AgeValue}
                                 onChange={handleAgeChange}        
                                >
                                   <FormControlLabel value="age18Plus" control={<Radio value="age18Plus" />} label={t('step02Page.radSelectAgeChoice01')}  />
                                   <FormControlLabel value="age1834" control={<Radio value="age1834" />} label={t('step02Page.radSelectAgeChoice02')} />
                                   <FormControlLabel value="age2554" control={<Radio value="age2554" />} label={t('step02Page.radSelectAgeChoice03')}/>
                                    <FormControlLabel value="age3564" control={<Radio value="age3564"  />} label={t('step02Page.radSelectAgeChoice04')} />
                                   <FormControlLabel value="age35Plus" control={<Radio value="age35Plus"  />} label={t('step02Page.radSelectAgeChoice05')} />
                       </RadioGroup>
                 </FormControl>
              </div>
              <div className={classnames(classes.controlCell, classes.borderRight)}>

                    <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                      <FormLabel component="legend">{t('step02Page.chkSelectDemogrTitle')}</FormLabel> 
                      {(error.demographyError || CheckBoxCheckCountEx(Demography,'') == 0) && (
                           <FormHelperText error>{t('step02Page.messSelectDemogr01')}<br></br>{t('step02Page.messSelectDemogr02')}</FormHelperText> 
                        )}
                      <FormGroup>
                        <FormControlLabel
                          control={ <Checkbox checked={men} onChange={handleChangeDemongraphyCheck} name="men" /> }
                          label={t('step02Page.chkSelectDemogrChoice01')}
                        />
                        <FormControlLabel
                          control={ <Checkbox checked={women} onChange={handleChangeDemongraphyCheck} name="women" /> }
                          label={t('step02Page.chkSelectDemogrChoice02')}
                        />
                      </FormGroup>
                      <FormLabel
                         id="Comnct-label"
                         sx={{
                         mt: 3,
                         mb: 2,
                         fontWeight: 'xl',
                         fontSize: 'xs',
                      }}
                       >
                         {t('step02Page.libSelectTips01')}<br></br>{t('step02Page.libSelectTips02')}<br></br>{t('step02Page.libSelectTips03')}
                      </FormLabel>   
                    </FormControl>

                </div>

             </div>
             <FormLabel component="legend" sx={{ mt: 4 }}>{t('commonStepPages.libFieldRequired')}</FormLabel>

      </div>
      </div>
      </div>
      
            </Widget>
          </Grid>

          
            {IsDemographPresent && (
             <Grid item md="auto" sm="auto" xs="auto">
               <Widget
                 title={t('step02Page.libDemogrDataTitle')+infoSeller.name}  
                 upperTitle
                 disableWidgetMenu
               >
                  <Grid container direction="column"
                        justifycontent="center"
                        alignItems="stretch" spacing={4}>
                      <Grid item>
                         <p>{t('step02Page.libAgeRangeTitle')}</p>             
                        <div className={classes.container1}>
                          <div className={classes.image1Container}  style={styleAgeGroup.paperContainer}></div>
                        </div>   
          
                      </Grid>
                   </Grid>


                   <Grid item alignItems="stretch">
                      <p>{t('step02Page.libDemogrTitle')}</p>           
                      <div className={classes.container1}>
                         <div className={classes.image1Container}  style={styleDemographie.paperContainer}></div>
                      </div>             
                  </Grid>

                </Widget>

              </Grid>
           )}

         </Grid>
         
         <BottomActionBar
             buttonsStack={
            <ButtonGroup disableElevation variant="contained">
              <Button 
                  variant="contained"
                  size="medium"
                  color="secondary"
                  onClick={() => {
                                   SauverStep02(AgeValue,Demography);
                                   props.history.push('/app/step01');                    
                           }}
               >
                {t('commonStepPages.butPrev')}
               </Button>
               <Button 
                  variant="contained"
                  size="medium"
                  color="secondary"
                  disabled={ IsStep02NotValid() }
                  onClick={() => {
                                   SauverStep02(AgeValue,Demography);
                                   props.history.push('/app/step03');                    
                          }}
               >
                {t('commonStepPages.butNext')}
               </Button>
             </ButtonGroup>
             }/>
      </React.Fragment>
    );
  }
  
  export default withTranslation()(Step02);


  // <FormControlLabel value="age18Plus" control={<Radio value="age18Plus" />} label="18 et plus" />
  // <FormControlLabel value="age18_24" control={<Radio value="age18_24" />} label="18-24" />
  // <FormControlLabel value="age25_34" control={<Radio value="age25_54" />} label="25-34" />
  // <FormControlLabel value="age35_44" control={<Radio value="age35_44"  />} label="35-44" />
  // <FormControlLabel value="age45_54" control={<Radio value="age45_54"  />} label="45-54" />
  // <FormControlLabel value="age55_64" control={<Radio value="age55_64"  />} label="55-64" />
  // <FormControlLabel value="age65Plus" control={<Radio value="age65Plus"  />} label="65 et plus" />

//   <FormLabel
//   id="Comnct-label"
//   sx={{
//   mt: 3,
//   mb: 2,
//   fontWeight: 'xl',
//   fontSize: 'xs',
//   }}
// >
// Plus votre cible est précise, moins vous<br></br>aurez d’impressions pour votre<br></br>investissement.<br></br>Pour le maximum d’impressions,<br></br>cochez 18 et plus<br></br>ainsi qu’hommes et femmes.
// </FormLabel>   