// @flow
import api from 'common/services/api';
import type { NewCampaignData, CampaignDescMessageData } from 'common/modules/campaign/domain/Campaign';
import Campaign from 'common/modules/campaign/domain/Campaign';
import FileTooLargeError from 'common/domain/FileTooLargeError';
import AgeRangeReachesAPIHelper from 'common/helpers/AgeRangeReachesAPIHelper';
import { Contract } from '../domain/contract';

export const MAX_AUDIO_FILE_SIZE_IN_MB = 5;
const BYTES_IN_A_MEGABYTE = 1000000;

class CampaignAPI {
  buildHeaders = (token: string) => ({ Authorization: 'Bearer ' + token });

  async uploadAudioFileMessage(token: string, callSign: string, campaignReference: string, audioFile: File): Promise<Campaign> {
    if (audioFile.size >= MAX_AUDIO_FILE_SIZE_IN_MB * BYTES_IN_A_MEGABYTE) {
      throw new FileTooLargeError();
    }

    const url = `/statsradio/${callSign}/adsfollo/${campaignReference}/upload-audio-file`;

    const formData = new FormData();
    formData.append('audioFile', audioFile);

    const headers = {
      ...this.buildHeaders(token),
      'Content-Type': 'multipart/form-data',
    };

    const { data } = await api.post(url, formData, { headers: headers });

    const json = this.assembleFlattenedData(data);

    return Campaign.parse(json);
  }

  async removeAudioFileMessage(token: string, callSign: string, campaignReference: string): Promise<Campaign> {
    const url = `/statsradio/${callSign}/adsfollo/${campaignReference}/remove-audio-file`;

    const { data } = await api.patch(url, {}, { headers: this.buildHeaders(token) });

    const json = this.assembleFlattenedData(data);

    return Campaign.parse(json);
  }

  async completeCampaign(token: string, callSign: string, reference: string, notifyAdvertiser?: boolean) {
    const url = `/statsradio/${callSign}/adsfollo/${reference}/complete`;
    const payload = { notifyAdvertiser };
    const { data } = await api.post(url, payload, { headers: this.buildHeaders(token) });

    const json = this.assembleFlattenedData(data);
    return Campaign.parse(json);
  }

  async updateCampaignDescMessage(token: string, callSign: string,  campaignReference:string, campaignDescMessageData: CampaignDescMessageData) {
    const campaignJson = Campaign.CampaignDescMessageDataAsJson(campaignDescMessageData);

    const body = {
      ...campaignJson,
    };

    const url = `/statsradio/${callSign}/adsfollo/${campaignReference}/update-campaign-desc-message`;

    const { data } = await api.post(url, body, { headers: this.buildHeaders(token) });

    if (data == "") {
  //    alert('OK');
      return "";
    }

    const json = this.assembleFlattenedData(data);

    return Campaign.parse(json);
  }

  async createNewCampaign(token: string, callSign: string, newCampaignData: NewCampaignData, contract: Contract) {
    const campaignJson = Campaign.newCampaignDataAsJson(newCampaignData);
    const contractJson = contract.toJSON();

    const body = {
      ...campaignJson,
      totalCosts: contract.getTotalCost(),
      spots: contract.getTotalSpots(),
      contract: contractJson,
    };

    const url = `/statsradio/${callSign}/adsfollo/`;
    const { data } = await api.post(url, body, { headers: this.buildHeaders(token) });

    if (data == "") {
  //    alert('OK');
      return "";
    }

    const json = this.assembleFlattenedData(data);

    return Campaign.parse(json);
  }

  async activateInFollo(token: string, callSign: string, reference: string) {
    const url = `/statsradio/${callSign}/adsfollo${reference}/activate-follo`;

    await api.post(url, {}, { headers: this.buildHeaders(token) });
  }

  assembleFlattenedData(data: Object) {
    if (!data.proposition) {
      return data;
    }

    return {
      ...data,
      proposition: {
        ...data.proposition,
        estimation: {
          ...data.proposition.estimation,
          ageGroup: AgeRangeReachesAPIHelper.flatToJson(data.proposition.estimation.ageGroup, data.proposition.estimation.ageGroupMetadata),
        },
      },
    };
  }

}

export default new CampaignAPI();
