// @flow
import api from 'common/services/api';
import type { FolloUserProfile } from 'domain/FolloUserProfile';
import CouldNotSignUpError from '../domain/CouldNotSignUpError';
import RadioCustomer from 'modules/customers/types/RadioCustomer';

class AdsFolloSignUpAPI {
  async signUp(folloUserProfile: FolloUserProfile, callSign:string) {
    const url = `/adsfollo/signup/${callSign}`;

    try {
      const { data } = await api.post(url, folloUserProfile);
      return data;
    } catch (e) {
        throw new CouldNotSignUpError();
    }
  }

    
  async signUpCustomer(folloUserProfile: FolloUserProfile, callSign:string) {
    const url = `/adsfollo/signup/${callSign}/addCustomer`;

    try {
      const { data } = await api.post(url, folloUserProfile);
      return RadioCustomer.parse(data);
    } catch (e) {
        throw new CouldNotSignUpError();
    }
  }

}

export default new AdsFolloSignUpAPI();
