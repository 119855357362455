// @flow
import AgeRangeReach, { type AgeRange, type AgeRangeReachJSONData } from './AgeRangeReach';

export type AgeRangeReachesData = {
  values: Array<AgeRangeReachJSONData>
}

export default class AgeRangeReaches {  è
  values: Array<AgeRangeReach>;

  constructor(reaches: Array<AgeRangeReach>) {
    this.values = reaches;
  }

  sum(): number {
    return this.values
              .map(r => r.reach)
              .reduce((a, b) => a + b, 0);
  }

  getPercentFor(ranges: Array<AgeRange>): number {
    const total = this.sum();

    const totalForRanges = this.values
                            .filter(r => r.hasRangeIn(ranges))
                            .map(r => r.reach)
                            .reduce((a, b) => a + b, 0);

    return totalForRanges / total;
  }

  asPercent(): Array<AgeRangeReach> {
    const total = this.sum();

    return this.values.map((ageRangeReach) => {
      const percent = ageRangeReach.reach / total;
      return new AgeRangeReach(ageRangeReach.range, percent, ageRangeReach.metadata);
    });
  }

  isEmpty(): boolean {
    return this.values.every(ageRangeReach => ageRangeReach.isEmpty());
  }

  hasInsufficientData(): boolean {
    return this.values.some(d => d.isEmpty());
  }

  toJSON(): AgeRangeReachesData {
    return {
      values: this.values.map(v => v.toJSON()),
    };
  }

  static parse(data: any): AgeRangeReaches {
    const reaches = data.values.map(d => AgeRangeReach.parseJSON(d));

    return new AgeRangeReaches(reaches);
  }

  static empty(): AgeRangeReaches {
    return new AgeRangeReaches([]);
  }
}
