// @flow
import _ from 'lodash';
import moment from 'moment';
import DateRange, { ISO_DATE } from 'common/domain/DateRange';
import { isDevFlagEnabled } from 'common/components/DevFlag';
import Radio from 'common/domain/radio';
import Proposition from 'common/modules/proposition/domain/proposition';
import Advertiser from 'common/modules/advertiser/domain/Advertiser';
import Salesman from 'common/modules/salesman/domain/Salesman';
import { ExternalCampaignListing } from 'modules/campaigns/domain/externalCampaign';
import RadioCustomer from 'modules/customers/types/RadioCustomer';
import CampaignMetrics from '../domain/CampaignMetrics';
import CampaignListing from './CampaignListing';

export type SortBy = 'NAME' | 'START_DATE';
export type CampaignState = 'BOOKED' | 'RUNNING' | 'COMPLETED';
export type CampaignFilter = 'booked' | 'running' | 'completed';
export type CampaignFilters = Array<CampaignFilter>;
export const ALL_FILTERS = ['booked', 'running', 'completed'];

export interface CampaignCustomer {
  name: string;
}

export type FetchCampaignOptions = {
  searchText?: string,
  sortBy?: SortBy,
  currentPage: number,
  limit: number,
  filterBy?: CampaignFilters,
}

export const FETCH_ALL_CAMPAIGNS: FetchCampaignOptions = {
  currentPage: 1,
  limit: 999999,
  filterBy: ALL_FILTERS,
};

export type NewCampaignData = {
  name: string,
  description?: string,
  message?: string,
  dateRange: DateRange,
  audioFilePath?: string,
  customer: ?RadioCustomer,
  giveAccessToFollo: boolean;
}

export type CampaignDescMessageData = {
  description?: string,
  message?: string,
}

export type CampaignData = {
  reference: string,
  name: string,
  description: string,
  dateRange: DateRange,
  advertiser: Advertiser,
  customer: CampaignCustomer,
  reach: ?number;
  impressions: ?number;
  averageFrequency: ?number;
  radio: Radio,
  linkedExternalCampaigns: ExternalCampaignListing[],
  salesman: Salesman,
  proposition?: ?Proposition,
  totalCost: number,
  numberOfSpotsSold: number,
  message: ?string,
  audioFilePath: ?string,
  completedAt: ?moment,
  message: string,
}

export interface AdvertisedCampaign {
  getAdvertiserName(): string;
}

export class InventoryCampaign implements AdvertisedCampaign {
  reference: string;
  name: string;
  advertiserName: string;

  constructor(reference: string, name: string, advertiserName: string) {
    this.reference = reference;
    this.name = name;
    this.advertiserName = advertiserName;
  }

  getAdvertiserName(): string {
    return this.advertiserName;
  }

  static parseForInventory(data: Object): InventoryCampaign {
    return new InventoryCampaign(data.reference, data.name, data.advertiserName);
  }
}

export default class Campaign implements AdvertisedCampaign {
  reference: string;
  name: string;
  description: string;
  dateRange: DateRange;
  advertiser: Advertiser; // TODO: STATS-1607 remove this
  customer: CampaignCustomer;
  reach: ?number;
  impressions: ?number;
  averageFrequency: ?number;
  radio: Radio;
  salesman: Salesman;
  proposition: ?Proposition;
  totalCost: number;
  numberOfSpotsSold: number;
  message: ?string;
  audioFilePath: ?string;
  completedAt: ?moment;
  message: string;
  linkedExternalCampaigns: ExternalCampaignListing[];

  constructor(data: CampaignData) {
    this.reference = data.reference;
    this.name = data.name;
    this.description = data.description;
    this.dateRange = data.dateRange;
    this.advertiser = data.advertiser;
    this.customer = data.customer;
    this.proposition = data.proposition;
    this.reach = data.reach;
    this.impressions = data.impressions;
    this.averageFrequency = data.averageFrequency;
    this.salesman = data.salesman;
    this.radio = data.radio;
    this.totalCost = data.totalCost;
    this.numberOfSpotsSold = data.numberOfSpotsSold;
    this.message = data.message;
    this.audioFilePath = data.audioFilePath;
    this.completedAt = data.completedAt;
    this.message = data.message;
    this.linkedExternalCampaigns = data.linkedExternalCampaigns;
  }

  getFullDateRange(metrics?: CampaignMetrics) {
    const campaignDateRange = this.dateRange;
    const metricsDateRange = metrics && metrics.getFullDateRange();

    if (!metricsDateRange) {
      return campaignDateRange;
    }

    return campaignDateRange.mergeWith(metricsDateRange);
  }

  getAdvertiserName(): string {
    return this.advertiser.name;
  }

  isBooked(): boolean {
    return this.dateRange.from.isAfter(moment());
  }

  isRunning(): boolean {
    return !this.isBooked() && !this.isCompleted();
  }

  shouldHaveCompleted(): boolean {
    return this.dateRange.to.isBefore(moment());
  }

  getShouldHaveCompletedTime() {
    if (!this.shouldHaveCompleted()) {
      throw new Error('campaign cannot be completed');
    }

    return this.dateRange.to.fromNow(true);
  }

  isCompleted(): boolean {
    return !!this.completedAt;
  }

  canBeCompleted(): boolean {
    return this.isRunning() && this.shouldHaveCompleted();
  }

  getName(): string {
    return this.name;
  }

  getStartAt(): moment {
    return this.dateRange.from;
  }

  getTotalCost(): number | void {
    return this.totalCost;
  }

  link(linkedExternalCampaigns: ExternalCampaignListing[]): void {
    this.linkedExternalCampaigns = linkedExternalCampaigns;
  }

  isLinked() {
    return this.linkedExternalCampaigns && this.linkedExternalCampaigns.length > 0;
  }

  toJSON(): Object {
    return {
      ...this,
      from: this.dateRange.from.format(ISO_DATE),
      to: this.dateRange.to.format(ISO_DATE),
      radio: this.radio.toJSON(),
      proposition: this.proposition && this.proposition.toJSON(),
    };
  }

  toCampaignListing(): CampaignListing {
    const linkedExternalCampaigns = this.linkedExternalCampaigns.map(c => c.getName());

    return new CampaignListing({
      reference: this.reference,
      name: this.name,
      callSign: this.radio.callSign,
      dateRange: this.dateRange,
      completedAt: this.completedAt,
      totalCost: this.totalCost,
      linkedExternalCampaigns,
      salesman: this.salesman,
      advertiser: this.advertiser,
    });
  }

  static parse(data: Object): Campaign {
    return new Campaign({
      reference: data.reference,
      name: data.name,
      description: data.description,
      dateRange: DateRange.fromISO({ from: data.from, to: data.to }),
      advertiser: Advertiser.parse(data.advertiser),
      reach: data.reach,
      impressions: data.impressions,
      averageFrequency: data.averageFrequency,
      radio: Radio.fromJSON(data.radio),
      linkedExternalCampaigns: data.linkedExternalCampaigns.map(l => new ExternalCampaignListing(l)),
      salesman: Salesman.parse(data.salesman),
      proposition: data.proposition && Proposition.parse(data.proposition),
      totalCost: data.totalCost,
      numberOfSpotsSold: data.numberOfSpotsSold,
      message: data.message,
      audioFilePath: data.audioFilePath,
      completedAt: data.completedAt,
      customer: data.customer,
    });
  }

  static areTheSame(a?: Campaign, b?: Campaign) {
    if (a === b) {
      return true;
    }
    if (!a || !b) {
      return false;
    }

    return a.reference === b.reference;
  }

  static CampaignDescMessageDataAsJson(data: CampaignDescMessageData): Object {

    return {
      description: data.description || '',
      message: data.message || '',
    };
  }



  static newCampaignDataAsJson(data: NewCampaignData): Object {
    const isoDateRange = data.dateRange.toISO();
    const giveAccessToFollo = !isDevFlagEnabled('FOLLO_WITHOUT_FOLLO') || data.giveAccessToFollo;

    if (!data.customer) {
      return {};
    }

    return {
      name: data.name,
      description: data.description || '',
      message: data.message || '',
      from: isoDateRange.from,
      to: isoDateRange.to,
      customerReference: data.customer.getReference(),
      giveAccessToFollo: giveAccessToFollo,
    };
  }

  static areEquals(a?: Campaign, b?: Campaign): boolean {
    return _.isEqual(a, b);
  }
}
