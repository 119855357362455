import React, { useState } from "react";
import {
  Grid,
  Button,
  ButtonGroup,
  Link
} from "@mui/material";
import {PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { payPalSetting } from 'common/config/config';
import { withTranslation } from 'react-i18next';

// styles
import useStyles from "./styles";

// components
import Widget from "../../components/Widget/Widget";
import BottomActionBar from "../../components/BottomActionBar/BottomActionBar";
import PageTitle from "../../components/PageTitle/PageTitle";

import {CampaignContextSec,SaveCampaign, UpdateCampaign, NewCampaign,ErreurCallAPI,FormatCampaignMessageForMail }  from "context/CampaignContext";
import FolloUserProfile from "domain/FolloUserProfile"
import {obtainUserProfile}  from "context/UserContext"    


var PayPalInitialOptions = {
  "client-id": payPalSetting.clientId,
  currency: "CAD",
  locale: "fr_CA",
  intent: "capture",
};

var CampaignCancel:boolean=false;

function Step07(props) {
  const { t } = props;
  var classes = useStyles();

 if (props.i18n.language !== 'fr' ){
    PayPalInitialOptions.locale='en_CA'
 }

 //var [error, setError] = useState(EnErreurStep07);
  var [campaignCancelValue, setCampaignCancel] = React.useState(false);    
  var [paymentDoneValue, setPaymentDoneValue] = useState(CampaignContextSec.PaiementDone);
  var [CampaignSaveValue, setCampaignSaveValue] = useState(CampaignContextSec.CampaignSave);
  var [ErreurCallApiValue, setErreurCallApiValue] = useState(false);

  CampaignContextSec.Calcul();

  return (

    <PayPalScriptProvider options={PayPalInitialOptions}>
      <React.Fragment>
        <PageTitle title={t('sidebar.libStep07Title')}/>

      {(paymentDoneValue === false && campaignCancelValue === false) && (
      <React.Fragment>
        <Grid container spacing={4}>
          <Grid item md={12} sm={12} xs={12}>
            <Widget
              title={t('step07Page.libEnterInfoStepTitle')} 
              upperTitle
              disableWidgetMenu
            >
      <div className={classes.formContainer}>
      <div className={classes.form}>
        <div className={classes.dashedBorder}>

           <Grid container spacing={2}>
             <Grid item xs={6}>
                <p>{t('step07Page.libCampaignName')} {CampaignContextSec.name}<br></br>
                   {t('step07Page.libOrderedOn')} {CampaignContextSec.getDateCommand()}<br></br>
                   {t('step07Page.libAdvertiser')} {CampaignContextSec.advertiserName}<br></br>
                   {t('step07Page.libContact')} {CampaignContextSec.contactName}<br></br>
                   {t('step07Page.libPhone')} {CampaignContextSec.phoneContact}<br></br>
                   {t('step07Page.libPhoneExt')} {CampaignContextSec.phoneExtensionContact}<br></br>
                   {t('step07Page.libObjective')} {CampaignContextSec.GetObjectiveLibel()}<br></br>
                   {t('step07Page.libDemogr')} {CampaignContextSec.GetGendersLibel()}<br></br>
                   {t('step07Page.libAgeGroup')} {CampaignContextSec.GetAgesGroupLibel()}<br></br>
                   {t('step07Page.libCallSign')}  {CampaignContextSec.GetStationsLibel()} </p>
                <p>{t('step07Page.libImpressionCount')} {CampaignContextSec.GetNbImpressionLibel()}<br></br>
                   {t('step07Page.libOccassCount')} {CampaignContextSec.GetNbSpot()}<br></br>
                   {t('step07Page.libPromoCode')}  {CampaignContextSec.promoCode}<br></br>
                   {t('step07Page.libPromoMessage')} {CampaignContextSec.getMessagePromo()}<br></br>
                   {t('step07Page.libInvest')} {CampaignContextSec.GetMontantLibel()}<br></br>
                   {t('step07Page.libProductMess')} {CampaignContextSec.GetMontantProdMessLibel()}</p>
                <p>{t('step07Page.libStartCampaign')} {CampaignContextSec.GetFromDate()}<br></br>
                   {t('step07Page.libEndCampaign')} {CampaignContextSec.GetToDate()}</p>

                   {CampaignContextSec.getFilePresent() === true  && (
                     <React.Fragment>
                       <p>{t('step07Page.libAudioFile')} {CampaignContextSec.audioFileName} </p>
                     </React.Fragment>
                   )}                
                   {CampaignContextSec.getFilePresent() === false  && (
                     <React.Fragment>
                       <p>{t('step07Page.libDuration')} {CampaignContextSec.spotDuration} {t('common.libSeconds')} <br></br>  
                          {t('step07Page.libVoice')} {CampaignContextSec.GetSpotVoiceLibel()}<br></br>
                          {t('step07Page.libSoundYtack')} {CampaignContextSec.GetSpotSoundTrackLibel()}</p>
                       <p>{t('step07Page.libText')}<br></br>{CampaignContextSec.spotText}</p>
                     </React.Fragment>
                   )}                
            </Grid>

             <Grid item xs={6}>
             <p>{t('step07Page.libAmountToPay')} {CampaignContextSec.GetMontantLibel()}<br></br>
                {t('step07Page.libProdMessAmountToPay')} {CampaignContextSec.GetMontantProdMessLibel()}<br></br>
                {t('step07Page.libDiscountToPay')} {CampaignContextSec.GetRabaisLibel()}<br></br>
                { CampaignContextSec.GetTaxeLibel().map((item)=>{
                    return <span>{item}<br></br></span>
                })}
                {t('step07Page.libTotalToPay')} {CampaignContextSec.GetTotalLibel()}<br></br><br></br>
                </p>

               { ((CampaignSaveValue == false) && (ErreurCallApiValue == false)) && (
                <Button 
                     variant="contained"
                     size="medium"
                     color="secondary"
                     onClick={() => {
                      var ProfileFollo:FolloUserProfile = obtainUserProfile();
                      SaveCampaign(ProfileFollo, t('step07Page.libCampaignSave'), t('step07Page.libNomPayeurCampaignSave') );      
                      if (ErreurCallAPI === false) {
                         setCampaignSaveValue(true);
                      }
                      else{
                        setErreurCallApiValue(ErreurCallAPI);
                      }
                     }}
                     >
                     {t('commonStepPages.butSaveConfirm')}
                 </Button>
                )} 

                { ErreurCallApiValue == true && (
                <Link
                  color={'primary'}
                  href={'mailto:support@statsradio.com?subject='+t('step07Page.messSubjectMailError')+'&body='+t('step07Page.messBodyMailError')+FormatCampaignMessageForMail()}
                  className={classes.linkError}
                >
                  {t('step07Page.messLinkMailError')}
                </Link>
                )}

                { ((CampaignSaveValue == true ) && (ErreurCallApiValue == false)) && (
                   <p>{t('step07Page.libPayYourCampaign')}</p>                
                )} 

                { ((CampaignSaveValue == true ) && (ErreurCallApiValue == false)) && (
                <PayPalButtons style={{ layout: "horizontal" }}
                  createOrder={ (data, actions) => {
                    var ProfileFollo:FolloUserProfile = obtainUserProfile();
                    return actions.order
                         .create({
                          purchase_units: [
                                 {
                                     description:CampaignContextSec.getDescPayPal(ProfileFollo.callSign) ,
                                     amount: {
                                         value: CampaignContextSec.Total,
                                     },
                                 },
                             ],
                         })
                         .then((orderId) => {
                             // Your code here after create the order
                             return orderId;
                         });
                    }}
                    onApprove={(data, actions) => {
                      return actions.order.capture().then((details) => {
                        var ProfileFollo:FolloUserProfile = obtainUserProfile();
                        const name = details.payer.name.given_name+" "+details.payer.name.surname;
                        UpdateCampaign(ProfileFollo, details.id, name);   
                        if (ErreurCallAPI === false) {
                            NewCampaign();
                            setPaymentDoneValue(true);
                            setCampaignSaveValue(false);                        
                            setCampaignCancel(false);
                        }
                        else{
                            setErreurCallApiValue(ErreurCallAPI);
                        }
                      });
                    }}                
                />
            )} 

            { ((CampaignSaveValue == true ) && (ErreurCallApiValue == false)) && (
            <Button 
                variant="contained"
                size="medium"
                color="secondary"
                onClick={() => {
                 var ProfileFollo:FolloUserProfile = obtainUserProfile();
                 UpdateCampaign(ProfileFollo, t('step07Page.libCampaignCancel'), t('step07Page.libNomPayeurCampaignSave'));   
                 if (ErreurCallAPI === false) {
                    NewCampaign();
                    setPaymentDoneValue(false);
                    setCampaignSaveValue(false);                        
                    setCampaignCancel(true);
                  }
                  else{
                      setErreurCallApiValue(ErreurCallAPI);
                 }
                }}
                >
                {t('commonStepPages.butCancelCampaign')}
            </Button>
           )} 

             </Grid>
           </Grid>

      </div>
      </div>
      </div>
      
            </Widget>
          </Grid>

         </Grid>
         { CampaignSaveValue == false && (

         <BottomActionBar
             buttonsStack={
            <ButtonGroup disableElevation variant="contained">
              <Button 
                             variant="contained"
                             size="medium"
                             color="secondary"
                             onClick={() => {
                                props.history.push('/app/step06');                    
                              }}
                            >
               {t('commonStepPages.butPrev')}
               </Button>
             </ButtonGroup>
             }
            />
            )} 
          </React.Fragment>            
          )} 


          { campaignCancelValue === true  && (

             <React.Fragment>

                <Grid container spacing={4}>
                   <Grid item md={12} sm={12} xs={12}>

                      <Widget
                        title={t('step07Page.libCancelCampaign')} 
                        upperTitle
                        disableWidgetMenu
                      >
                      <div className={classes.formContainer}>
                         <div className={classes.form}>
                             <div className={classes.dashedBorder}>
                                <Grid container spacing={2}>
                                   <Grid item xs={6}>
                                      <Button 
                                         variant="contained"
                                         size="medium"
                                         color="secondary"
                                         onClick={() => {
                                            props.history.push('/app/step01');                    
                                         }}
                                      >
                                       {t('commonStepPages.butNewCampaign')}
                                      </Button>
                                   </Grid>
                                 </Grid>
                              </div>
                           </div>
                       </div>
                     </Widget>
                  </Grid>
               </Grid>
            </React.Fragment>            
          )}
 
          {paymentDoneValue === true  && (
           <React.Fragment>

              <Grid container spacing={4}>
                  <Grid item md={12} sm={12} xs={12}>

                     <Widget
                         title={t('step07Page.libThankForPayYourCampaign')} 
                         upperTitle
                         disableWidgetMenu
                      >
                        <div className={classes.formContainer}>
                            <div className={classes.form}>
                                 <div className={classes.dashedBorder}>
                                   <Grid container spacing={2}>
                                     <Grid item xs={6}>
                                        <p>{t('step07Page.messReceiveEmail')}</p> 
                                        {CampaignContextSec.getFilePresent() === true  && (
                                           <React.Fragment>
                                              <p>{t('step07Page.messDelayReceiveYourSpot01')}</p>
                                           </React.Fragment>
                                         )}                
                                        {CampaignContextSec.getFilePresent() === false  && (
                                           <React.Fragment>
                                             <p>{t('step07Page.messDelayReceiveYourSpot02')}</p>
                                           </React.Fragment>
                                        )}                
                                       <p>{t('step07Page.messActivFollo')}</p>

                                        <Button 
                                          variant="contained"
                                          size="medium"
                                          color="secondary"
                                          onClick={() => {
                                            props.history.push('/app/step01');                    
                                         }}
                                        >
                                          {t('commonStepPages.butNewCampaign')}
                                        </Button>

                                     </Grid>
                                   </Grid>
                                  </div>
                             </div>
                         </div>
                      </Widget>
                   </Grid>
              </Grid>

           </React.Fragment>            
          )}

        </React.Fragment>
      </PayPalScriptProvider>      
    );
  }

export default withTranslation()(Step07);