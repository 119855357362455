// @flow

type Gender = 'male' | 'female';

export type GenderReachData = {
  [gender: Gender]: number
}

export default class GenderReach {
  female: number;
  male: number;

  constructor(female: number, male: number) {
    this.female = female;
    this.male = male;
  }

  isEmpty(): boolean {
    return this.female === 0 && this.male === 0;
  }

  hasEnoughData(): boolean {
    return this.female !== 0 && this.male !== 0;
  }

  toJSON(): GenderReachData {
    return {
      female: this.female,
      male: this.male,
    };
  }

  static parse(data: any) {
    return new GenderReach(data.female, data.male);
  }

  static empty(): GenderReach {
    return new GenderReach(0, 0);
  }
}
