// @flow
type CentralMarketData = {
  reference: string;
  name: string;
}

export class CentralMarket {
  reference: string;
  name: string;

  constructor(centralMarket: CentralMarketData) {
    this.reference = centralMarket.reference;
    this.name = centralMarket.name;
  }

  toJSON() {
    return {
      reference: this.reference,
      name: this.name,
    };
  }

  static parse(centralMarketData: CentralMarketData) {
    return new CentralMarket(centralMarketData);
  }
}
