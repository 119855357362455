import i18n from "i18n/i18n";

class InfoPromo {

    code: string;
    dateFin: string;
    particEffect: string;
    multiImpress: Integer;
    multiSpot: Integer;
    discountPercentage: number;

    constructor(data: { code: string, dateFin: string, particEffect: string, multiImpress: Integer, multiSpot: Integer, discountPercentage: number }) {
      if (data !== undefined ){  
         this.code = data.code;
         this.dateFin = data.dateFin;
         this.particEffect = data.particEffect;
         this.multiImpress = data.multiImpress;
         this.multiSpot = data.multiSpot;
         this.discountPercentage = data.discountPercentage;
      }
      else
      {
        this.code = "";
        this.dateFin = "";
        this.particEffect = "";
        this.multiImpress = "";
        this.multiSpot = 0;
        this.discountPercentage = 0;
     }
    }
  
    toObject(): Object {
      return {
        code: this.code,
        dateFin: this.dateFin,
        particEffect: this.particEffect,
        multiImpress: this.multiImpress,
        multiSpot: this.multiSpot,
        discountPercentage: this.discountPercentage,
      };
    }
  
    getDateFin():Date {
      return new Date(Date.parse(this.dateFin));
    }

    getMessRabais():String {
      if (this.particEffect == "E-POURC"){
        return i18n.t('common.messPromoPercent',{val:this.discountPercentage.toString()});
      }
      if (this.particEffect == "E-MUL"){
        return i18n.t('common.messPromoMulti',{val:this.discountPercentage.toString()});
      }
      return "";
    }

    static parse(data: Object): InfoPromo {
      return new InfoPromo(data);
    }
  }
  
export default InfoPromo;