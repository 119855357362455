/** @flow */
import axios from 'axios';
import qs from 'qs';
import i18n from 'i18next';

import { apiSettings } from 'common/config/config';

export const ISO_DATE = 'YYYY-MM-DD';

type HeaderOptions = {
  version?: string,
}

export function buildHeaders(token: string, options: HeaderOptions = {}) {
  return {
    Authorization: 'Bearer ' + token,
    Accept: options.version || 'application/json',
  };
}

axios.defaults.baseURL = apiSettings.url;

Object.keys(apiSettings.headers).forEach((item) => {
  axios.defaults.headers.common[item] = apiSettings.headers[item];
});

axios.defaults.paramsSerializer = (params) => {
  return qs.stringify(params, { arrayFormat: 'repeat' });
};

const get = (path: string, params: ?Object, config: ?Object) => {
  const conf = config || {};
  conf.headers = { ...conf.headers, 'X-Language': i18n.language };
  if (params) {
    conf.params = params;
  }

  return axios.get(path, conf);
};

const post = (path: string, data: mixed, config: ?Object) => {
  const conf = config || {};
  conf.headers = { ...conf.headers, 'X-Language': i18n.language };
  return axios.post(path, data, conf);
};

const del = (path: string, config: ?Object) => {
  const conf = config || {};
  conf.headers = { ...conf.headers, 'X-Language': i18n.language };
  return axios.delete(path, config || {});
};

const patch = (path: string, data: mixed, config: ?Object) => {
  const conf = config || {};
  conf.headers = { ...conf.headers, 'X-Language': i18n.language };
  return axios.patch(path, data, conf);
};

const put = (path: string, data: mixed, config: ?Object) => {
  const conf = config || {};
  conf.headers = { ...conf.headers, 'X-Language': i18n.language };
  return axios.put(path, data, conf);
};

export const setAuthorization = (token: string) => {
  axios.defaults.headers = {
    ...axios.defaults.headers,
    Authorization: `Bearer ${token}`,
  };
};

export const removeAuthorization = () => {
  delete axios.defaults.headers.Authorization;
};

export default {
  get,
  post,
  del,
  patch,
  put,
  setAuthorization,
  removeAuthorization,
};
