// @flow
import moment from 'moment';
import Radio from 'common/domain/radio';
import Estimation from './estimation';
import type { TotalSummary } from './estimation';
import DateRange from '../../../domain/DateRange';
import Salesman from '../../salesman/domain/Salesman';

const DATE_FORMAT = 'YYYY-MM-DD';
export const DRAFT_STATE = 'DRAFT';
export const PROPOSED_STATE = 'PROPOSED';
export const ACCEPTED_STATE = 'ACCEPTED';
export const CANCELLED_STATE = 'CANCELLED';
export type PropositionState = 'DRAFT' | 'PROPOSED' | 'ACCEPTED' | 'CANCELLED';

export type PropositionReference = | string;
export type SortBy = 'NAME' | 'START_DATE';

export type FetchPropositionOptions = {
  searchText?: string,
  filterBy?: Array<PropositionState>,
  sortBy?: SortBy,
  currentPage: number,
  limit: number,
}

export interface PropositionCustomer {
  reference: string;
  name: string;
  hasAccessToFollo: boolean;
}

type PropositionData = {
  reference: string,
  title: string,
  description: ?string,
  notes: ?string,
  dateRange: ?DateRange,
  customer: ?PropositionCustomer,
  campaignReference: ?string,
  proposedOn: ?moment,
  estimation: Estimation,
  state: PropositionState,
  radio: ?Radio,
  salesman: ?Salesman,
  totalCost: number;
}

export default class Proposition {
  reference: string;
  title: string;
  description: ?string;
  notes: ?string;
  dateRange: ?DateRange;
  customer: ?PropositionCustomer;
  campaignReference: ?string;
  proposedOn: ?moment;
  estimation: Estimation;
  state: PropositionState;
  radio: ?Radio;
  salesman: ?Salesman;
  totalCost: number;

  constructor(data: PropositionData) {
    this.reference = data.reference;
    this.title = data.title;
    this.description = data.description;
    this.notes = data.notes;
    this.dateRange = data.dateRange;
    this.customer = data.customer;
    this.campaignReference = data.campaignReference;
    this.proposedOn = data.proposedOn;
    this.estimation = data.estimation;
    this.state = data.state;
    this.radio = data.radio;
    this.salesman = data.salesman;
    this.totalCost = data.totalCost;
  }

  isAccepted(): boolean {
    return this.state === ACCEPTED_STATE;
  }

  isProposed(): boolean {
    return this.state === PROPOSED_STATE;
  }

  isCancelled(): boolean {
    return this.state === CANCELLED_STATE;
  }

  isDrafted(): boolean {
    return this.state === DRAFT_STATE;
  }

  is(reference: string) {
    return this.reference === reference;
  }

  cancel() {
    return new Proposition({ ...this, state: CANCELLED_STATE });
  }

  clone(): Proposition {
    return new Proposition({ ...this });
  }

  hasRequiredInformations() {
    const { title, customer, dateRange } = this;
    return title && customer && dateRange;
  }

  getTotalOpportunities(): number {
    return this.estimation.getTotalSummary().numberOfOpportunities;
  }

  getTotalImpressions(): number {
    return this.estimation.getTotalSummary().impressions;
  }

  getTotalSummary(): TotalSummary {
    return this.estimation.getTotalSummary();
  }

  toJSON(): Object {
    return {
      ...this,
      from: this.dateRange && this.dateRange.from.format(DATE_FORMAT),
      to: this.dateRange && this.dateRange.to.format(DATE_FORMAT),
      proposedOn: this.proposedOn && this.proposedOn.format(),
      estimation: this.estimation && this.estimation.toJSON(),
      radio: this.radio && this.radio.toJSON(),
    };
  }

  static parse(data: Object): Proposition {
    return new Proposition({
      reference: data.reference,
      title: data.title,
      description: data.description,
      notes: data.notes,
      dateRange: data.from && data.to && DateRange.fromISO({ from: data.from, to: data.to }),
      customer: data.customer,
      campaignReference: data.campaignReference,
      proposedOn: data.proposedOn && moment(data.proposedOn, moment.ISO_8601),
      estimation: data.estimation && Estimation.parse(data.estimation),
      state: data.state,
      radio: data.radio && Radio.fromJSON(data.radio),
      salesman: data.salesman && Salesman.parse(data.salesman),
      totalCost: data.totalCost,
    });
  }

  static replaceIn(propositions: Proposition[], proposition: Proposition) {
    const replacedIn: Array<Proposition> = propositions.slice();

    const index = replacedIn.findIndex(elem => elem.is(proposition.reference));
    replacedIn[index] = proposition;

    return replacedIn;
  }
}
