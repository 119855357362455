// @flow
import moment from 'moment';
import { WEEK_START, WEEK_END } from './week';

export const ISO_DATE = 'YYYY-MM-DD';

export type DateRangeData = {
  from: string;
  to: string;
};

export default class DateRange {
  from: moment;
  to: moment;

  constructor({ from, to }: { from: moment, to: moment }) {
    this.from = from;
    this.to = to;
  }

  trimEndToStartOfWeek() {
    const startOfWeek = this.to.clone().startOf('isoWeek');
    if (startOfWeek.isBefore(this.from)) {
      return this;
    }

    return new DateRange({ from: this.from, to: startOfWeek });
  }

  mergeWith(other?: DateRange) {
    if (!other) {
      return this;
    }

    const from = this.from.isBefore(other.from) ? this.from : other.from;
    const to = this.to.isAfter(other.to) ? this.to : other.to;

    return new DateRange({ from, to });
  }

  boundedToYesterday() {
    const toDate = moment.min(this.to, moment()
      .add(-1, 'days'));
    return new DateRange({ from: this.from, to: toDate });
  }

  getAllDates(): moment[] {
    const date = this.from.clone();
    const dates = [];

    while (date.isSameOrBefore(this.to)) {
      dates.push(date.clone());

      date.add(1, 'days');
    }

    return dates;
  }

  contains(date: moment): boolean {
    return date.isBetween(this.from, this.to, 'days', '[]');
  }

  getWeekCount(): number {
    return this.to.diff(this.from, 'weeks', true);
  }

  toISO(): { from: string, to: string } {
    return {
      from: this.from.format(ISO_DATE),
      to: this.to.format(ISO_DATE),
    };
  }

  equals(other: DateRange) {
    if (this === other) {
      return true;
    }

    if (this.from.isSame(other.from, 'day') && this.to.isSame(other.to, 'day')) {
      return true;
    }

    return false;
  }

  static fromISO({ from, to }: DateRangeData): DateRange {
    return new DateRange({ from: moment(from), to: moment(to) });
  }

  static default(): DateRange {
    return new DateRange({ from: moment(), to: moment() });
  }

  static nextNWeeks(n: number): DateRange {
    return new DateRange({
      from: moment()
        .day(WEEK_START + 7),
      to: moment()
        .day(WEEK_START + (7 * n) + 6),
    });
  }

  static fromLastNWeeks(numberOfWeek: number): DateRange {
    return new DateRange({
      from: moment().isoWeekday(WEEK_START).subtract(numberOfWeek, 'week'),
      to: moment().isoWeekday(WEEK_END).subtract(7, 'days'),
    });
  }

  static getLastNDays(numberOfDays: number, lastDate?: moment): DateRange {
    let to = lastDate;
    if (!to) {
      to = moment().subtract(1, 'day');
    }

    return new DateRange({
      from: to.clone().subtract(numberOfDays - 1, 'day'),
      to,
    });
  }

  static getWholeMonthFor(date: moment) {
    const startOfMonth = date.clone().startOf('month');
    const endOfMonth = date.clone().endOf('month');

    return new DateRange({ from: startOfMonth, to: endOfMonth });
  }
}

export const LAST_4_WEEKS = new DateRange({
  from: moment().subtract(4, 'week').startOf('isoWeek'),
  to: moment().subtract(1, 'week').endOf('isoWeek'),
});
