class InfoCpm {

    age18Plus: number;
    age1834: number;
    age2554: number;
    age3564: number;
    age35Plus: number;

    constructor(data: { age18Plus: number, age1834: number, age2554: number, age3564: number, age35Plus: number }) {
      if (data !== undefined){
         this.age18Plus = data.age18Plus;
         this.age1834 = data.age1834;
         this.age2554 = data.age2554;
         this.age3564 = data.age3564;
         this.age35Plus = data.age35Plus;
      }
      else
      {
        this.age18Plus = 0;
        this.age1834 = 0;
        this.age2554 =0;
        this.age3564 = 0;
        this.age35Plus = 0;
      }
    }
  
    toObject(): Object {
      return {
        age18Plus: this.age18Plus,
        age1834e: this.age1834,
        age2554: this.age2554,
        age3564: this.age3564,
        age35Plus: this.age35Plus
      };
    }
  
    static parse(data: Object): InfoCpm {
      return new InfoCpm(data);
    }
  }
  
export default InfoCpm;