// @flow
import api from 'common/services/api';
import RefreshToken from '../domain/RefreshToken';

function createRefreshTokenHeader(refreshToken: RefreshToken) {
  return {
    'X-Refresh-Token': refreshToken.value,
  };
}

class SessionAPI {

  async renewSession(refreshToken: RefreshToken): Promise<{ token: string, refreshToken: RefreshToken }> {
    const url = '/statsradio/authentication/is-authenticated';
    const { data } = await api.get(url, {}, { headers: createRefreshTokenHeader(refreshToken) });
    
    if (!data.accessToken || !data.refreshToken || !data.expiration) {
      throw data;
    }

    return {
      token: data.accessToken,
      refreshToken: new RefreshToken(data.refreshToken, data.accessToken, data.expiration),
    };
  }

}

export default new SessionAPI();
