// @flow
import api from 'common/services/api';

class AuthenticationAPI {

  buildHeaders(encodedCredentials: string): Object {
    return {
      'Authorization': 'Basic ' + encodedCredentials,
    };
  }

  async authenticate(email: string, password: string): Promise<*> {
    const url = '/statsradio/authentication/authenticate';

    const encodedEmail = encodeURIComponent(email);
    const encodedPassword = encodeURIComponent(password);
    const encodedData = window.btoa(encodedEmail + ':' + encodedPassword);

    const { data } = await api.post(url, {}, { headers: this.buildHeaders(encodedData) });

    return data;
  }

  async requestStationPasswordReset(email: string): Promise<*> {
    return await this._requestPasswordReset(email, 'radio');
  }

  async requestFolloPasswordReset(email: string): Promise<*> {
    return await this._requestPasswordReset(email, 'follo');
  }

  async _requestPasswordReset(email: string, type: 'radio' | 'follo'): Promise<*> {
    const url = `/statsradio/users/forgot-password/${type}`;
    const data = {
      email: email,
    };

    return await api.post(url, data);
  }

  async resetPassword(password: string, passwordValidation: string, token: string): Promise<*> {
    const url = `/statsradio/users/reset-password/?token=${token}`;
    const data = {
      password: password,
      passwordVerification: passwordValidation,
    };

    return await api.post(url, data);
  }

}

export default new AuthenticationAPI();
