// @flow
export type AgeRange = "18-24" | "25-34" | "35-44" | "45-54" | "55-64" | "65+";

export type AgeRangeReachMetadata = {
  interpolated: boolean;
}

export type AgeRangeReachJSONData = {
  range: AgeRange,
  reach: number,
  metadata?: ?AgeRangeReachMetadata,
};

export default class AgeRangeReach {
  range: AgeRange;
  reach: number;
  metadata: ?AgeRangeReachMetadata;

  constructor(range: AgeRange, reach: number, metadata: ?AgeRangeReachMetadata) {
    this.range = range;
    this.reach = reach;
    this.metadata = metadata;
  }

  hasRangeIn(ranges: Array<AgeRange>): boolean {
    return ranges.includes(this.range);
  }

  isEmpty(): boolean {
    return this.reach === 0;
  }

  isInterpolated(): boolean {
    if (!this.metadata) {
      return false;
    }

    return this.metadata.interpolated;
  }

  toJSON(): AgeRangeReachJSONData {
    return {
      range: this.range,
      reach: this.reach,
      metadata: this.metadata,
    };
  }

  static parseJSON(data: AgeRangeReachJSONData) {
    return new AgeRangeReach(data.range, data.reach, data.metadata);
  }
}
