import React, { useState, useEffect  } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Tabs,
  Tab,
  TextField,
  Fade,
  Link
} from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import Email from "domain/Email";
import FolloUserProfile from "domain/FolloUserProfile"
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import i18n from '../../i18n/i18n'

// styles
import useStyles from "./styles";
import { useTheme } from "@mui/styles";

// logo
import Images  from 'themes/Images';

// context
import { useUserDispatch, loginUser, CleanProfileLogin, obtainCallsSign, createProfile, forgotPassword, initInfoAdsSeller, obtInfoAdsSeller } from "../../context/UserContext";

var InfoProfile=new FolloUserProfile({
  advertiserName:"",
  firstName: "",
  surname:"",
  email: "",
  password: "",
  confirmPassword: "",
  language: "",
  callSign: "", //obtainCallsSign(),
});

var EnErreur={
  advertiserNameError: false,
  firstNameError: false,
  surnameError: false,
  emailError: false,
  emailNotValideError: false,
  emailForgotError: false,
  emailForgotNotValideError: false,
  passwordError: false,  
  passwordConfError: false,
  accesError: false, 
  createError: false,
  forgotError: false,
  OkForgotSend:false,
};

var TabCour:number=0;
var EnChargement=false;

function SetEnErreur(vIndErreur){
  EnErreur=vIndErreur;
}

function SetEnChargement(vIndCharge){
  EnChargement=vIndCharge;
}

function Login(props) {
  const { t } = props;
  var classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  var styles;

  // global
  var userDispatch = useUserDispatch();

  if (CleanProfileLogin()){
    InfoProfile=undefined;
    InfoProfile=new FolloUserProfile({
      advertiserName:"",
      firstName: "",
      surname:"",
      email: "",
      password: "",
      confirmPassword: "",
      language: "",
      callSign: obtainCallsSign(),      
    });
  }

  // local
  const [openDialogEmailChange, setOpenDialogEmailChange] = React.useState(false);    
  var [isLoading] = useState(EnChargement);
//  var [isLoading, setIsLoading] = useState(EnChargement);
  var [error, setError] = useState(EnErreur);
  var [activeTabId, setActiveTabId] = useState(TabCour);
  var [advertiserNameValue, setAdvertiserNameValue] = useState(InfoProfile.advertiserName);
  var [firstNameValue, setFirstNameValue] = useState(InfoProfile.firstName);
  var [SurnameValue, setSurnameValue] = useState(InfoProfile.surname);
  var [emailValue, setEmailValue] = useState(InfoProfile.email);
  var [emailForgotValue, setEmailForgotValue] = useState(InfoProfile.email);
  var [passwordValue, setPasswordValue] = useState(InfoProfile.password);
  var [confirmPasswordValue, setconfirmPasswordValue] = useState(InfoProfile.confirmPassword);
  var [infoSeller, setInfoSeller] = useState(obtInfoAdsSeller());

  useEffect(() => {
    if((infoSeller === undefined) && (isLoading === false)) {
      initInfoAdsSeller(userDispatch,  props.history, SetEnChargement, SetEnErreur); 
    }
    }, []);

  if (infoSeller === undefined)  {
      if (i18n.language == 'fr')
      {
        styles = {
            paperContainer: {
             backgroundImage: `url(${Images.logoLoginAdsFollo})`,  
             backgroundRepeat: "no-repeat",
           },
          }
      }
      else
      {
        styles = {
          paperContainer: {
           backgroundImage: `url(${Images.logoLoginAdsFolloen})`,  
           backgroundRepeat: "no-repeat",
         },
        }
      };
  }
  else {
    styles = {
      paperContainer: {
         backgroundImage: `url(${infoSeller.obtLogoLogon(i18n.language)})`,
         backgroundRepeat: "no-repeat",
         backgroundColor: infoSeller.headerColor,
       },
   }
 }

  const ResetErr = () => {
    var tmp=  { emailError: error.emailError,
                emailNotValideError: error.emailNotValideError,
                emailForgotErrorError: error.emailForgotErrorError,
                emailForgotNotValideError: error.emailForgotNotValideError,
                passwordError: error.passwordError,  
                accesError: false, 
                advertiserNameError: error.advertiserNameError,
                firstNameError: error.firstNameError,
                surnameError: error.surnameError,
                passwordConfError: error.passwordConfError,
                createError:false,
                forgotError:false,
                OkForgotSend:false,
              };
    return tmp;
  }

  const handleEmailChange = (email: string) => {
    var TmpErr=ResetErr();
    TmpErr.emailError=false;
    TmpErr.emailNotValideError=false;
    setEmailValue(email);
    if (email ==''){
      TmpErr.emailError=true;
    }
    else{
       if (!Email.isValid(email)) {
        TmpErr.emailNotValideError=true;
       }
    }
    setError(TmpErr);
  };

  const handleEmailForgotChange = (email: string) => {
    var TmpErr=ResetErr();
    TmpErr.emailForgotError=false;
    TmpErr.emailForgotNotValideError=false;
    setEmailForgotValue(email);
    if (email ==''){
      TmpErr.emailForgotError=true;
    }
    else{
       if (!Email.isValid(email)) {
        TmpErr.emailForgotNotValideError=true;
       }
    }
    setError(TmpErr);
  };

  const handlePasswordChange = (password: string) => {
    var TmpErr=ResetErr();
    TmpErr.passwordError=false;
    setPasswordValue(password);
    if (password ==''){
      TmpErr.passwordError=true;
    }
    setError(TmpErr);
  };

  const handlePassword2Change = (password: string, passwdConf: string) => {
    var TmpErr=ResetErr();
    TmpErr.passwordError=false;
    TmpErr.passwordConfError=false;
    setPasswordValue(password);
    if (password ==''){
      TmpErr.passwordError=true;
    }

    setconfirmPasswordValue(passwdConf);
    if (passwdConf ==''){
      TmpErr.passwordConfError=true;
    }
    else {
      if (passwdConf !== password ){
         TmpErr.passwordConfError=true;
      }
    }
    setError(TmpErr);
  };
  
  const handleAdvertiserNameChange = (advertiserName: string) => {
    var TmpErr=ResetErr();
    TmpErr.advertiserNameError=false;
    setAdvertiserNameValue(advertiserName);
    if (advertiserName ==''){
      TmpErr.advertiserNameError=true;
    }
    setError(TmpErr);
  };

  const  handleFirstNameChange = (firstName: string) => {
    var TmpErr=ResetErr();
    TmpErr.firstNameError=false;
    setFirstNameValue(firstName);
    if (firstName ==''){
      TmpErr.firstNameError=true;
    }
    setError(TmpErr);
  }; 
  
  const handleSurnameChange = (Surname: string) => {
    var TmpErr=ResetErr();
    TmpErr.surnameError=false;
    setSurnameValue(Surname);
    if (Surname ==''){
      TmpErr.surnameError=true;
    }
    setError(TmpErr);
  }; 

  const handleCloseEmailChangeValid = () => {
    var TmpErr=ResetErr();
    TmpErr.emailForgotError=false;
    TmpErr.emailForgotNotValideError=false;
    setError(TmpErr);        

     if (forgotPassword(userDispatch,
                        SetEnChargement,
                        SetEnErreur,
                        emailForgotValue) ) {
        setEmailForgotValue("");
        setOpenDialogEmailChange(false);      
      }
    };

 const handleCloseEmailChangeAnnul = () => {
    setOpenDialogEmailChange(false);
    setEmailForgotValue("");
    var TmpErr=ResetErr();
    TmpErr.emailForgotError=false;
    TmpErr.emailForgotNotValideError=false;
    setError(TmpErr);
 };

  const IsLoginNotValid = () => {
    return emailValue.length === 0 || passwordValue.length === 0 ||  error.emailError || error.passwordError || error.emailNotValideError
  }

  const IsEmailForgotNotValid = () => {
    return emailForgotValue.length === 0  ||  error.emailForgotErrorError  || error.emailForgotNotValideError
  }

  const IsCreateNotValid = () => {
    return advertiserNameValue.length === 0 ||  firstNameValue.length === 0 ||  SurnameValue.length === 0 || 
           emailValue.length === 0 ||   passwordValue.length === 0 ||  confirmPasswordValue.length === 0 || 
           error.advertiserNameError || error.firstNameError ||  error.surnameError ||
           error.emailError || error.passwordError || error.emailNotValideError || error.passwordConfError
  }

  if (isLoading || infoSeller === undefined) {
    return false;
  }
  else {
    return (
    <Grid container className={classes.container}>
      <div
        className={classes.logotypeContainer}
        style={styles.paperContainer}
      ></div>
      <div className={classes.formContainer}>
        <div className={classes.form}>
          <Tabs
            value={activeTabId}
            onChange={(e, id) => setActiveTabId(id)}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab label={t('commonRegistration.tabConnName')} classes={{ root: classes.tab }} />
            <Tab label={t('commonRegistration.tabCreateName')} classes={{ root: classes.tab }} />
          </Tabs>
          {activeTabId === 0 && (
            <React.Fragment>
              <Typography
                variant="h1"
                className={classes.greeting}
                style={{ wordWrap: "break-word" }}
              >
                {t('commonRegistration.libCreateCampaign1')}<br />
                {t('commonRegistration.libCreateCampaign2')}
                <br />{t('commonRegistration.libCreateCampaign3')}

              </Typography>

              <div className={classes.loginFormBorder}>
                <TextField
                  id="email"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  required
                  value={emailValue}
                  onChange={(e) => handleEmailChange(e.target.value)  } 
                  margin="normal"
                  placeholder={t('commonRegistration.ediUserName')}
                  type="email"
                  fullWidth
                  helperText={error.emailError ? t('commonRegistration.messEnterEmail') : error.emailNotValideError ? t('commonRegistration.messEmailValid') : undefined} 
                  error={error.emailError || error.emailNotValideError}
                />
                <TextField
                  id="password"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  required
                  value={passwordValue}
                  onChange={(e) => handlePasswordChange(e.target.value)}  
                  margin="normal"
                  placeholder={t('commonRegistration.ediPassWord')}
                  type="password"
                  fullWidth
                  helperText={error.passwordError ? t('commonRegistration.messEnterPassword'): undefined}
                  error={error.passwordError}
                />
                <div className={classes.formButtons}>
                  {isLoading ? (
                    <CircularProgress
                      size={26}
                      className={classes.loginLoader}
                    />
                  ) : (
                    <Button
                      disabled={ IsLoginNotValid() }
                      onClick={e =>{
                        e.preventDefault();
                        TabCour=activeTabId;
                        InfoProfile.email=emailValue;
                        InfoProfile.password=passwordValue;
                        InfoProfile.advertiserName=advertiserNameValue;
                        InfoProfile.firstName=firstNameValue;
                        InfoProfile.surname=SurnameValue;
                        InfoProfile.confirmPassword=confirmPasswordValue;
                        InfoProfile.language=i18n.language;
  
                        loginUser(
                          userDispatch,
                          emailValue,
                          passwordValue,
                          props.history,
                          SetEnChargement,
                          SetEnErreur,
                        );

                        }
                      }
                      variant="contained"
                      size="large"
                      className={classes.loginbutton}
                    >
                      {t('commonRegistration.butConnTitle')}
                    </Button>
                  )}
                </div>

                <Fade in={error.accesError}>
                   <Typography color="secondary" className={classes.errorMessage}>
                    {t('commonRegistration.messInvalidAccount')}
                   </Typography>
                </Fade>

                <Link
                     color={'primary'}
                     href={t('commonRegistration.adrLinkTuto')}  
                     className={classes.link}
                     target="_blank"
                     rel="noopener"
                    >
                      {t('commonRegistration.messVisuTotu')} <br></br>
                      {t('commonRegistration.messVisuTotu2')} 
                  </Link>

                <div className={classes.formButtons}>
                  <Button
                    color="primary"
                    size="large"
                    className={classes.forgetButton}
                    onClick={e =>{
                      e.preventDefault();
                      setOpenDialogEmailChange(true);  
                      }
                    }
                  >
                     {t('commonRegistration.butForgotPwTitle')}
                  </Button>
                  <Fade in={error.OkForgotSend}>
                     <Typography color="secondary" className={classes.OkMessage}>
                       {t('commonRegistration.messSendInitPw')}
                     </Typography>
                  </Fade>
                </div>
              </div>
            </React.Fragment>
          )}
          {activeTabId === 1 && (
            <React.Fragment>
              <Typography variant="h1" className={classes.greeting}>
                 {t('commonRegistration.libWelcome')}
              </Typography>
              <Typography variant="h2" className={classes.subGreeting}>
                 {t('commonRegistration.libCreateAccount')}
              </Typography>
              <TextField
                id="CompanyName"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                required
                value={advertiserNameValue}
                onChange={(e) => handleAdvertiserNameChange (e.target.value)} 
                margin="normal"
                placeholder={t('commonRegistration.ediEnterprise')}
                type="text"
                fullWidth 
                helperText={error.advertiserNameError ?  t('commonRegistration.messEnterEnterprise'): undefined}
                error={error.advertiserNameError}
              />

              <TextField
                id="name"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                required
                value={firstNameValue}
                onChange={(e) => handleFirstNameChange(e.target.value)} 
                margin="normal"
                placeholder={t('commonRegistration.ediFirstName')}
                type="text"
                fullWidth
                helperText={error.firstNameError ? t('commonRegistration.messEnterFirstName') : undefined}
                error={error.firstNameError}
              />

              <TextField
                id="surname"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                required
                value={SurnameValue}
                onChange={(e) => handleSurnameChange(e.target.value)}
                margin="normal"
                placeholder={t('commonRegistration.ediSurname')} 
                type="text"
                fullWidth
                helperText={error.surnameError ?  t('commonRegistration.messEnterSurname') : undefined}
                error={error.surnameError}
              />

              <TextField
                id="email"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                required
                value={emailValue}
                onChange={(e) => handleEmailChange(e.target.value)  } 
                margin="normal"
                placeholder={t('commonRegistration.ediEmail')} 
                type="email"
                fullWidth
                helperText={error.emailError ? t('commonRegistration.messEnterEmail'): error.emailNotValideError ? t('commonRegistration.messValidEmail') : undefined} 
                error={error.emailError || error.emailNotValideError}
              />
              <TextField
                id="password"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                required
                value={passwordValue}
                onChange={(e) => handlePassword2Change(e.target.value,confirmPasswordValue)}  
                margin="normal"
                placeholder={t('commonRegistration.ediPassWord')}
                type="password"
                fullWidth
                helperText={error.passwordError ? t('commonRegistration.messEnterPassword'): undefined}
                error={error.passwordError}
            />
              <TextField
                id="passwordConf"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                required
                value={confirmPasswordValue}
                onChange={(e) => handlePassword2Change(passwordValue,e.target.value)}    
                margin="normal"
                placeholder= {t('commonRegistration.ediConfPassWord')}
                type="password"
                fullWidth
                helperText={error.passwordConfError ?  t('commonRegistration.messConfPassword') : undefined}
                error={error.passwordConfError}
              />
              <div className={classes.creatingButtonContainer}>
                {isLoading ? (
                  <CircularProgress size={26} />
                ) : (
                  <Button
                    onClick={e =>{
                      e.preventDefault();
                      TabCour=activeTabId;
                      InfoProfile.advertiserName=advertiserNameValue;
                      InfoProfile.firstName=firstNameValue;
                      InfoProfile.surname=SurnameValue;
                      InfoProfile.email=emailValue;
                      InfoProfile.password=passwordValue;
                      InfoProfile.confirmPassword=confirmPasswordValue;
                      InfoProfile.language=i18n.language;

                      createProfile(userDispatch, SetEnChargement, SetEnErreur, props.history, InfoProfile)
                      }
                    }
                    disabled={ IsCreateNotValid() }
                    size="large"
                    variant="contained"
                    color="primary"
                    fullWidth
                    className={classes.createAccountButton}
                  >
                    {t('commonRegistration.butCreateAccount')} 
                  </Button>
                )}
              </div>
              <Fade in={error.createError}>
                <Typography color="secondary" className={classes.errorMessage}>
                 {t('commonRegistration.messCreateAccountErr')}
                </Typography>
              </Fade>

            </React.Fragment>
          )}
        </div>
        <Typography color="primary" className={classes.copyright}>
          © {new Date().getFullYear()}{" "}
          <a
            style={{ textDecoration: "none", color: "inherit" }}
            href="https://StatsRadio.com"
            rel="noopener noreferrer"
            target="_blank"
          >
             {t('commonRegistration.libStatsRadio')} 
          </a>{" "}
          {t('commonRegistration.libTrademark')} 
        </Typography>
      </div>

    <Dialog
        fullScreen={fullScreen}
        open={openDialogEmailChange}
        onClose={handleCloseEmailChangeAnnul}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">  
          { t('commonRegistration.libForgotPwTitle') }
        </DialogTitle>
        <DialogContent>
            <TextField
                id="emailForgot"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                required
                value={emailForgotValue}
                onChange={(e) => handleEmailForgotChange(e.target.value)  } 
                margin="normal"
                label={t('commonRegistration.ediEmail')} 
                type="email"
                fullWidth
                helperText={error.emailForgotError ? t('commonRegistration.messEnterEmail') : error.emailForgotNotValideError ? t('commonRegistration.messValidEmail') : undefined} 
                error={error.emailForgotError || error.emailForgotNotValideError}
            />
           </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseEmailChangeAnnul}>
            {t('commonRegistration.butAbend')} 
          </Button>
          <Button onClick={handleCloseEmailChangeValid} autoFocus disabled={ IsEmailForgotNotValid() }>
            {t('commonRegistration.butValid')} 
          </Button>
        </DialogActions>
      </Dialog> 
    </Grid>
  );
  }
}

export default withRouter(withTranslation()(Login));
