// @flow
import api from 'common/services/api';
import AdsSellerRadio from '../domain/AdsSellerRadio';

class AdsSellerRadioAPI {
  baseUrl = '/adsfollo/sellerradio/';
  
   async get(callSign: string):AdsSellerRadio {

    const url = this.baseUrl + callSign;

    const { data } = await api.get(url, {}, { })

    if (!data) {
      throw new Error("adsSellerRadio not found");
    }
    
    return  AdsSellerRadio.parse(data) ;
  }

  async getAll(): Promise<{ listAdsSellerRadio: AdsSellerRadio[] }> {
    const url = this.baseUrl + "getAll";
    const { data } = await api.get(url, {}, { }).then();
    

    if (!data) {
      throw data;
    }

    return {
      listAdsSellerRadio:  data.map(info => { return AdsSellerRadio.parse(info) })
    };
  }

}

export default new AdsSellerRadioAPI();


/**
  async get(callSign: string):AdsSellerRadio {
    const url = this.baseUrl + callSign;

    const { data } = await api.get(url, {}, { })
    .then(function (response) {
      // handle success
      console.log(response);
      return response;
    })
    .catch(function (error) {
      // handle error
      console.log(error);
      throw new Error("adsSellerRadio not found");
    })
    .finally(function () {
      // always executed
    });
    
    if (!data) {
      throw new Error("adsSellerRadio not found");
    }
    
    return  AdsSellerRadio.parse(data) ;
  }


    return api.get(url, {}, { })
    .then(function (response) {
      // handle success
      console.log(response);
      return response.data;
    })
    .catch(function (error) {
      // handle error
      console.log(error);
      throw new Error("adsSellerRadio not found");
    });

*/