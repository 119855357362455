// @flow

type SalesmanData = {
  reference: string,
  email: string,
  name: string,
  surname: string,
};

export default class Salesman {

  reference: string;
  email: string;
  name: string;
  surname: string;

  constructor(data: SalesmanData) {
    this.reference = data.reference;
    this.email = data.email;
    this.name = data.name;
    this.surname = data.surname;
  }

  is(other: Salesman) {
    return this.reference === other.reference;
  }

  toObject(): Object {
    const { reference, email, name, surname } = this;
    return { reference, email, name, surname };
  }

  static parse(data: Object): Salesman {
    return new Salesman({
      reference: data.reference,
      email: data.email,
      name: data.name,
      surname: data.surname,
    });
  }

}
