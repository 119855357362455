import React, { useState } from "react";
import {
  Grid,
  Button,
  ButtonGroup,
  FormHelperText,
  FormLabel
} from "@mui/material";

import frLocale from 'date-fns/locale/fr';
import enLocale from 'date-fns/locale/en-CA';

import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { useTheme } from "@mui/styles";
import classnames from "classnames";
import { withTranslation } from 'react-i18next';
import i18n from "i18n/i18n";


// styles
import useStyles from "./styles";

// components
import Widget from "../../components/Widget/Widget";
import BottomActionBar from "../../components/BottomActionBar/BottomActionBar";
import PageTitle from "../../components/PageTitle/PageTitle";

import {SauverStep05,CampaignContextSec}  from "../../context/CampaignContext";

var EnErreurStep05={
  fromDateError: false,
  toDateError: false,
};

function Step05(props) {
  const { t } = props;
  var classes = useStyles();
  var langLocale;

  if (i18n.language === 'fr') {
    langLocale=frLocale;
  }
  else {
    langLocale=enLocale;
  }

  var startMinDate=new Date();
  startMinDate.setDate(startMinDate.getDate() +5);
  startMinDate.setHours(0, 0, 0);

  var [error, setError] = useState(EnErreurStep05);
  const [FromValue, setFromValue] = useState(CampaignContextSec.fromDate);  
  const [ToValue, setToValue] = useState(CampaignContextSec.toDate);  
  

  if (CampaignContextSec.CampaignSave === true){
    props.history.push('/app/step07');
    return;
  }

  const ResetErr = () => {
    var tmp=  { fromDateError: error.fromDateError,
                toDateError: error.toDateError,
              };
     return tmp;
   }

   const ValidFromDate=(newValue):boolean => {
    return (newValue >= startMinDate);
  }

  const ValidToDate=(newValue):boolean => {
    return (newValue > FromValue);
  }

   const handleFromDateChange = (newValue) =>{
    var TmpErr=ResetErr();
    TmpErr.fromDateError=false;
    newValue.setHours(0, 0, 0);
    setFromValue(newValue);
    if (! ValidFromDate(newValue)) {
       TmpErr.fromDateError=true;
    }
    CampaignContextSec.IndUpdate=true;
    setError(TmpErr);
  }

  const handleToDateChange= (newValue) =>{
    var TmpErr=ResetErr();
    TmpErr.toDateError=false;
    newValue.setHours(0, 0, 0);
    setToValue(newValue);  
    if (!ValidToDate(newValue)) {
       TmpErr.toDateError=true;
    }
    CampaignContextSec.IndUpdate=true;
    setError(TmpErr);
  }

  const IsStep05NotValid = () => {
    return !ValidFromDate(FromValue) || !ValidToDate(ToValue) || error.fromDateError || error.toDateError;
  }
  
  return (
      <React.Fragment>
        <PageTitle title={t('sidebar.libStep05Title')} />

        <Grid container spacing={4}>
          <Grid item md={12} sm={12} xs={12}>
            <Widget
              title={t('step05Page.libEnterInfoStepTitle')} 
              upperTitle
              disableWidgetMenu
            >
              <div className={classes.formContainer}>
                 <div className={classes.form}>
                   <div className={classes.dashedBorder}>

                     <div className={classes.controlContainer}>
                       <div className={classnames(classes.controlCell, classes.borderRight)}>
                         <Grid container spacing={2}>
                           <Grid item xs={12}>
                             <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={langLocale}>  
                               <DatePicker
                                   
                                   label={t('step05Page.ediStartDate')} 
                                   value={FromValue}
                                   onChange={(newValue) => { handleFromDateChange(newValue);  }}
                                   renderInput={(params) => <TextField {...params}                                         
                                                                 helperText={error.fromDateError ? t('step05Page.messDelayBeforeStart'): undefined}
                                                                 error={error.fromDateError}
                                               />}
                               />
                             </LocalizationProvider>

                             <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={langLocale}>
                               <DatePicker  sx={{ ml: 3 }} 
                                   
                                   label={t('step05Page.ediEndDate')} 
                                   value={ToValue}
                                   onChange={(newValue) => { handleToDateChange(newValue); }}
                                   renderInput={(params) => <TextField {...params} 
                                                                 helperText={error.toDateError ? t('step05Page.messEndDateAfter') : undefined}
                                                                 error={error.toDateError}
                                   />}
                               />
                             </LocalizationProvider>
                             {IsStep05NotValid() == true && (
                                <FormHelperText error  >{t('step05Page.messModifyCampaignDates')}  </FormHelperText>
                           )}                      
                             
                           </Grid>
                         </Grid>
                       </div>
                     </div>

                     <FormLabel component="legend" sx={{ mt: 4 }}>{t('commonStepPages.libFieldRequired')}</FormLabel>

                    </div>
                 </div>
               </div>
            </Widget>
          </Grid>
        </Grid>

        <BottomActionBar
             buttonsStack={
            <ButtonGroup disableElevation variant="contained">
              <Button 
                  variant="contained"
                  size="medium"
                  color="secondary"
                  onClick={() => {
                                    SauverStep05(FromValue,ToValue);
                                    props.history.push('/app/step04');                    
                          }}
               >
                {t('commonStepPages.butPrev')}
               </Button>
               <Button 
                   variant="contained"
                   size="medium"
                   color="secondary"
                   disabled={ IsStep05NotValid() }
                   onClick={() => {
                                    SauverStep05(FromValue,ToValue);
                                    props.history.push('/app/step06');                    
                            }}
               >
                {t('commonStepPages.butNext')}
               </Button>
            </ButtonGroup>
            }/>
      </React.Fragment>
    );
  }

  export default withTranslation()(Step05);

//  <DateRangeWrapper>
//  <HistoryDateRangeSelector dateRanges={HistoryDateRange.allDateRanges()}
//    selectedDateRange={selectedDateRange} onSelectDateRange={this.onSelectDateRange}
//  />
// </DateRangeWrapper>

/* 
               <Button 
                variant="contained"
                size="medium"
                color="secondary"
                onClick={() => {
                  CampaignContextSec.setStep05(FromValue,ToValue);
                }}
                >
                Enregistrer
                </Button>

*/