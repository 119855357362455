import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem
} from "@mui/material";
import {
  Menu as MenuIcon,
  Person as AccountIcon,
  ArrowBack as ArrowBackIcon
} from "@mui/icons-material";
import classNames from "classnames";
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';

// styles
import useStyles from "./styles";
import Images  from '../../themes/Images';

// components
import { Typography } from "../Wrappers";


// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";
import { useUserDispatch, signOut, showProfile, obtainUserProfile, initInfoAdsSeller2, obtInfoAdsSeller } from "../../context/UserContext";

const Logo = styled.img`
  height: 54px;
  margin-top: 5px;
`;
//export default function Header(props) {

function Header(props) {
  const { t } = props;  
  var classes = useStyles();
  var FolloProfilCurr=obtainUserProfile();
  var infoSeller= obtInfoAdsSeller();
  var IsLogoPresent:Boolean;
  var styles;


  // global
  var layoutState = useLayoutState();
  var layoutDispatch = useLayoutDispatch();
  var userDispatch = useUserDispatch();

  var [infoSeller, setInfoSeller] = useState(obtInfoAdsSeller());

  useEffect(() => {
    initInfoAdsSeller2(userDispatch,props.history);
    }, [])    

  // local
  if (infoSeller === undefined)  {
   
    IsLogoPresent=false;
    styles = {
      toolbarHeader: { },
    };
  }
  else {
    styles = {
      toolbarHeader: {
         backgroundColor: infoSeller.headerColor,
       },
    };

    if (infoSeller.logo == "" || infoSeller.logo == null ){
      IsLogoPresent=false;
    }
    else {
      IsLogoPresent=true;
    }
  }

  var [profileMenu, setProfileMenu] = useState(null);

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar} style={styles.toolbarHeader}>
        <IconButton
          color="inherit"
          onClick={() => toggleSidebar(layoutDispatch)}
          className={classNames(
            classes.headerMenuButtonSandwich,
            classes.headerMenuButtonCollapse,
          )}
        >
          {layoutState.isSidebarOpened ? (
            <ArrowBackIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          ) : (
            <MenuIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          )}
        </IconButton>
        {IsLogoPresent === true && (   <Logo src={infoSeller.logo} alt="logo" style={{  margin: 'auto 20px' }} /> )}
        {IsLogoPresent === false && (  <Logo src={Images.logoFolloWhite} alt="logo" style={{  margin: 'auto 20px' }} /> )}

        
        <Typography variant="h6" weight="medium" className={classes.logotype}>
            {t('header.libTitle')}
        </Typography>
        <div className={classes.grow} />
       
        {IsLogoPresent === true && (  <Logo src={Images.logoFolloWhite} alt="logo" style={{  margin: 'auto 20px' }} /> )}

        <IconButton
          aria-haspopup="true"
          color="inherit"
          className={classes.headerMenuButton}
          aria-controls="profile-menu"
          onClick={e => setProfileMenu(e.currentTarget)}
        >
          <AccountIcon classes={{ root: classes.headerIcon }} />
        </IconButton>
        <Menu
          id="profile-menu"
          open={Boolean(profileMenu)}
          anchorEl={profileMenu}
          onClose={() => setProfileMenu(null)}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
        >
          <div className={classes.profileMenuUser}>
            <Typography variant="h4" weight="medium">
            {FolloProfilCurr.firstName} 
            </Typography>
            <Typography
              color="primary"
            >
             {FolloProfilCurr.advertiserName} 
            </Typography>
          </div>
          <MenuItem
            onClick={() => showProfile(props.history) }          
            className={classNames(
              classes.profileMenuItem,
              classes.headerMenuItem,
            )}
          >
            <AccountIcon className={classes.profileMenuIcon} /> {t('header.libProfil')}
          </MenuItem>
          <div className={classes.profileMenuUser}>
            <Typography
              className={classes.profileMenuLink}
              color="primary"
              onClick={() =>   signOut(userDispatch, props.history)   }
            >
              {t('header.mnuSignOut')}
            </Typography>
          </div>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}

export default withTranslation()(Header);