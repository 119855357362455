// @flow

type AdvertiserOwner = {
  email: string,
  name: string,
  surname: string,
};

type AdvertiserData = {
  reference: string,
  name: string,
  owner: AdvertiserOwner,
};

export default class Advertiser {

  reference: string;
  name: string;
  owner: AdvertiserOwner;

  constructor(data: AdvertiserData) {
    this.reference = data.reference;
    this.name = data.name;
    this.owner = data.owner;
  }

  getReference() {
    return this.reference;
  }

  is(other: Advertiser) {
    return this.reference === other.reference;
  }

  toObject(): Object {
    const { reference, name, owner } = this;
    return { reference, name, owner };
  }

  static parse(data: Object): Advertiser {
    return new Advertiser({
      reference: data.reference,
      name: data.name,
      owner: data.owner,
    });
  }

}
