import React, { useState } from "react";
import {
  Grid,
  FormControl,
  FormLabel,
  FormGroup,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  TextField,  
  Radio,
  RadioGroup,  
  Button,
  ButtonGroup
} from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from "@mui/styles";
import { withTranslation } from 'react-i18next';

// styles
import useStyles from "./styles";

// components
import Widget from "../../components/Widget/Widget";
import BottomActionBar from "../../components/BottomActionBar/BottomActionBar";
import PageTitle from "../../components/PageTitle/PageTitle";

import {NewCampaign,SauverStep01,CampaignContextSec}  from "../../context/CampaignContext";


var EnErreurStep01={
  campaignNameError: false,
  objectivesError: false,
};

function Step01(props) {
    const { t } = props;
    var classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    var [error, setError] = useState(EnErreurStep01);
    const [openDialogNewCampaign, setOpenDialogNewCampaign] = React.useState(false);    
    var [CampaignNameValue, setCampaignNameValue] = useState(CampaignContextSec.name);
    const [objective, setObjective] = useState(CampaignContextSec.objective);

    if (CampaignContextSec.CampaignSave === true){
      props.history.push('/app/step07');
      return;
    }

    const ResetErr = () => {
      var tmp=  { campaignNameError: error.campaignNameError,
                  objectivesError: error.objectivesError,
                  };
      return tmp;
    }

    const CheckBoxCheckCountEx = (checkBox:object, exclu:string):integer =>{
      var Nb=0;
      Object.keys(checkBox).forEach(function(key, index) {
        if (key !== exclu)
        {
          if (checkBox[key] == true){
           Nb=Nb+1;
          }
        }
      });
      return Nb;
    }

    const HandleOpenNewCampaign = () =>{
      setOpenDialogNewCampaign(true);
    }

    const handleCloseNewCampaignYes = () => {
      NewCampaign();
      setOpenDialogNewCampaign(false);
      setCampaignNameValue(CampaignContextSec.name);
      setObjective(CampaignContextSec.objective);
    };

    const handleCloseNewCampaignNo = () => {
      setOpenDialogNewCampaign(false);
    };

    const handleNameChange = (CampaignName: string) => {
      var TmpErr=ResetErr();
      TmpErr.campaignNameError=false;
      setCampaignNameValue(CampaignName);
      if (CampaignName ==''){
        TmpErr.campaignNameError=true;
      }
      CampaignContextSec.IndUpdate=true;
      setError(TmpErr);
    };

    const handleObjectifChange = (event) => {
      var TmpErr=ResetErr();
      TmpErr.objectivesError=false;
      setObjective(event.target.value);
      CampaignContextSec.IndUpdate=true;        
      setError(TmpErr);
    };      

    const IsStep01NotValid = () => {
      return CampaignNameValue.length === 0 || objective.length === 0 ||
             error.campaignNameError || error.objectivesError
    }

    return (

      <React.Fragment>
        <PageTitle title={t('sidebar.libStep01Title')}
        button={<Button
        variant="contained"
        size="medium"
        color="secondary"
        onClick={HandleOpenNewCampaign}        
      >
         {t('commonStepPages.butNew')}
      </Button>} />

        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Widget
              title={t('step01Page.libEnterInfoStepTitle')} 
              upperTitle
              disableWidgetMenu
            >
              <div className={classes.formContainer}>
                <div className={classes.form}>
                  <div className={classes.dashedBorder}>



                  <Grid container spacing={2}>
                   <Grid item xs={6}>
                      <TextField 
                        id="CampainName"
                        InputProps={{
                        classes: {
                            underline: classes.textFieldUnderline,
                            input: classes.textField,
                        },
                        }}
                        required
                        value={CampaignNameValue}
                        label={t('step01Page.ediCampaignName')} 
                        onChange={(e) => {handleNameChange(e.target.value); } }
                        margin="normal" 
                        type="text"
                        fullWidth
                        helperText={error.campaignNameError ? t('step01Page.messEnterCampaignName') : undefined}
                        error={error.campaignNameError}
                      />
                    </Grid>
                   <Grid item xs={6}>

                      <FormControl  id="objective-label" sx={{ mt: 3, mr: 3, mb: 3, ml: 3 }} component="fieldset" variant="standard">
                        <FormLabel component="legend">{t('step01Page.radObjective')}</FormLabel>
                        {error.objectivesError && (
                           <FormHelperText error>{t('step01Page.messSelectObjective')} </FormHelperText>
                        )}

                         <FormGroup>
                           <RadioGroup
                              aria-labelledby="objective-label"
                              size="lg"
                              sx={{ gap: 1.5 }}
                              value={objective}
                              onChange={handleObjectifChange}        
                              >
                                   <FormControlLabel value="N" control={<Radio value="N" />} label={t('step01Page.radChoiceNotoriety')} />
                                   <FormLabel component="legend" sx={{  mb: 1 }}><b>{t('step01Page.radChoiceNotoriety')}:</b> {t('step01Page.messChoiceNotoriety')}</FormLabel>
                                   <FormControlLabel value="C" control={<Radio value="C"  />} label={t('step01Page.radChoiceCallAction')} />  
                                   <FormLabel component="legend"><b>{t('step01Page.radChoiceCallAction')}:</b> {t('step01Page.messChoiceCallAction')}</FormLabel>
                          </RadioGroup>
                        </FormGroup>
                      </FormControl>


                  </Grid>
                </Grid>
                <FormLabel component="legend" sx={{ mt: 4 }}>{t('commonStepPages.libFieldRequired')}</FormLabel>  

                  </div>
                </div>
              </div>
      
            </Widget>
          </Grid>
         </Grid>

         <BottomActionBar
             buttonsStack={
            <ButtonGroup disableElevation variant="contained">
               <Button 
                   variant="contained"
                   size="medium"
                   color="secondary"
                   disabled={ IsStep01NotValid() }
                   onClick={() => {
                                    SauverStep01(CampaignNameValue,objective);
                                    props.history.push('/app/step02');
                            }}
                >
                {t('commonStepPages.butNext')}
               </Button>
             </ButtonGroup>
             } /> 

      <Dialog
        fullScreen={fullScreen}
        open={openDialogNewCampaign}
        onClose={handleCloseNewCampaignNo}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {t('commonStepPages.libFolloTitle')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('commonStepPages.messConfirmAnnulCamp')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseNewCampaignYes}>
            {t('commonStepPages.butYes')}
          </Button>
          <Button onClick={handleCloseNewCampaignNo} autoFocus>
            {t('commonStepPages.butNo')}
          </Button>
        </DialogActions>
      </Dialog>
      </React.Fragment>

    );
  }

export default withTranslation()(Step01);