import React, { useState, useEffect } from "react";
import { Drawer, IconButton, List } from "@mui/material";
import {
  Typography,
  Home as HomeIcon,
  Accessibility as AccessibilityIcon,
  Map as MapIcon,
  AttachMoney as AttachMoneyIcon,
//  NotificationsNone as NotificationsIcon,
//  FormatSize as TypographyIcon,
//  FilterNone as UIElementsIcon,
//  BorderAll as TableIcon,
  QuestionAnswer as SupportIcon,
  AccessTime as AccessTimeIcon,
  HelpOutline as FAQIcon,
  ArrowBack as ArrowBackIcon,
  Speaker as SpeakerIcon,
  Radio as RadioIcon,
  School as SchoolIcon,
  Payment as PaymentIcon
//  Stop as StopIcon,
} from "@mui/icons-material";
import { useTheme } from "@mui/styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import { withTranslation } from 'react-i18next';
import i18n from '../../i18n/i18n';

// styles
import useStyles from "./styles";

// components
import SidebarLink from "./components/SidebarLink/SidebarLink";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
  LinkActive
} from "../../context/LayoutContext";


var structure;

function initStructure() {
  structure = [

    { id: 0, type: "title", label: i18n.t('sidebar.libDevCampTitle') },
  
    { id: 1, label: i18n.t('sidebar.libStep01Title'), link: "/app/step01", icon: <HomeIcon />, NoLink:1 },
    { id: 2, label: i18n.t('sidebar.libStep02Title'), link: "/app/step02", icon: <AccessibilityIcon />, NoLink:2},
    { id: 3, label: i18n.t('sidebar.libStep03Title'), link: "/app/step03", icon: <MapIcon />, NoLink:3 },
    { id: 4, label: i18n.t('sidebar.libStep04Title'), link: "/app/step04", icon: <AttachMoneyIcon />, NoLink:4 },
    { id: 5, label: i18n.t('sidebar.libStep05Title'), link: "/app/step05", icon: <AccessTimeIcon />, NoLink:5},
    { id: 6, label: i18n.t('sidebar.libStep06Title'), link: "/app/step06", icon: <SpeakerIcon />, NoLink:6},
    { id: 7, label: i18n.t('sidebar.libStep07Title'), link: "/app/step07", icon: <PaymentIcon />, NoLink:7},
  
    { id: 8, type: "divider" },
  
    { id: 9, type: "title", label: i18n.t('sidebar.libMonitorCampTitle') }, 
  
    {
      id: 10,
      label: i18n.t('sidebar.libFollo') , 
      link: "https://follo.statsradio.com/login",
      icon: < RadioIcon/>,
    },
    { id: 11, type: "divider" },
    {
      id: 12, type: "linkExtern",
      label: i18n.t('sidebar.libTuto') , 
      link: i18n.t('commonRegistration.adrLinkTuto'),
      icon: <SchoolIcon />,
    },
    
    { id: 13, type: "divider" },
    { id: 14, type: "logo", label: i18n.t('sidebar.libPowerBy') },
      
  ];
}
  
function Sidebar({ location }) {
  var classes = useStyles();
  var theme = useTheme();

  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();

  // local
  var [isPermanent, setPermanent] = useState(true);

  useEffect(function() {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  initStructure();  

  return (
    <Drawer
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <List className={classes.sidebarList}>
        {structure.map(link => (
          <SidebarLink
            key={link.id}
            location={location}
            isSidebarOpened={isSidebarOpened}
            {...link}
          />
        ))}
      </List>
    </Drawer>
  );

  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(withTranslation()(Sidebar));
