// @flow
import * as React from 'react';
import numeral from 'numeral';
import i18n from 'i18next';

import { changeNumeralLocale } from 'i18n/numeral';

const SIGNIFICATIVE_NUMBERS = 3;

type Props = {
  value: any;
  locale?: string;
  style?: any;
  className?: string;
}

type NumberProps = Props & {
  roundAt?: number;
  suffix?: string;
  showPlusSign?: boolean;
};

type CurrencyProps = Props & {
  currency?: string,
  precision?: CurrencyPrecisionType
}

function applyDefaultsLocal(locale?: string) {
  const loc = locale || i18n.language;
  changeNumeralLocale(loc);
}

export function roundUpToNSignificativeDigits(value: number, n: number) {
  return Number(value.toPrecision(n));
}

export function displayNumber(value: any, roundAt?: number, locale?: string) {
  if (value === undefined) {
    return 'NaN';
  }

  applyDefaultsLocal(locale);

  let options = '0,0';
  if (roundAt && roundAt >= 0) {
    options = options + '.[' + '0'.repeat(roundAt) + ']';
  }

  return numeral(roundUpToNSignificativeDigits(value, SIGNIFICATIVE_NUMBERS)).format(options);
}

export const CurrencyPrecision = {
  DOLLARS: 'DOLLARS',
  CENTS: 'CENTS',
};
type CurrencyPrecisionType = $Values<typeof CurrencyPrecision>;


export function displayCurrency(value: any, locale?: string, precision: CurrencyPrecisionType = CurrencyPrecision.CENTS) {
  applyDefaultsLocal(locale);

  switch (precision) {
    case CurrencyPrecision.DOLLARS:
      return i18n.t('number.fullCurrency', { value: Number(value) });
    case CurrencyPrecision.CENTS:
    default:
      return i18n.t('number.currency', { value: Number(value) });
  }
}

export function displayPercent(value: any, locale?: string) {
  applyDefaultsLocal(locale);
  return i18n.t('number.percentage', { value: Number(value) });
}

export function Numeric(props: NumberProps) {
  const number = displayNumber(props.value, props.roundAt, props.locale);
  let toDisplay = number + (props.suffix ? ` ${props.suffix}` : '');
  toDisplay = props.value > 0 && props.showPlusSign ? '+' + toDisplay : toDisplay;

  return (
    <span className={props.className} style={{ ...props.style }}>
      {toDisplay}
    </span>
  );
}

export function Currency(props: CurrencyProps) {
  const currency = displayCurrency(props.value, props.locale, props.precision);

  return (
    <span className={props.className} style={{ ...props.style }}>
      {currency}
    </span>
  );
}

export function Percent(props: Props) {
//  const percentage = displayPercent(props.value, props.locale);
  const percentage = (props.value*100).toPrecision(2)+'%';

  return (
    <span className={props.className} style={{ ...props.style }}>
      {percentage}
    </span>
  );
}

