// @flow
import moment from 'moment';
import i18n from 'i18next';
import type { DayOfWeek } from './DayOfWeek';
import { translate, DAY_RENDER_FORMAT } from './DayOfWeek';
import DateRange from './DateRange';

export const WEEK: Array<DayOfWeek> = [
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
];

export const WEEKEND: Array<DayOfWeek> = [
  'SATURDAY',
  'SUNDAY',
];

export const ALL_WEEK: Array<DayOfWeek> = WEEK.concat(WEEKEND);

export const WEEK_START = 1; // moment().day(WEEK_START) --> +7 for next week, -7 for last week
export const WEEK_END = 7; // moment().day(WEEK_START) --> +7 for next week, -7 for last week

export const getDayOfWeek = (position: number): DayOfWeek => {
  return ALL_WEEK[position];
};

export const getPositionInWeek = (day: DayOfWeek) => {
  return ALL_WEEK.findIndex(compared => compared === day) + 1;
};

function isWeek(days: Array<DayOfWeek>) {
  return days.length === 5 && WEEK.every(day => days.includes(day));
}

function isWeekend(days: Array<DayOfWeek>) {
  return days.length === 2 && WEEKEND.every(day => days.includes(day));
}

export function formatDays(days: Array<DayOfWeek>, options?: { separator?: string, format?: DAY_RENDER_FORMAT } = {}) {
  const separator = options.separator || ', ';
  const format = options.format || DAY_RENDER_FORMAT.LONG;

  if (days.length === 0) {
    return 'none';
  }

  if (isWeek(days)) {
    return 'week';
  }

  if (isWeekend(days)) {
    return 'weekend';
  }

  if (days.length === 7) {
    return 'allWeek';
  }

  return days.map(day => translate(day, format)).join(separator);
}

export function asEntireWeeks({ from, to }: { from: moment, to: moment }) {
  const start = from.clone().startOf('isoWeek');
  const end = to.clone().endOf('isoWeek');
  return new DateRange({ from: start, to: end });
}
