class InfoTax {

    codeTax: string;
    taxPercent: number;

    constructor(data: { codeTax: string, taxPercent: number }) {
      if (data !== undefined ){  
         this.codeTax = data.codeTax;
         this.taxPercent = data.taxPercent;
      }
      else
      {
        this.codeTax = "";
        this.taxPercent = 0;
     }
    } 
  
    toObject(): Object {
      return {
        codeTax: this.codeTax,
        taxPercent: this.taxPercent,
      };
    }
  
    static parse(data: Object): InfoTax {
      return new InfoTax(data);
    }
  }
  
export default InfoTax;