import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  container: {
    height: "100vh",
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: 0,
    left: 0,
  },
  logotypeContainer: {
    // backgroundColor: theme.palette.primary.main,

    width: "60%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#49AAE2",
    backgroundSize: "80%",
    backgroundPosition: "center",

    [theme.breakpoints.down("lg")]: {
      //display: "none",
      width: "100%",
      height: "400px !important",
      backgroundSize: "60%",
      backgroundPosition: "center",
    },
    [theme.breakpoints.down("md")]: {
      //display: "none",
      width: "100%",
      height: "400px !important",
      backgroundSize: "80%",
      backgroundPosition: "center",
    },
    [theme.breakpoints.down("sm")]: {
      //display: "none",
      width: "100%",
      height: "400px !important",
      backgroundSize: "70%",
      backgroundPosition: "center",
    },
  },
  logotypeImage: {
    width: 165,
    marginBottom: theme.spacing(4),
  },
  logotypeText: {
    color: "white",
    fontWeight: 500,
    fontSize: 68,
    [theme.breakpoints.down("md")]: {
      fontSize: 32,
    },
  },
  formContainer: {
    width: "40%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
//    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("lg")]: {
      width: "50%",
      height: "auto",
      overflow: "unset"
    },
  },
  form: {
    width: 420,
    marginTop: 50,
  },
  tab: {
    fontWeight: 400,
    fontSize: 18,
  },
  loginFormBorder: {
    border: "#C9C9CA solid 2px",
    borderRadius: "7px",
    padding: "35px",
  },
  greeting: {
    fontWeight: 600,
    textAlign: "center",
    fontSize: "2rem !important",
    color: "#E03616",
    marginTop: `${theme.spacing(4)} !important`,
    marginBottom: `${theme.spacing(4)} !important`,
  },
  subGreeting: {
    fontWeight: 500,
    textAlign: "center",
    marginTop: theme.spacing(2),
  },
  googleButton: {
    marginTop: theme.spacing(6),
    boxShadow: theme.customShadows.widget,
    backgroundColor: "white",
    width: "100%",
    textTransform: "none",
  },
  googleButtonCreating: {
    marginTop: 0,
  },
  googleIcon: {
    width: 30,
    marginRight: theme.spacing(2),
  },
  creatingButtonContainer: {
    marginTop: theme.spacing(2.5),
    height: 46,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  createAccountButton: {
    height: 46,
    textTransform: "none",
    background: "#E03616",
    color: "#fff",
    fontWeight: "bold",
    fontSize: "20px",
  },

  loginbutton: {
    background: "#E03616",
    color: "#fff",
    fontWeight: "bold",
    fontSize: "20px",

  },
  formDividerContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: "flex",
    alignItems: "center",
  },
  formDividerWord: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  formDivider: {
    flexGrow: 1,
    height: 1,
    backgroundColor: theme.palette.text.hint + "40",
  },
  errorMessage: {
    textAlign: "center",
    fontWeight: "bold !important",    
    color: `${theme.palette.messErr.main} !important`,
  },
  OkMessage: {
    textAlign: "center",
    fontWeight: "bold !important",    
    color: `${theme.palette.success.main} !important`,
  },
  textFieldUnderline: {
    "&:before": {
      borderBottom: "none !important",
    },
    // "&:after": {
    //borderBottomColor: theme.palette.primary.main,
    // },
    "&:hover:before": {
      borderBottom: `none !important`,
    },
  },
  textField: {
    background: "#f1f1f1",
    borderRadius: "2px",
    //borderBottomColor: theme.palette.background.light,
    textAlign: "center",
    color: "black",
    padding: "10px",
  },
  formButtons: {
    width: "100%",
    marginTop: theme.spacing(4),
    display: "block",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "center",
  },
  forgetButton: {
    textTransform: "none",
    fontWeight: 400,
  },
  loginLoader: {
    marginLeft: theme.spacing(4),
  },
  link: {
    width: "100%",
    display: "block",
    textAlign: "center",
    justifyContent: "space-between",
  },
  copyright: {
    marginTop: theme.spacing(4),
    whiteSpace: "nowrap",
    [theme.breakpoints.up("md")]: {
//      position: "absolute",
      bottom: theme.spacing(2),
    },
  },
}));
