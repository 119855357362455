import React, { useState } from "react";
import {
  Grid,
  FormControl,
  FormLabel,
  FormGroup,
  Checkbox,
  FormControlLabel,
  FormHelperText,  
  Button,
  ButtonGroup
} from "@mui/material";
import { useTheme } from "@mui/styles";
import classnames from "classnames";
import { withTranslation } from 'react-i18next';
import i18n from '../../i18n/i18n'

// styles
import useStyles from "./styles";
import Images  from '../../themes/Images';
import styled from 'styled-components';

// components
import Widget from "../../components/Widget/Widget";
import BottomActionBar from "../../components/BottomActionBar/BottomActionBar";
import PageTitle from "../../components/PageTitle/PageTitle";


import {SauverStep03,CampaignContextSec}  from "../../context/CampaignContext";
import { obtInfoAdsSeller } from "../../context/UserContext";

const Logo = styled.img`
  height: 54px;
  margin-top: 5px;
`;

var EnErreurStep03={
  regionError: false,
};

function Step03(props) {
  const { t } = props;
  var classes = useStyles();
  var IsGeolocPresent:Boolean;
  var styleCarte;

  var [error, setError] = useState(EnErreurStep03);
  var [infoSeller, setInfoSeller] = useState(obtInfoAdsSeller());

  const [station, setStation] = useState({
    FM: CampaignContextSec.stations.FM,
    HD: CampaignContextSec.stations.HD,
  });

  const { FM, HD } = station;

   if (infoSeller === undefined)  {
    IsGeolocPresent=false;
    styleCarte = {
      paperContainer: {
        backgroundImage: ``,
        backgroundRepeat: "no-repeat",
      },
    };
  }
  else {
    IsGeolocPresent=true;
    styleCarte = {
      paperContainer: {
        backgroundImage: `url(${infoSeller.imagGeoAreaCover})`,
        backgroundRepeat: "no-repeat",
      },
    };
  }

  if (CampaignContextSec.CampaignSave === true){
    props.history.push('/app/step07');
    return;
  }


  const ResetErr = () => {
    var tmp=  { regionError: error.regionError,
              };
     return tmp;
   }

   const CheckBoxCheckCountEx = (checkBox:object, exclu:string):integer =>{
     var Nb=0;
     Object.keys(checkBox).forEach(function(key, index) {
       if (key !== exclu)
       {
         if (checkBox[key] == true){
          Nb=Nb+1;
         }
       }
     });
    return Nb;
   }

  const handleChangeStationCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
     var TmpErr=ResetErr();
     TmpErr.regionError=false;
     setStation({
        ...station,
        [event.target.name]: event.target.checked,
      });
      if (CheckBoxCheckCountEx(station,event.target.name)==0 && event.target.checked ==false){
        TmpErr.regionError=true;
       }
       CampaignContextSec.IndUpdate=true;        
       setError(TmpErr);
     };      

     const IsStep03NotValid = () => {
      return  error.regionError;

//      return CheckBoxCheckCountEx(station,'') == 0 || 
//             error.regionError;
     }

    return (
      <React.Fragment>
        <PageTitle title={t('sidebar.libStep03Title')} />

        <Grid container spacing={4}>
          {/* <Grid item md="auto" sm="auto" xs={12}>
            <Widget
              title={t('step03Page.libEnterInfoStepTitle')} 
              upperTitle
              disableWidgetMenu
            >
              <div className={classes.formContainer}>
                <div className={classes.form}>
                  <div className={classes.dashedBorder}>

                    <div className={classes.controlContainer}>
                       <div className={classnames(classes.controlCell, classes.borderRight)}>
                         <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                           <FormLabel component="legend">Sélectionner les stations voulues *</FormLabel>
                           {(error.regionError || CheckBoxCheckCountEx(station,'') == 0) && (
                             <FormHelperText error>Vous devez sélectionner au moins un choix<br></br>de station.</FormHelperText>
                           )}                      
                           <FormGroup>
                               <FormControlLabel
                                  control={ <Checkbox checked={FM} onChange={handleChangeStationCheck} name="FM" /> }
                                  label="FM103,3"
                                  /><a target="_blank" href="https://www.fm1033.ca/"> <Logo src={Images.logoFM1033} alt="logo" style={{  margin: 'auto 20px' }} /></a>
                               <FormControlLabel
                                 control={ <Checkbox checked={HD} onChange={handleChangeStationCheck} name="HD" /> }
                                 label="Coyote New Country"
                               /><a target="_blank" href="https://coyotenewcountry.ca/"> <Logo src={Images.logoCoyote} alt="logo" style={{  margin: 'auto 20px' }} /></a>
                            </FormGroup>
                         </FormControl>
                        </div>
                      </div>

                      <FormLabel component="legend" sx={{ mt: 4 }}>* Champ obligatoire</FormLabel>

                   </div>
                 </div>
              </div>
      
            </Widget>
          </Grid> */}

          {IsGeolocPresent &&(
            <Grid item md={4} sm={4} xs={4} >
              <Grid container direction="column"
                  justifycontent="center"
                  alignItems="stretch" >
              <Grid item>
               <Widget
                title={t('step03Page.libZoneGeo')} 
                upperTitle
                disableWidgetMenu
              >
                 <div className={classes.container}>
                    <div className={classes.logotypeContainer}  style={styleCarte.paperContainer}></div>
                 </div>
                {infoSeller.infoCity && (<FormLabel component="legend" sx={{ mt: 4 }}><b>{t('step03Page.libCity')}</b> { infoSeller.ObtInfoCity(i18n.language) }</FormLabel> )}
             

               
              </Widget>

              </Grid>
            </Grid>
            </Grid>
           )}

         </Grid>

         <BottomActionBar
             buttonsStack={
            <ButtonGroup disableElevation variant="contained">
              <Button 
                  variant="contained"
                  size="medium"
                  color="secondary"
                  onClick={() => {
                                   SauverStep03(infoSeller.name);
                                   props.history.push('/app/step02');                    
                          }}
                >
                {t('commonStepPages.butPrev')}
               </Button>
               <Button 
                  variant="contained"
                  size="medium"
                  color="secondary"
                  disabled={ IsStep03NotValid() }
                  onClick={() => {
                                   SauverStep03(infoSeller.name);
                                   props.history.push('/app/step04');                    
                           }}
               >
                {t('commonStepPages.butNext')}
               </Button>
             </ButtonGroup>
             }/>
      </React.Fragment>
    );
  }

  export default withTranslation()(Step03);  

  /*
               <Button 
                variant="contained"
                size="medium"
                color="secondary"
                onClick={() => {
                  CampaignContextSec.setStep03(station);
                }}
                >
                Enregistrer
                </Button>

  */