import React from "react";
import { Grid, Paper, Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { getHost, getCurrentUrl, buildUrlFor } from "common/helpers/location"
import { withTranslation } from 'react-i18next';

// styles
import useStyles from "./styles";

// logo
import Images  from '../../themes/Images';

function Error(props) {
  const { t } = props;  
  var classes = useStyles();

  return (
    <Grid container className={classes.container}>
      <div className={classes.logotype}>
        <img className={classes.logotypeIcon} src={Images.logoFolloWhite} alt="logo" /><br></br>
        <Typography variant="h3" color="white" className={classes.logotypeText}>
          {t('errorPage.libAdFolloTitle')}
        </Typography>
      </div>
      <Paper classes={{ root: classes.paperRoot }}>
        <Typography
          variant="h1"
          color="primary"
          className={classnames(classes.textRow, classes.errorCode)}
        >
          404
        </Typography>
        <Typography variant="h5" color="primary" className={classes.textRow}>
          {t('errorPage.messPageNotExist')}
        </Typography>
        <Typography
          variant="h6"
          color="text"
          className={classnames(classes.textRow, classes.safetyText)}
        >
           {t('errorPage.messReturnGoodPlace')} 
        </Typography>
        <Button
          variant="contained"
          color="primary"
          href="https://follo.statsradio.com/login"
          size="large"
          className={classes.backButton}
        >
          {t('errorPage.butReturn')} 
        </Button>
      </Paper>
    </Grid>
  );
}


export default withTranslation()(Error);