import numeral from 'numeral';

numeral.register('locale', 'fr', {
  delimiters: {
    thousands: ' ',
    decimal: ',',
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'g',
    trillion: 't',
  },
  ordinal: function (number) {
    return number === 1 ? 'er' : 'e';
  },
  currency: {
    symbol: '$',
  },
});

export function changeNumeralLocale(lng) {
  let language = 'en';
  if (!isLanguageSupported(lng)) {
    const subLanguage = lng.substring(0, 2);

    if (isLanguageSupported(subLanguage)) {
      language = subLanguage;
    }
  } else {
    language = lng;
  }
  numeral.locale(language);
}

export function isLanguageSupported(lng) {
  return Object.keys(numeral.locales).some(language => language === lng);
}

export default numeral;
