import React, { useState } from "react";
import {
  Grid,
  FormControl,
  FormLabel,
  FormControlLabel,
  TextField,  
  Radio,
  RadioGroup,  
  Button,
  ButtonGroup,
  Box
} from "@mui/material";
import { useTheme } from "@mui/styles";
import classnames from "classnames";
import { withTranslation } from 'react-i18next';


import { infoPromo } from "../../modules/adssellerradio/domain/InfoPromo"

// styles
import useStyles from "./styles";

// components
import Widget from "../../components/Widget/Widget";
import BottomActionBar from "../../components/BottomActionBar/BottomActionBar";
import PageTitle from "../../components/PageTitle/PageTitle";

import {SauverStep04,CampaignContextSec}  from "../../context/CampaignContext";
import { obtInfoAdsSeller } from "../../context/UserContext";

var EnErreurStep04={
  CustomAmountError: false,
};


function Step04(props) {
  const { t } = props;
  var classes = useStyles();
  var IsInfoPromoPresent:Boolean;
  var IsPromoPastDue:Boolean=false;
  var dateFinProm:String="";
  var messRabais:String="";
  
  var [error, setError] = useState(EnErreurStep04);
  var montantCourant=CampaignContextSec.getMontant();
//  var [CpmValue, setCpmValue] = useState(CampaignContextSec.GetCPMLibel());
  var [priceValue, setPriceValue] = React.useState(montantCourant); 
  var [ActiCustomAmount, setActiCustomAmount] = useState(priceValue==="other" ? true : false );
  var [CustomAmountValue, setCustomAmountValue] = useState(CampaignContextSec.montant);
  var [ImpressionValue, setImpressionValue] = useState(CampaignContextSec.GetNbImpression());
  var [promoCodeValue, setPromoCodeValue] = useState(CampaignContextSec.promoCode);
  var [infoSeller, setInfoSeller] = useState(CampaignContextSec.setInfoSeller(obtInfoAdsSeller()));

  if (infoSeller === undefined)  {
    IsInfoPromoPresent=false;
  }
  else {
    IsInfoPromoPresent=true;
  }

  if (CampaignContextSec.CampaignSave === true){
    props.history.push('/app/step07');
    return;
  }

  const ResetErr = () => {
    var tmp=  { CustomAmountError: error.CustomAmountError,
              };
     return tmp;
   }  
  const handlePriceChange = (event) => {
    setPriceValue(event.target.value);
    if (event.target.value !== 'other')
    {
       setCustomAmountValue(event.target.value);
       setActiCustomAmount(false);
       handleCustomAmountChange(event);
    }
    else
    {
      setActiCustomAmount(true);
    }
  };

  const handleCustomAmountChange= (event) =>{
    var TmpErr=ResetErr();
    TmpErr.CustomAmountError=false;
    setCustomAmountValue(event.target.value);
    setImpressionValue(CampaignContextSec.GetNbImpressionForMount(event.target.value,promoCodeValue));
    if (event.target.value < 500) {
       TmpErr.CustomAmountError=true;
    }
    CampaignContextSec.IndUpdate=true;
    setError(TmpErr);
  }

  const handlePromoCodeChange = (event) =>{
    setPromoCodeValue(event.target.value);    
    CampaignContextSec.setInfoPromo(obtInfoPromo(event.target.value));
    setImpressionValue(CampaignContextSec.GetNbImpressionForMount(CustomAmountValue,event.target.value));
  }   

  const obtInfoPromo=(parmCodeProm:String):infoPromo =>{
    var infoPromo:infoPromo=undefined;

    if (IsInfoPromoPresent) {
      infoPromo=infoSeller.validCodeProm(parmCodeProm);
    }
    return infoPromo;
  }

  const IsPromoCodeValid = () => {
    var infoPromo:infoPromo;
    var promoValid = false;
    var DateJour = new Date;
    IsPromoPastDue=false;
    dateFinProm="";

    if (promoCodeValue == undefined || (promoCodeValue.trim() == "") ) {
      promoValid=true;
    }
    else {
        infoPromo=obtInfoPromo(promoCodeValue);
        if ((infoPromo !== undefined ) )  {
          if (DateJour <= infoPromo.getDateFin()) {
             messRabais=infoPromo.getMessRabais();
             promoValid=true;
             CampaignContextSec.setInfoPromo(infoPromo);
          }
          else {
             dateFinProm=infoPromo.dateFin;
             IsPromoPastDue=true;
          }
        }
    }
    
    return promoValid;
  }

  const getMess = (mess:String) => {
    var Suite:String="";
    
    if (IsPromoPastDue){
      Suite=t('step04Page.messPromoCodeInvalidSince') + dateFinProm; 
    } 

    return mess+Suite;
  }

  const IsStep04NotValid = () => {
    return error.CustomAmountError || CustomAmountValue < 500 || IsPromoCodeValid() == false;
  }

  return (
      <React.Fragment>
        <PageTitle title={t('sidebar.libStep04Title')} />

        <Grid container spacing={4}>
          <Grid item md={12} sm={12} xs={12}>
            <Widget
              title={t('step04Page.libEnterInfoStepTitle')} 
              upperTitle
              disableWidgetMenu
            >
             <div className={classes.formContainer}>
                <div className={classes.form}>
                  <div className={classes.dashedBorder}>

                     <div className={classes.controlContainer}>
                        <div className={classnames(classes.controlCell, classes.borderRight)}>

                          <Grid container spacing={2}>
                             <Grid item xs={12}>

                              <FormLabel
                                  id="Impression-label"
                                  sx={{
                                        mb: 2,
                                        fontWeight: 'xl',
                                        textTransform: 'uppercase',
                                        fontSize: '1.5rem',
                                        letterSpacing: '0.15rem',
                                      }}
                              >
                                 {t('step04Page.libImpression')} 
                              </FormLabel>
                              <FormLabel
                                  id="Impression-label"
                                  sx={{
                                        mb: 2,
                                        fontWeight: '700',
                                        fontSize: '1.5rem',
                                      }}
                              >
                                 &nbsp;{CampaignContextSec.GetNbImpressionFormat(ImpressionValue).replace(' ', '&nbsp;').replace(String.fromCharCode(8239), ' ')      }
                              </FormLabel>                              
                              </Grid>
                            <Grid item xs={12}>

                               <FormLabel
                                      id="price-label"
                                      sx={{
                                        mb: 2,
                                        fontWeight: 'xl',
                                        textTransform: 'uppercase',
                                        fontSize: 'xs',
                                        letterSpacing: '0.15rem',
                                      }}
                                  >
                                  {t('step04Page.radInvesTitle')} 
                               </FormLabel>
                               <RadioGroup
                                 row
                                 aria-labelledby="price-label"
                                 size="lg"
                                 sx={{ gap: 1.5 }}
                                 value={priceValue}
                                 onChange={handlePriceChange}        
                                >
                                   <FormControlLabel value="500" control={<Radio value="500" />} label={t('step04Page.radInvesChoices01')} />  
                                   <FormControlLabel value="1000" control={<Radio value="1000" />} label={t('step04Page.radInvesChoices02')} />
                                   <FormControlLabel value="1500" control={<Radio value="1500"  />} label={t('step04Page.radInvesChoices03')}  />
                                   <FormControlLabel value="2000" control={<Radio value="2000"  />} label={t('step04Page.radInvesChoices04')}  />
                                   <FormControlLabel value="3000" control={<Radio value="3000"  />} label={t('step04Page.radInvesChoices05')} />
                                   <FormControlLabel value="4000" control={<Radio value="4000"  />} label={t('step04Page.radInvesChoices06')} />
                                   <FormControlLabel value="other" control={<Radio value="other"  />} label={t('step04Page.radInvesChoices07')} />
                                </RadioGroup>


                            </Grid>
                            <Grid item xs={12}>

                              <Box
                                 sx={{
                                      width: 250,
                                      maxWidth: '100%',
                                     }}
                               >
                                  <TextField 
                                        id="CustumAmount"
                                        disabled={ ActiCustomAmount === false }
                                        InputProps={{
                                                      inputMode: 'numeric', pattern: '[0-9]*' ,
                                                      classes: {
                                                                 underline: classes.textFieldUnderline,
                                                                 input: classes.textField,
                                                                },
                                                     }}
                                        value={CustomAmountValue}
                                        label={t('step04Page.ediCustomAmount')}
                                        onChange={(e) => handleCustomAmountChange(e)}
                                        margin="normal" 
                                        type="number"
                                        fullWidth
                                        helperText={(error.CustomAmountError || CustomAmountValue < 500)? t('step04Page.messAmountValid'): undefined}
                                        error={(error.CustomAmountError || CustomAmountValue < 500)}
                                    />

                               </Box>

                               <Box
                                 sx={{
                                      width: 300,
                                      maxWidth: '100%',
                                     }}
                               >
                                  <TextField 
                                        id="PromoCode2"
                                        InputProps={{
                                                      classes: {
                                                                 underline: classes.textFieldUnderline,
                                                                 input: classes.textField,
                                                                },
                                                     }}
                                        value={promoCodeValue}
                                        label= {t('step04Page.ediPromoCode')}
                                        onChange={(e) => handlePromoCodeChange(e)}
                                        margin="normal" 
                                        fullWidth
                                        helperText={(IsPromoCodeValid()==false)? getMess(t('step04Page.messPromoCodeInvalid')): undefined}  
                                        error={(IsPromoCodeValid()==false)}
                                    />
                                    
                                 <FormLabel
                                      id="rabate-label"
                                      sx={{
                                        mb: 2,
                                        fontWeight: 'xl',
                                        textTransform: 'uppercase',
                                        fontSize: 'xs',
                                        letterSpacing: '0.15rem',
                                      }}
                                  >
                                     {messRabais}
                               </FormLabel>                                 
                               </Box>

                            </Grid>
                            <Grid item xs={12}>
                              <FormLabel
                                  id="Impression-label"
                                  sx={{
                                        mb: 2,
                                        fontWeight: 'xl',
                                        fontSize: 'xs',
                                      }}
                                >
                                    <b>{t('step04Page.libImpression')}</b>  {t('step04Page.libImpressionDef')}
                              </FormLabel>     
                            </Grid>

                         </Grid>

                        </div>
                     </div>

                  </div>
               </div>
             </div>
      
            </Widget>
          </Grid>

         </Grid>

         <BottomActionBar
             buttonsStack={
            <ButtonGroup disableElevation variant="contained">
              <Button 
                  variant="contained"
                  size="medium"
                  color="secondary"
                  onClick={() => {
                                   SauverStep04(ImpressionValue,CustomAmountValue,promoCodeValue);
                                   props.history.push('/app/step03');                    
                          }}
               >
                {t('commonStepPages.butPrev')}
               </Button>
               <Button 
                   variant="contained"
                   size="medium"
                   color="secondary"
                   disabled={ IsStep04NotValid() }
                   onClick={() => {
                                    SauverStep04(ImpressionValue,CustomAmountValue,promoCodeValue);
                                    props.history.push('/app/step05');                    
                            }}
               >
                {t('commonStepPages.butNext')}
               </Button>
             </ButtonGroup>
             }/>
      </React.Fragment>
    );
  }

  export default withTranslation()(Step04);  
  /*
                 <Button 
                variant="contained"
                size="medium"
                color="secondary"
                onClick={() => {
                  CampaignContextSec.setStep04(ImpressionValue,CustomAmountValue);
                }}
                >
                Enregistrer
                </Button>

  
                              <Grid item xs={12}>
                              <FormLabel
                                  id="Cpm-label"
                                  sx={{
                                        mb: 2,
                                        fontWeight: 'xl',
                                        textTransform: 'uppercase',
                                        fontSize: 'xl',
                                        letterSpacing: '0.15rem',
                                      }}
                              >
                                 Coût par mille: {CpmValue}
                              </FormLabel>

                            </Grid>

  
                */