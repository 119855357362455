// @flow
function removeTrailingSlash(url: string) {
  return url.replace(/\/$/, '');
}

export function getHost(): string {
  const url = window.location.protocol.concat('//').concat(window.location.host);
  return removeTrailingSlash(url);
}

export function getCurrentUrl(): string {
  return removeTrailingSlash(window.location.href);
}

export function buildUrlFor(path: string): string {
  let newPath = path;
  if (path.startsWith('/')) {
    newPath = path.replace('/', '');
  }

  return removeTrailingSlash(`${getHost()}/${newPath}`);
}

export function getCallSignFromUrl(): string {
  var url=getCurrentUrl();
  var posSep:Integer;

  url=url.replace(getHost(), '').replace('#','');
  url=url.replace('/','');

  posSep=url.indexOf("/");
  if (posSep > 0)
     { url=url.substring(0,url.indexOf("/")); } 

  return url;
}


export default { getHost, getCurrentUrl, buildUrlFor, getCallSignFromUrl };
