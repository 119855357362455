// @flow
import api, { buildHeaders } from 'common/services/api';
import Advertiser from 'common/modules/advertiser/domain/Advertiser';

class AdvertiserProfileAPI {
  baseUrl = '/follo/profiles';

  async getUserAdvertiser(token: string) {
    const { data } = await api.get(this.baseUrl, {}, { headers: buildHeaders(token) });
    return Advertiser.parse(data);
  }

  async updateAdvertiser(token: string, advertiser: Advertiser) {
    const url = this.baseUrl + `/${advertiser.reference}`;
    return await api.put(url, { name: advertiser.name }, { headers: buildHeaders(token) });
  }
}
export default new AdvertiserProfileAPI();
