import React, { useState, setState } from "react";
import {useDropzone} from 'react-dropzone';
import {
  Grid,
  FormControl,
  FormLabel,
  FormControlLabel,
  TextField,  
  FormHelperText,
  Radio,
  RadioGroup,  
  Button,
  ButtonGroup
} from "@mui/material";
import InputMask from 'react-input-mask'
import { useTheme } from "@mui/styles";
import classnames from "classnames";
import { withTranslation } from 'react-i18next';
import i18n from "i18n/i18n"

// styles
import useStyles from "./styles";
import styled, { withTheme } from 'styled-components';

// components
import Widget from "../../components/Widget/Widget";
import BottomActionBar from "../../components/BottomActionBar/BottomActionBar";
import PageTitle from "../../components/PageTitle/PageTitle";

import {SauverStep06,CampaignContextSec, CreatCampaignFile, ObtainCampaignFile, FlushCampaignFile}  from "../../context/CampaignContext";

var EnErreurStep06={
  spotTextError: false,
};

const maxSize = 4081209;

function sizeValidator(file) {
  if (file.size > maxSize) {
    return {
      code: "name-too-large",
      message: i18n.t('step06Page.messFileTooBig',{val: maxSize})
    };
  }

  return null
}

function DropAudioFile(props) {
    const {
      acceptedFiles,
      fileRejections,
      getRootProps,
      getInputProps
    } = useDropzone({
      validator: sizeValidator,
      onDropAccepted: acceptedFiles => {
                                         var ContextFile=CreatCampaignFile(acceptedFiles[0]);
                                         props.onChange() 
                                        },
      maxFiles:1,
      accept: {
        'audio/mpeg': [],
        'audio/wav': []
      },
    });

    var classes = useStyles();
  
//  const files = acceptedFiles.map(file =>{ 
//    var ContextFile=CreatCampaignFile(file);
//    return (
//    <li key={file.path}>
//      {file.path} - {file.size} octets  <audio controls src={ContextFile.PathTemp} />
//    </li>
//    )
//   });
  
  const GestMessError = (TexteMess:string) => {
    
    if (TexteMess == "Too many files") {
       return i18n.t('step06Page.messTooMuchFile');
    }
    if (TexteMess == "File type must be audio/mpeg,audio/wav") {
      return i18n.t('step06Page.messTypeFileInvalid');
    }  
    return TexteMess;
  }
  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={file.path}>
      {file.path} - {file.size} {i18n.t('step06Page.libBytes')}
      <ul>
        {errors.map(e => (
          <li key={e.code}>{GestMessError(e.message)}</li>
        ))}
      </ul>
    </li>
  ));

  return (
    <section className={classes.container}>
      <div {...getRootProps({className: classes.dropzone})}>
        <input {...getInputProps()}  />
        <p>{i18n.t('step06Page.messUploadFile')} </p>
        <em>{i18n.t('step06Page.messTypeFile')}</em>        
      </div>
        {fileRejectionItems.length > 0 && (
          <aside>
             <h4>{i18n.t('step06Page.libRejectFile')}</h4>
             <ul>{fileRejectionItems}</ul>
          </aside>
        )}
    </section>
  );
}


function Step06(props) {
  const { t } = props;
  var classes = useStyles();
  const theme = useTheme();

  var [error, setError] = useState(EnErreurStep06);
  var [CampaignFileValue, setCampaignFileValue] = useState(ObtainCampaignFile());
  var [filePresentValue, setFilePresentValue] = useState(CampaignContextSec.getFilePresent());
  var [SpotDurationValue, setSpotDurationValue] = useState(CampaignContextSec.spotDuration);
  var [SpotTextValue, setSpotTextValue] = useState(CampaignContextSec.spotText);
  var [SpotVoiceValue, setSpotVoiceValue] = useState(CampaignContextSec.spotVoice);
  var [SpotSoundtrackValue, setSpotSoundtrackValue] = useState(CampaignContextSec.spotSoundTrack);
  var [ContactNameValue, setContactNameValue] = useState(CampaignContextSec.contactName);
  var [PhoneContactValue, setPhoneContactValue] = useState(CampaignContextSec.phoneContact);
  var [PhoneExtensionContactValue, setPhoneExtensionContactValue] = useState(CampaignContextSec.phoneExtensionContact);
  var NbMaxCarac=(SpotDurationValue===15) ? 350 : 700; 
  var [MaxLengthTextValue, setMaxLengthTextValue] = useState(NbMaxCarac);
  var [MessMaxLengthText, setMessMaxLengthText] = useState(t('step06Page.messTextLength',{val: NbMaxCarac}) );

  if (CampaignContextSec.CampaignSave === true){
    props.history.push('/app/step07');
    return;
  }

  const ResetErr = () => {
    var tmp=  { spotTextError: error.messageError,
              };
     return tmp;
   }
  const handleSpotDurationChange = (event) => {
    var MaxLenght=event.target.value==="15" ? 350 : 700;
    setSpotDurationValue(event.target.value);
    setMaxLengthTextValue(MaxLenght);
    setSpotTextValue(SpotTextValue.slice(0,MaxLenght));
    setMessMaxLengthText( t('step06Page.messTextLength',{val: MaxLenght}) );
  };

  const handleSpotVoiceChange = (event) => {
    setSpotVoiceValue(event.target.value);
  };

  const handleIndUpLoadChange = (event) => {
    if (event.target.value =="true"){
       setFilePresentValue(true);
       setSpotTextValue("");
     }
     else{
      setFilePresentValue(false);
      setCampaignFileValue(FlushCampaignFile());
     }
     CampaignContextSec.IndUpdate=true;
  };

  const handleSpotSoundtrackChange = (event) => {
    setSpotSoundtrackValue(event.target.value);
  };

  const handleAudioFileUpload = () => {
    CampaignContextSec.IndUpdate=true;
    setCampaignFileValue(ObtainCampaignFile());
  };

  const ValidSpotText=(newVal)=>{
     return newVal.length !== 0;
  }

  const handleSpotTextChange= (event) =>{
    var TmpErr=ResetErr();
    TmpErr.spotTextError=false;
    setSpotTextValue(event.target.value);
    if (!ValidSpotText(event.target.value)) {  
       TmpErr.spotTextError=true;
    }
    CampaignContextSec.IndUpdate=true;
    setError(TmpErr);
  }

  const handleContactNameChange = (ContactName: string) => {
    setContactNameValue(ContactName);
    CampaignContextSec.IndUpdate=true;
  };

  const handlePhoneChange = (PhoneContact: string) => {
    setPhoneContactValue(PhoneContact);
    CampaignContextSec.IndUpdate=true;
  };

  const handlePhoneExtensionChange= (extPhoneContact: string) => {
    setPhoneExtensionContactValue(extPhoneContact);
    CampaignContextSec.IndUpdate=true;
  };

  const IsStep06NotValid = () => {
    if (filePresentValue){
      return ObtainCampaignFile() === undefined ;
    }
    else{
      return !ValidSpotText(SpotTextValue) || error.spotTextError ;
    }
  }
 
  return (
      <React.Fragment>
        <PageTitle title={t('sidebar.libStep06Title')} />

        <Grid container spacing={4}>
          <Grid item md={12} sm={12} xs={12}>
            <Widget
              title={t('step06Page.libEnterInfoStepTitle')} 
              upperTitle
              disableWidgetMenu
            >
      <div className={classes.formContainer}>
      <div className={classes.form}>
        <div className={classes.dashedBorder}>

        <div className={classes.controlContainer}>
           <div className={classnames(classes.controlCell, classes.borderRight)}>

           <Grid container spacing={2}>
             <Grid item xs={12}>

        <FormLabel 
                      id="Comnct-label"
                      sx={{
                         mb: 2,
                         fontWeight: 'xl',
                         fontSize: 'xs',
                         }}
                     >
                    {t('step06Page.messQualityRespect')} 
       </FormLabel>     
        <FormLabel component="legend" sx={{  mt: 3, mb: 1 }}>{t('step06Page.messProdAnnounce', {val:CampaignContextSec.montProdMessage})}<b>{t('step06Page.messProdAnnounce2')}</b>{t('step06Page.messProdAnnounce3')}</FormLabel>
        <FormLabel
        id="IndUpload-label"
        sx={{
          mb: 2,
          mr: 2,
          fontWeight: 'xl',
          fontSize: 'xl',
        }}
      >
        {t('step06Page.messDurationQuest')}  
      </FormLabel>
      <RadioGroup
        row
        aria-labelledby="IndUpload-label"
        size="lg"
        sx={{ gap: 1.5 }}
        value={filePresentValue}
        onChange={handleIndUpLoadChange}        
      >
        <FormControlLabel sx={{  mb: 1 }} value="true" control={<Radio value="true" />} label={t('commonStepPages.butYes')}  />
        <FormControlLabel sx={{  mb: 1 }} value="false" control={<Radio value="false" />} label={t('commonStepPages.butNo')} />
      </RadioGroup>

       {filePresentValue == true  && (
              <React.Fragment>

                  { CampaignFileValue !== undefined && (
                    <React.Fragment>
                       <h4>{t('step06Page.libFileLoaded')}</h4>
                       <ul>
                          <li key={CampaignFileValue.DefFile.path}>
                               {CampaignFileValue.DefFile.path} - {CampaignFileValue.DefFile.size} {t('step06Page.libBytes')}
                               
                              <audio controls src={CampaignFileValue.PathTemp} />
                           </li>
                       </ul>
                       <Button 
                         variant="contained"
                         size="medium"
                         color="secondary"
                         onClick={() => {
                                     setCampaignFileValue(FlushCampaignFile());
                                     CampaignContextSec.audioFileName="";

                                }} >
                         {t('step06Page.butChangeFile')}
                       </Button>
                    </React.Fragment>
                  )}
                  { CampaignFileValue == undefined && (
                    <React.Fragment>
                           {IsStep06NotValid() == true && (  
                              <FormHelperText error  >{t('step06Page.messSelectFile')}</FormHelperText> )}
                          { CampaignContextSec.getFilePresent() == true && (
                             <h4>{t('step06Page.messReloadFile',{val:CampaignContextSec.audioFileName})}</h4>
                          )}
                         <DropAudioFile onChange={handleAudioFileUpload} />
                     </React.Fragment>
                  )}
              </React.Fragment>
        )}

        </Grid>
        {filePresentValue === false && (
          <React.Fragment>
             <Grid item xs={12}>

             <FormLabel
        id="SpotDuration-label"
        sx={{
          mb: 2,
          fontWeight: 'xl',
          textTransform: 'uppercase',
          fontSize: 'xs',
          letterSpacing: '0.15rem',
        }}
      >
        {t('step06Page.radDurationTitle')} 
      </FormLabel>
      <RadioGroup
        row
        aria-labelledby="SpotDuration-label"
        size="lg"
        sx={{ gap: 1.5 }}
        value={SpotDurationValue}
        onChange={handleSpotDurationChange}        
      >
        <FormControlLabel value="15" control={<Radio value="15" />} label={t('step06Page.radDurationChoice01')} />
        <FormControlLabel value="30" control={<Radio value="30" />} label={t('step06Page.radDurationChoice02')} />
      </RadioGroup>


             </Grid>
             <Grid item xs={12}>

             <TextField 
                 id="SpotText"
                 value={SpotTextValue}
                 label={MessMaxLengthText}
                 onChange={(e) =>  handleSpotTextChange(e)}
                 margin="normal" 
                 multiline
                 required
                 rows={6}
                 fullWidth
                 onInput = {(e) =>{
                        e.target.value = e.target.value.slice(0,MaxLengthTextValue)
                    }}
                 helperText={(error.spotTextError || IsStep06NotValid() == true) ? t('step06Page.messEnterSpotText'): undefined}
                 error={(error.spotTextError|| IsStep06NotValid() == true)}
              />


             </Grid>
             <Grid item xs={4}>

             <FormLabel
        id="SpotVoice-label"
        sx={{
          mb: 2,
          fontWeight: 'xl',
          textTransform: 'uppercase',
          fontSize: 'xs',
          letterSpacing: '0.15rem',
        }}
      >
        {t('step06Page.radVoiceTitle')}
      </FormLabel>
      <RadioGroup
        row
        aria-labelledby="SpotVoice-label"
        size="lg"
        sx={{ gap: 1.5 }}
        value={SpotVoiceValue}
        onChange={handleSpotVoiceChange}        
      >
        <FormControlLabel value="W" control={<Radio value="W" />} label={t('step06Page.radVoiceChoice01')} />
        <FormControlLabel value="M" control={<Radio value="M" />} label={t('step06Page.radVoiceChoice02')} />
        <FormControlLabel value="N" control={<Radio value="N" />} label={t('step06Page.radVoiceChoice03')} />
      </RadioGroup>

             </Grid>
             <Grid item xs={8}>

             <FormLabel
        id="SpotSoundtrack-label"
        sx={{
          mb: 2,
          fontWeight: 'xl',
          textTransform: 'uppercase',
          fontSize: 'xs',
          letterSpacing: '0.15rem',
        }}
      >
        {t('step06Page.radSoundtrackTypeTitle')}
      </FormLabel>
      <RadioGroup
        row
        aria-labelledby="SpotSoundtrack-label"
        size="lg"
        sx={{ gap: 1.5 }}
        value={SpotSoundtrackValue}
        onChange={handleSpotSoundtrackChange}        
      >
        <FormControlLabel value="VeryRhythm" control={<Radio value="VeryRhythm" />} label={t('step06Page.radSoundtrackTypeChoice01')} />
        <FormControlLabel value="Rhythm" control={<Radio value="Rhythm" />} label={t('step06Page.radSoundtrackTypeChoice02')} />
        <FormControlLabel value="Soft" control={<Radio value="Soft" />} label={t('step06Page.radSoundtrackTypeChoice03')} />
        <FormControlLabel value="None" control={<Radio value="None" />} label={t('step06Page.radSoundtrackTypeChoice04')} />
      </RadioGroup>

             </Grid>
             </React.Fragment>
        )}             
            <Grid item xs={12}>
            <Grid container spacing={2}>
               <Grid item xs={12}>

                  <FormLabel
                     id="Tel-label"
                     sx={{
                          mt: 6,
                          mb: 2,
                          fontWeight: 'xl',
                          fontSize: 'xs',
                        }}
                     >
                       {t('step06Page.libContactTitle')} 
                    </FormLabel>     
               </Grid>  
               <Grid item xs={4}>
                  <TextField 
                        id="ContactName"
                        InputProps={{
                        classes: {
                            underline: classes.textFieldUnderline,
                            input: classes.textField,
                        },
                        }}
                        value={ContactNameValue}
                        label={t('step06Page.ediContactName')}
                        onChange={(e) => {handleContactNameChange(e.target.value); } }
                        margin="normal" 
                        type="text"
                        fullWidth
                      />
               </Grid>
               <Grid item xs={3}>
               <InputMask mask="(999)999-9999" maskChar=" " value={PhoneContactValue} onChange={(e) => {handlePhoneChange(e.target.value); }}>
                   {(inputProps) => <TextField 
                        {...inputProps}
                        id="TelContact"
                        InputProps={{
                        classes: {
                            underline: classes.textFieldUnderline,
                            input: classes.textField,
                        },
                        }}
                        label={t('step06Page.ediPhone')} 
                        margin="normal" 
                        type="text"
                        fullWidth
                      />}
                        
                    </InputMask>
               </Grid>
               <Grid item xs={3}>

               <InputMask mask="99999999" maskChar=" " value={PhoneExtensionContactValue} onChange={(e) => {handlePhoneExtensionChange(e.target.value); }}>
                   {(inputProps) => <TextField 
                        {...inputProps}
                        id="PhoneExtensionContact"
                        InputProps={{
                        classes: {
                            underline: classes.textFieldUnderline,
                            input: classes.textField,
                        },
                        }}
                        label={t('step06Page.ediPhoneExt')}
                        margin="normal" 
                        type="text"
                        fullWidth
                      />}
                        
                    </InputMask>
               </Grid>

             </Grid>
             </Grid>

           </Grid>

                    </div>
                </div>
                <FormLabel component="legend" sx={{ mt: 4 }}>{t('commonStepPages.libFieldRequired')}</FormLabel>

      </div>
      </div>
      </div>
      

            </Widget>
          </Grid>

         </Grid>

         <BottomActionBar
             buttonsStack={
            <ButtonGroup disableElevation variant="contained">
              <Button 
                  variant="contained"
                  size="medium"
                  color="secondary"
                  onClick={() => {
                                   SauverStep06(SpotDurationValue,SpotVoiceValue,SpotSoundtrackValue,SpotTextValue,ContactNameValue,PhoneContactValue,PhoneExtensionContactValue);
                                  props.history.push('/app/step05');                    
                          }}
              >
                {t('commonStepPages.butPrev')}
               </Button>
               <Button 
                   variant="contained"
                   size="medium"
                   color="secondary"
                   disabled={ IsStep06NotValid() }
                   onClick={() => {
                                    SauverStep06(SpotDurationValue,SpotVoiceValue,SpotSoundtrackValue,SpotTextValue,ContactNameValue,PhoneContactValue,PhoneExtensionContactValue);
                                    props.history.push('/app/step07');                    
                            }}
               >
                {t('commonStepPages.butNext')}
               </Button>
             </ButtonGroup>
             }/>
      </React.Fragment>
    );
  }

  export default withTranslation()(Step06);
  /*
                  <TextField 
                        id="TelContact"
                        InputProps={{
                        classes: {
                            underline: classes.textFieldUnderline,
                            input: classes.textField,
                        },
                        }}
                        value={PhoneContactValue}
                        label="Téléphone"  
                        onChange={(e) => {handlePhoneNameChange(e.target.value); } }
                        margin="normal" 
                        type="text"
                        fullWidth
                      />

                 <Button 
                variant="contained"
                size="medium"
                color="secondary"
                onClick={() => {
                    CampaignContextSec.setStep06(SpotDurationValue,SpotVoiceValue,SpotSoundtrackValue,SpotTextValue);
                }}
                >
                Enregistrer
                </Button>

   
  */