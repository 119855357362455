import React, { useState }  from "react";
import {
  Route,
  Switch,
  Redirect,
  withRouter,
} from "react-router-dom";
import classnames from "classnames";
import {Box, IconButton, Link} from '@mui/material'
import Icon from '@mdi/react'
import { withTranslation } from 'react-i18next';
import i18n from '../../i18n/i18n'


//icons
import {
  mdiFacebook as FacebookIcon,
//  mdiTwitter as TwitterIcon,
//  mdiGithub as GithubIcon,
} from '@mdi/js'

// styles
import useStyles from "./styles";

// components
import Header from "../Header";
import Sidebar from "../Sidebar";

// pages
import Step01 from "../../pages/step01";
import Step02 from "../../pages/step02";
import Step03 from "../../pages/step03";
import Step04 from "../../pages/step04";
import Step05 from "../../pages/step05";
import Step06 from "../../pages/step06";
import Step07 from "../../pages/step07";
import Profile from "../../pages/profile";
import Support from "../../pages/support";
import FAQ from "../../pages/faq";

// context
import { useLayoutState } from "../../context/LayoutContext";
import {ObtCampaign }  from "context/CampaignContext";

import { getHost, getCurrentUrl, buildUrlFor } from "common/helpers/location"
import {LinkActive} from 'context/LayoutContext';
import { useUserDispatch, initInfoAdsSeller2, obtInfoAdsSeller } from "../../context/UserContext";


function SetActivLink() {
  var I;
  I=1;
  while (I<=7 && LinkActive[I] == false ) 
  {
    LinkActive[I]=true;
    I=I+1; 
  }
}

function Layout(props) {
  const { t } = props;    
  var classes = useStyles();
  var IsSupportPresent:Boolean;
  var supportMessage;
  var supportMessagePart1;
  var supportMessagePart2;

  // global
  var layoutState = useLayoutState();

  var infoCampaign = ObtCampaign();

  var urlCurrent = getCurrentUrl().toLowerCase();

  var [infoSeller, setInfoSeller] = useState(obtInfoAdsSeller());

  if (infoSeller === undefined)  {
    IsSupportPresent=false;
  }
  else {
    IsSupportPresent=true;
    supportMessage=infoSeller.supportMessage.split("|")
    supportMessagePart1=JSON.parse(supportMessage[0])[i18n.language];
    supportMessagePart2=JSON.parse(supportMessage[1])[i18n.language];
  }

  if (urlCurrent.includes("profile") || urlCurrent.includes("support") || infoCampaign.newState )
  {
    LinkActive[1]=true;
    if (infoCampaign.newState){
      LinkActive[2]=false;
      LinkActive[3]=false;
      LinkActive[4]=false;
      LinkActive[5]=false;
      LinkActive[6]=false;
      LinkActive[7]=false;
    }
  }
  else {
    if (infoCampaign.CampaignSave == false){
       LinkActive[1]=urlCurrent.includes("step01");
       LinkActive[2]=urlCurrent.includes("step02");
       LinkActive[3]=urlCurrent.includes("step03");
       LinkActive[4]=urlCurrent.includes("step04");
       LinkActive[5]=urlCurrent.includes("step05");
       LinkActive[6]=urlCurrent.includes("step06");
       LinkActive[7]=urlCurrent.includes("step07");
       SetActivLink();
    }
    else
    {
      LinkActive[1]=false;
      LinkActive[2]=false;
      LinkActive[3]=false;
      LinkActive[4]=false;
      LinkActive[5]=false;
      LinkActive[6]=false;
      LinkActive[7]=false;
    }
  }

  return (
    <div className={classes.root}>
        <React.Fragment>
         <Header history={props.history} />
          <Sidebar />
          <div
            className={classnames(classes.content, {
              [classes.contentShift]: layoutState.isSidebarOpened,
            })}
          >
            <div className={classes.fakeToolbar} />
            <Switch>
              {(infoCampaign.newState == true && infoCampaign.CampaignSave == false )  && ( <Route component={Step01} /> )}
              <Route path="/app/profile" component={Profile} /> 
              <Route path="/app/step01" component={Step01} /> 
              <Route path="/app/step02" component={Step02} /> 
              <Route path="/app/step03" component={Step03} /> 
              <Route path="/app/step04" component={Step04} /> 
              <Route path="/app/step05" component={Step05} /> 
              <Route path="/app/step06" component={Step06} /> 
              <Route path="/app/step07" component={Step07} /> 
              <Route path="/app/support" component={Support} />
            </Switch>
            <Box
              mt={5}
              width={"100%"}
              display={"flex"}
              alignItems={"center"}
              justifyContent="space-between"
            >
              {IsSupportPresent && (
              <div>
              {supportMessagePart1}&nbsp;  
              
                <Link
                  color={'primary'}
                  href={'mailto:'+infoSeller.supportEmail}
                  className={classes.link}
                >
                  {infoSeller.supportEmail} 
                </Link>
                &nbsp;{supportMessagePart2}.
              </div>
              )}
            </Box>
          </div>
        </React.Fragment>
    </div>
  );
}

export default withRouter(withTranslation()(Layout));

/* <div>
<Link
  href={'https://www.facebook.com/StatsRadio'}
  target={'_blank'}
>
  <IconButton aria-label="facebook">
    <Icon
      path={FacebookIcon}
      size={1}
      color="#6E6E6E99"
    />
  </IconButton>
</Link>
</div>


            <Box
              mt={5}
              width={"100%"}
              display={"flex"}
              alignItems={"center"}
              justifyContent="space-between"
            >
              <div>
                <Link
                  color={'primary'}
                  href={'https://statsradio.com/'}
                  target={'_blank'}
                  className={classes.link}
                >
                  Achat publicités
                </Link>
                <Link
                  color={'primary'}
                  href={'https://statsradio.com/about.html'}
                  target={'_blank'}
                  className={classes.link}
                >
                  À propos de nous
                </Link>
              </div>
            </Box>


*/

