import React from "react";
import AuthenticationAPI from 'common/modules/authentication/services/AuthenticationAPI';
import UserAPI from 'common/services/UserAPI';
import UserProfileAPI from 'common/modules/userProfile/services/UserProfileAPI';
import AdsSellerRadioAPI from 'modules/adssellerradio/services/AdsSellerRadioAPI';
import AdvertiserProfileAPI from 'modules/advertiser/services/AdvertiserProfileAPI';
import CustomersAdsFolloAPI from "modules/customers/services/CustomersAdsFolloAPI"
import ApiClient from 'common/services/api';
import SessionAPI from 'common/modules/session/services/SessionAPI';
import AdsFolloSignUpAPI from 'common/userProfile/services/AdsFolloSignUpAPI';
import RefreshToken from 'common/modules/session/domain/RefreshToken';
import SessionStorage from 'common/modules/session/services/SessionStorage';
import FolloUserProfile from 'domain/FolloUserProfile'
import User from 'common/domain/user';
import UserProfile from 'common/modules/userProfile/domain/UserProfile';
import CampaignContext, {CampaignContextSec}  from "./CampaignContext";
import { getHost, getCurrentUrl, buildUrlFor, getCallSignFromUrl} from "common/helpers/location"
import { use } from "i18next";
import AdsSellerRadio from "modules/adssellerradio/domain/AdsSellerRadio";
import i18n from '../i18n/i18n';


var UserStateContext = React.createContext();
var UserDispatchContext = React.createContext();
var FolloProfileCurrent:FolloUserProfile;
var InfoAdsSellerCurrent:AdsSellerRadio;
var ClearProfileLogin=false;

const ERROR_STATUS = 403;
const ERROR_MESSAGE = 'EMAIL_ALREADY_IN_USE';

function userReducer(state, action) {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return { ...state, isAuthenticated: true, isCallSignPresent: isCallSign() };
    case "SIGN_OUT_SUCCESS":
      return { ...state, isAuthenticated: false, isCallSignPresent: isCallSign() };
   case "LOGIN_AWAIT":
      return { ...state, isAuthenticated: false, isCallSignPresent: isCallSign() };
   case "LOGIN_FAILURE":
      return { ...state, isAuthenticated: false, isCallSignPresent:isCallSign() };
   case "RETRIEVE_NO_SESSION":      
      return { ...state, isAuthenticated: false, isCallSignPresent: isCallSign() };
   case "UPDATE_AWAIT":
    return { ...state, isAuthenticated: true, isCallSignPresent: isCallSign() };
   case "UPDATE_USER":
      return { ...state, isAuthenticated: true , isCallSignPresent:isCallSign()};
   case "UPDATE_USER_FAILURE":
      return { ...state, isAuthenticated: true, isCallSignPresent:isCallSign() };
   case "CREATE_AWAIT":
        return { ...state, isAuthenticated: false , isCallSignPresent:isCallSign()};
   case "CREATE_USER_FAILURE":
        return { ...state, isAuthenticated: false , isCallSignPresent:isCallSign()};
   case "FORGOT_PASSWORD_AWAIT":
        return { ...state, isAuthenticated: false , isCallSignPresent:isCallSign()};
   case "FORGOT_PASSWORD_FAILURE":
          return { ...state, isAuthenticated: false , isCallSignPresent:isCallSign()};
   case "FORGOT_PASSWORD_SUCCESS":
        return { ...state, isAuthenticated: false , isCallSignPresent:isCallSign()};
   case "SELLER_AWAIT":        
        return { ...state, isAuthenticated: false , isCallSignPresent:isCallSign()};
   case "SELLER_SUCCESS":        
        return { ...state, isAuthenticated: false , isCallSignPresent:isCallSign()};        
   default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({ children }) {
  var callSignPresent = isCallSign();
  var [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated: !! SessionStorage.retrieveNotExpired(),     //localStorage.getItem("id_token"),    
    isCallSignPresent: callSignPresent,
  });
  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  var context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserDispatch() {
  var context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}


function obtainCallsSign():string {
  if (InfoAdsSellerCurrent === undefined){
     return getCallSignFromUrl().toUpperCase();
  }   
  else {
    return InfoAdsSellerCurrent.callSign;
  }
}

function isCallSign():boolean {
  const callSignobt = obtainCallsSign();

  if (callSignobt === undefined || callSignobt === "" || callSignobt === "VIDE"){
      return false;
   }
   else {
      return true;
   }
}


export { UserProvider, useUserState, useUserDispatch, loginUser, signOut, showProfile, obtainUserProfile, 
         updateProfile, createProfile, CleanProfileLogin, obtainCallsSign, initInfoAdsSeller, initInfoAdsSeller2, obtInfoAdsSeller,
        forgotPassword};

// ###########################################################

function obtainUserProfile():FolloUserProfile{
   if (FolloProfileCurrent === undefined){
    FolloProfileCurrent=SessionStorage.retrieveFolloProfile();
    FolloProfileCurrent.Reset=true;
   }
   return FolloProfileCurrent;
}

function obtInfoAdsSeller():AdsSellerRadio {
  return InfoAdsSellerCurrent;
}

async function initInfoAdsSeller(dispatch,  history, setIsLoading, setError) {

  if (InfoAdsSellerCurrent == undefined ) {
    try {

      setError({ accesError: false });
      setIsLoading(true);
      dispatch({ type: 'SELLER_AWAIT' });

      InfoAdsSellerCurrent=await AdsSellerRadioAPI.get(obtainCallsSign()).then(function(data) { return data});

      setError({ accesError: false });
      setIsLoading(false);
      dispatch({ type: 'SELLER_SUCCESS' });

     } catch (e) {
       history.push('/error');
       InfoAdsSellerCurrent=AdsSellerRadio.vide();
     }
  }
  return false;
}

async function initInfoAdsSeller2(dispatch,  history) {

  if (InfoAdsSellerCurrent == undefined ) {
    try {

      dispatch({ type: 'SELLER_AWAIT' });

      InfoAdsSellerCurrent=await AdsSellerRadioAPI.get(obtainCallsSign()).then(function(data) { return data});

      dispatch({ type: 'SELLER_SUCCESS' });

     } catch (e) {
       history.push('/error');
       InfoAdsSellerCurrent=AdsSellerRadio.vide();
     }
  }
  return false;
}



async function loginUser(dispatch, email, password, history, setIsLoading, setError ) {
  
  var EstValide;
  
  setError({ accesError: false });                         
  setIsLoading(true);
  dispatch({ type: 'LOGIN_AWAIT' });

  if (!!email && !!password) {
     
    await AuthenticationAPI.authenticate(email, password).then(
      result =>{ SessionStorage.updateRefreshToken(new RefreshToken(result.refreshToken, "", result.expiration));
                 EstValide=true;
                },
      error=>{ EstValide=false;
    }); 

    if (EstValide){
      
      var ret = await beginSession(dispatch,password,setIsLoading,setError);
      
      if (ret != undefined) {
        setError({ accesError: false });                         
        setIsLoading(false);
        dispatch({ type: 'LOGIN_SUCCESS' });
        history.push('/app/step01');
      }
    } else {
      setIsLoading(false);
      setError({ accesError: true });
      dispatch({ type: "LOGIN_FAILURE" });
    }

  } else {
    setError({ accesError: true });
    setIsLoading(false);
    dispatch({ type: "LOGIN_FAILURE" });
  }

  return false;
}

function signOut(dispatch, history) {
  SessionStorage.clear();
  ApiClient.removeAuthorization();
  dispatch({ type: "SIGN_OUT_SUCCESS" });
  ClearProfileLogin=true;
  history.push("/login");
}

function CleanProfileLogin(){
  if (ClearProfileLogin){
    ClearProfileLogin=false;
    return true;
  }
  else{
    return false;
  }
}

function showProfile(history) {
  if (CampaignContextSec.IndUpdate == false)
  { ResetProfile();         
    history.push("/app/profile"); }
}

function ResetProfile(){
    FolloProfileCurrent=undefined; 
}

async function beginSession(dispatch,password,setIsLoading, setError):FolloUserProfile {

    const session = SessionStorage.retrieveNotExpired();
    if (!session) {
      dispatch({ type: "RETRIEVE_NO_SESSION" });
      return;
    }

    try {
      const { token, refreshToken } = await SessionAPI.renewSession(session.refreshToken);
      const user = await UserAPI.fetchGeneralUserInformation(token);
      const advertiser = await AdvertiserProfileAPI.getUserAdvertiser(token);
      const custumersRadio = await CustomersAdsFolloAPI.fetchCustomersForStation(obtainCallsSign(),token);


      var custumerInfo=undefined;
      for(var i= 0; i < custumersRadio.length; i++)
         {
            if (user.email.toUpperCase()== custumersRadio[i].contactEmail.toUpperCase()){
             custumerInfo=custumersRadio[i];
            }
         }
         
      if (custumerInfo == undefined) {
        
        var tmpFolloProfile= new FolloUserProfile( { advertiserName:advertiser.name, 
            advertiserReference:advertiser.reference, 
            firstName:user.name,
            surname:user.surname,
            email:user.email,
            password:password,
            confirmPassword:password,
            language:user.language,
            customer:custumerInfo,
            callSign:obtainCallsSign(),
          });
        custumerInfo = await AdsFolloSignUpAPI.signUpCustomer(tmpFolloProfile,tmpFolloProfile.callSign);
      }


      FolloProfileCurrent= new FolloUserProfile( { advertiserName:advertiser.name, 
        advertiserReference:advertiser.reference, 
        firstName:user.name,
        surname:user.surname,
        email:user.email,
        password:password,
        confirmPassword:password,
        language:user.language,
        customer:custumerInfo,
        callSign:obtainCallsSign(),
      });

    
      if (user.language !== i18n.language) {
        i18n.changeLanguage(user.language);
      }

      ApiClient.setAuthorization(token);
      SessionStorage.updateRefreshToken(refreshToken);
      SessionStorage.updateFolloProfile(FolloProfileCurrent);
//      SessionStorage.updateSentryUser(user, advertiser);

//      Sentry.setUser(SessionStorage.retrieveSentryUser());

      return FolloProfileCurrent;
    } catch (error) {
      SessionStorage.clear();
      setIsLoading(false);
      setError({ accesError: true });
      dispatch({ type: "LOGIN_FAILURE" });
      return undefined;
    }
};


async function updateProfile(dispatch, setIsLoading, setError, IndEmailChange, PasswordCurrent ) {

    setError({ accesError: false, MajOk: false, passwordAccesError:false });
    setIsLoading(true);
    dispatch({ type: 'UPDATE_AWAIT' });

    const Session = SessionStorage.retrieveNotExpired();
    const updatedUser= new User({ email: FolloProfileCurrent.email, 
                                  surname: FolloProfileCurrent.surname, 
                                  name: FolloProfileCurrent.firstName, radios:[], 
                                  language: FolloProfileCurrent.language, 
                                  notificationConfiguration: {
                                     dailyNotificationConfiguration: true,
                                     weeklyNotificationConfiguration: true,
                                     propositionNotificationConfiguration: true,
                                  }
                                });
    const advertiser={name:FolloProfileCurrent.advertiserName, reference:FolloProfileCurrent.advertiserReference};
    
    try {

      if (IndEmailChange){

         const emails: Object = { newEmail: FolloProfileCurrent.email,
                                   newEmailVerification: FolloProfileCurrent.email,
                                 };

         await UserProfileAPI.updateEmailFollo(Session.refreshToken.token, emails);
      }
  
      await UserProfileAPI.updateProfile(Session.refreshToken.token, UserProfile.parse(updatedUser));
      
      await AdvertiserProfileAPI.updateAdvertiser(Session.refreshToken.token, advertiser);

      await CustomersAdsFolloAPI.editCustomer(FolloProfileCurrent.callSign, Session.refreshToken.token, FolloProfileCurrent.customer);

      if (PasswordCurrent !== FolloProfileCurrent.password){
         const passwords: Object = {
            currentPassword: PasswordCurrent,
            newPassword: FolloProfileCurrent.password,
            newPasswordVerification: FolloProfileCurrent.confirmPassword,
         };
         try {
            await UserProfileAPI.updatePassword(Session.refreshToken.token, passwords);
          } catch (error) {
            setError({ accesError: false, MajOk: false, passwordAccesError:true });
            setIsLoading(false);
            dispatch({ type: "UPDATE_USER_FAILURE" });            
            return;
          }            
      }

       SessionStorage.updateFolloProfile(FolloProfileCurrent);

       setError({ accesError: false, MajOk: true, passwordAccesError:false });
       setIsLoading(false);
       dispatch({type: "UPDATE_USER"});

//      if (!modifiedUserProfile.hasLanguage(i18n.language)) {
//        switchLanguage(() => {
//        });
//      }

    } catch (error) {
      if (error.response.status !== ERROR_STATUS) {
          setError({ accesError: true, MajOk: false, passwordAccesError:false });
       }
       if (error.response.status === ERROR_STATUS && error.response.data.code === ERROR_MESSAGE) {
           setError({ emailExistError: true, MajOk: false, passwordAccesError:false });
       } 
       else {
           setError({ accesError: true, MajOk: false, passwordAccesError:false });
       }
      setIsLoading(false);
      dispatch({ type: "UPDATE_USER_FAILURE" });
   }
  };

  async function forgotPassword(dispatch, setIsLoading, setError, email) {
    setError({ forgotError: false, OkForgotSend:false });       
    setIsLoading(true);
    dispatch({ type: 'FORGOT_PASSWORD_AWAIT' });

    try {
  
      const ResultCreate = await AuthenticationAPI.requestFolloPasswordReset(email);

      setError({ forgotError: false, OkForgotSend:true });       
      setIsLoading(false);
      dispatch({ type: 'FORGOT_PASSWORD_SUCCESS' });

      return true;
  
    } catch (error) {
       setError({ forgotError: true,  OkForgotSend:false });
       setIsLoading(false);
       dispatch({ type: "FORGOT_PASSWORD_FAILURE" });
       return false;
    }

  }

  async function createProfile(dispatch, setIsLoading, setError, history, infoProfile) {
    setError({ createError: false });       
    setIsLoading(true);
    dispatch({ type: 'CREATE_AWAIT' });
    var newFolloProfile= new FolloUserProfile( { advertiserName:infoProfile.advertiserName, 
      advertiserReference:"", 
      firstName:infoProfile.firstName,
      surname:infoProfile.surname,
      email:infoProfile.email,
      password:infoProfile.password,
      confirmPassword:infoProfile.confirmPassword,
      language:infoProfile.language,
      customer:"",
      callSign:obtainCallsSign(),
    });

    try {
  
      const ResultCreate = await AdsFolloSignUpAPI.signUp(newFolloProfile,newFolloProfile.callSign);

      loginUser(dispatch, newFolloProfile.email, newFolloProfile.password, history, setIsLoading, setError )

    } catch (error) {
       setError({ createError: true});
       setIsLoading(false);
       dispatch({ type: "CREATE_USER_FAILURE" });
   }

};