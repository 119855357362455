// @flow
import Advertiser from 'common/modules/advertiser/domain/Advertiser';

export type CustomerInvitation = {
  advertiserName: string,
  emailAddress: string,
  fullname: string,
  giveAccessToFollo: boolean,
}

class RadioCustomer {

  reference: string;
  advertiser: ?Advertiser; // TODO Remove in STATS-1607
  isRegistered: boolean;
  contact: ?Contact; // RETRO COMPATIBILITY  TODO Remove in STATS-1607
  hasAccessToFollo: boolean;

  advertiserName: string;
  contactEmail: string;
  contactName: string;

  constructor(
    advertiser: ?Advertiser,
    isRegistered: boolean,
    contact: ?Contact,
    hasAccessToFollo: boolean,
    reference: string,
    advertiserName: string,
    contactEmail: string,
    contactName: string) {
    this.reference = reference;
    this.advertiser = advertiser;
    this.isRegistered = isRegistered;
    this.contact = contact;
    this.hasAccessToFollo = hasAccessToFollo;
    this.advertiserName = advertiserName;
    this.contactEmail = contactEmail;
    this.contactName = contactName;
  }

  hasReference(reference: string) {
    return this.reference === reference;
  }

  isSameAs(other: RadioCustomer) {
    return this.reference === other.reference;
  }

  hasAcceptedInvitation(): boolean {
    return this.isRegistered;
  }

  getReference() {
    return this.reference;
  }

  getAdvertiserName() {
    return this.advertiserName;
  }

  getContactEmail(): string {
    return this.contactEmail;
  }

  getContactFullName(): string {
    return this.contactName;
  }

  getContactName(): string {
    return this.contactName.split(' ').slice(0, 1).join();
  }

  getContactSurname(): string {
    const names = this.contactName.split(' ');
    // If more than one word in fullname return the rest
    if (names.length > 1) {
      return names.slice(1).join(' ');
    }
    return '';
  }

  withEmail(contactEmail: string): RadioCustomer {
    return new RadioCustomer(this.advertiser, this.isRegistered, this.contact, this.hasAccessToFollo, this.reference, this.advertiserName, contactEmail, this.contactName);
  }

  withAdvertiserName(advertiserName: string): RadioCustomer {
    return new RadioCustomer(this.advertiser, this.isRegistered, this.contact, this.hasAccessToFollo, this.reference, advertiserName, this.contactEmail, this.contactName);
  }

  withName(contactName: string): RadioCustomer {
    return new RadioCustomer(this.advertiser, this.isRegistered, this.contact, this.hasAccessToFollo, this.reference, this.advertiserName, this.contactEmail, contactName);
  }

  withAccessToFollo(giveAccessToFollo: boolean): RadioCustomer {
    return new RadioCustomer(this.advertiser, this.isRegistered, this.contact, giveAccessToFollo, this.reference, this.advertiserName, this.contactEmail, this.contactName);
  }

  isEqual(other: RadioCustomer): boolean {
    return this.advertiserName === other.advertiserName && this.contactEmail === other.contactEmail
      && this.contactName === other.contactName && this.hasAccessToFollo === other.hasAccessToFollo;
  }

  toObject(): Object {
    return {
      advertiser: this.advertiser ? this.advertiser.toObject() : null,
      contact: this.contact ? this.contact.toObject() : null,
      pending: !this.isRegistered,
      giveAccessToFollo: this.hasAccessToFollo,
      advertiserName: this.advertiserName,
      contactEmail: this.contactEmail,
      contactName: this.contactName,
    };
  }


  static parse(data: Object): RadioCustomer {
    // TODO - STATS-1607 - legacy
    const noAdvertiser = null;
    const noContact = null;

    return new RadioCustomer(noAdvertiser, !data.pending, noContact, data.hasAccessToFollo, data.reference, data.advertiserName, data.contactEmail, data.contactName);
  }

}

export class Contact {
  email: string;
  name: string;
  surname: string;

  constructor(data: { email: string, name: string, surname: string }) {
    this.name = data.name;
    this.surname = data.surname;
    this.email = data.email;
  }

  toObject(): Object {
    return {
      email: this.email,
      name: this.name,
      surname: this.surname,
    };
  }

  static parse(data: Object): Contact {
    return new Contact(data);
  }
}

export default RadioCustomer;
