import defaultTheme from "./default";

import { createTheme} from "@mui/material/styles";

const overrides = {
  typography: {
    h1: {
      fontSize: "3rem",
    },
    h2: {
      fontSize: "2rem",
    },
    h3: {
      fontSize: "1.64rem",
    },
    h4: {
      fontSize: "1.5rem",
    },
    h5: {
      fontSize: "1.285rem",
    },
    h6: {
      fontSize: "1.142rem",
    },
  },
  metrics: {
  navBarHeight: 64,
  sectionWidth: 300,
  tinyMargin: 4,
  smallMargin: 8,
  margin: 16,
  largeMargin: 32,
  actionBarHeight: 64,
  icon: {
    large: {
      width: 60,
      height: 60,
    },
    medium: {
      width: 48,
      height: 48,
    },
    small: {
      width: 36,
      height: 36,
    },
    xsmall: {
      width: 24,
      height: 24,
    },
    tiny: {
      width: 20,
      height: 20,
    },
  },
  tinyBorder: 1,
  smallBorder: 2,

  infoBar: {
    height: 88,
  },

  notificationRectangle: {
    width: 120,
    height: 40,
  },

  table: {
    rowHeight: 55,
  },

  breakpoints: {
    large: 1200,
    medium: 1000,
    small: 800,
    xsmall: 600,
  },
  numeric: {
    largeMetric: 34,
    mediumMetric: 21,
  }
 }
};


const themes = {
  default: createTheme({ ...defaultTheme, ...overrides }),
};

export default themes;
