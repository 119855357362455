// @flow
import moment from 'moment';
import GenderReach from 'common/domain/GenderReach';
import AgeRangeReaches from 'common/domain/AgeRangeReaches';
import DateRange from 'common/domain/DateRange';
import Opportunity from './opportunity';
import { CentralMarket } from './CentralMarket'

export type EstimationSummary = {
  reach: number,
  impressions: number,
  numberOfOpportunities: number,
  totalCost: number,
  averageFrequency: number,
  costPerThousand: number,
}

export type TotalSummary = {
  numberOfOpportunities: number,
  totalCost: number,
  costPerThousand: number,
  impressions: number,
}

type EstimationData = {
  reference: string,
  estimatedOn: moment,
  summary: EstimationSummary,
  opportunities: Array<Opportunity>,
  genderDemography: GenderReach,
  ageGroup: AgeRangeReaches,
  sampling?: { total: number, listening: number },
  dateRange: DateRange,
  centralMarket: ?CentralMarket,
  metricsDateRange: DateRange,
}

export default class Estimation {

  reference: string;
  estimatedOn: moment;
  summary: EstimationSummary;
  opportunities: Array<Opportunity>;
  genderDemography: GenderReach;
  ageGroup: AgeRangeReaches;
  sampling: { total: number, listening: number } | typeof undefined;
  dateRange: DateRange;
  centralMarket: ?CentralMarket;
  metricsDateRange: DateRange;

  constructor(data: EstimationData) {
    this.reference = data.reference;
    this.estimatedOn = data.estimatedOn;
    this.summary = data.summary;
    this.opportunities = data.opportunities;
    this.genderDemography = data.genderDemography;
    this.ageGroup = data.ageGroup;
    this.sampling = data.sampling;
    this.dateRange = data.dateRange;
    this.centralMarket = data.centralMarket;
    this.metricsDateRange = data.metricsDateRange;
  }

  static parse(data: Object): Estimation {
    return new Estimation({
      reference: data.reference,
      estimatedOn: data.estimatedOn && moment(data.estimatedOn, moment.ISO_8601),
      opportunities: data.opportunities.map(o => Opportunity.parse(o)),
      genderDemography: GenderReach.parse(data.genderDemography),
      summary: data.summary,
      sampling: (data.totalSampling && data.listeningSampling) ? {
        total: data.totalSampling,
        listening: data.listeningSampling,
      } : undefined,
      dateRange: DateRange.fromISO(data.dateRange),
      ageGroup: AgeRangeReaches.parse(data.ageGroup),
      centralMarket: data.centralMarket && CentralMarket.parse(data.centralMarket),
      metricsDateRange: DateRange.fromISO(data.metricsDateRange),
    });
  }

  is(reference: string): boolean {
    return this.reference === reference;
  }

  compareTo(other: Estimation) {
    return this.estimatedOn.valueOf() - other.estimatedOn.valueOf();
  }

  getDateRange() {
    return this.dateRange;
  }

  getTotalSummary(): TotalSummary {
    return {
      ...this.summary,
    };
  }

  hasCentralMarket(): boolean {
    return this.centralMarket !== undefined;
  }

  toJSON(): Object {
    return {
      ...this,
      estimatedOn: this.estimatedOn && this.estimatedOn.format(),
      totalSampling: this.sampling ? this.sampling.total : undefined,
      listeningSampling: this.sampling ? this.sampling.listening : undefined,
      opportunities: this.opportunities.map((opportunity: Opportunity) => opportunity.toJSON()),
      dateRange: this.dateRange.toISO(),
      ageGroup: this.ageGroup.toJSON(),
      centralMarket: this.centralMarket && this.centralMarket.toJSON(),
      metricsDateRange: this.metricsDateRange.toISO(),
    };
  }

}
