// @flow
import RadioCustomer from "modules/customers/types/RadioCustomer"

export default class FolloUserProfile {
  callSign:String;
  advertiserName: string;
  advertiserOwner: string;
  firstName: string;
  surname: string;
  email: string;
  password: string;
  confirmPassword: string;
  language: string;
  customer: RadioCustomer;
  callSign: string;

  constructor(data: { advertiserName: string, advertiserReference:string, firstName: string, surname: string, email:string,
                      password:string, confirmPassword:string, language: string, customer:RadioCustomer, callSign:string }) {
    this.callSign= data.callSign;
    this.advertiserName = data.advertiserName;
    this.advertiserReference = data.advertiserReference;
    this.firstName = data.firstName;
    this.email = data.email;
    this.surname = data.surname;
    this.password = data.password;
    this.confirmPassword = data.confirmPassword;
    this.language = data.language;
    this.customer = data.customer;
  }

  static toJson = (token: FolloUserProfile): string => {
    return JSON.stringify(token);
  };
  static fromJson(json: ?string): FolloUserProfile {

      const converter = (key, value) => {
        if (key === 'customer') {
          return new RadioCustomer(value.advertiser, value.isRegistered, value.contact, value.hasAccessToFollo,
                                   value.reference, value.advertiserName, value.contactEmail, value.contactName);
        }

        return value;
      };

    var data  = JSON.parse(json, converter);
    return new FolloUserProfile(data);
  }

}

