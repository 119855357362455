// @flow
import User from 'common/domain/user';
import type { Session } from '../domain/Session';
import { RefreshToken } from '../domain/Session';
import { SentryUser } from '../domain/SentryUser';
import FolloUserProfile from 'domain/FolloUserProfile'

const REFRESH_TOKEN_KEY = 'RefreshToken';
const CURRENT_PROFILE = 'CurrentProfile';
const SENTRY_USER_KEY = 'Sentry';

class SessionStorage {

  storage: Storage;

  constructor(storage: Storage) {
    this.storage = storage;
  }

  retrieveNotExpired = (): ?Session => {
    const refreshToken = RefreshToken.fromJson(this.storage.getItem(REFRESH_TOKEN_KEY));

    if (refreshToken && !refreshToken.isExpired()) {
      return { refreshToken };
    }

    this.clear();
    return undefined;
  }

  updateRefreshToken = (refreshToken: RefreshToken) => {
    this.storage.setItem(REFRESH_TOKEN_KEY, RefreshToken.toJson(refreshToken));
  }

  updateFolloProfile = (profile: FolloUserProfile) => {
    this.storage.setItem(CURRENT_PROFILE,  FolloUserProfile.toJson(profile));
  }

  retrieveFolloProfile = (): ?FolloUserProfile => {
    const storedUser = this.storage.getItem(CURRENT_PROFILE);
    if (!storedUser) {
      return undefined;
    }

    return FolloUserProfile.fromJson(storedUser);
  }

  updateSentryUser = (user: User, id: string) => {
    this.storage.setItem(SENTRY_USER_KEY, JSON.stringify({
      email: user.email,
      language: user.language,
      username: `${user.name} ${user.surname}`,
      id,
    }));
  }

  retrieveSentryUser = (): ?SentryUser => {
    const storedUser = this.storage.getItem(SENTRY_USER_KEY);
    if (!storedUser) {
      return undefined;
    }

    return JSON.parse(storedUser);
  }


  clear = () => {
    this.storage.removeItem(REFRESH_TOKEN_KEY);
    this.storage.removeItem(CURRENT_PROFILE);
  }

}

export default new SessionStorage(window.localStorage);
