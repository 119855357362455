// @flow

import moment from 'moment';
import { createEndOfWeek, createStartOfDay, getFirstSundayOf } from './Helpers';

export class BroadcastWeek {
  startOfWeek: moment;
  endOfWeek: moment;

  constructor(date: moment) {
    this.startOfWeek = createStartOfDay(date);
    this.endOfWeek = createEndOfWeek(date);
  }

  static on(date: moment): BroadcastWeek {
    return new BroadcastWeek(date);
  }

  static of(year: number, week: number) {
    const firstSunday = getFirstSundayOf(year);
    const withWeeks = firstSunday.clone().add(week - 1, 'weeks');

    return new BroadcastWeek(withWeeks);
  }

  next() {
    const nextStartOfWeek = this.startOfWeek.clone().add(1, 'week');
    return BroadcastWeek.on(nextStartOfWeek);
  }

  isSame(other: BroadcastWeek) {
    return this.startOfWeek.isSame(other.startOfWeek) && this.endOfWeek.isSame(other.endOfWeek);
  }

  month(): number {
    return this.endOfWeek.month() + 1;
  }

  week(): number {
    const firstSunday = getFirstSundayOf(this.year());

    const nbOfWeeksFromFirstSunday = this.endOfWeek.diff(firstSunday, 'weeks', true);
    return nbOfWeeksFromFirstSunday + 1;
  }

  getStartOfWeek(): moment {
    return this.startOfWeek;
  }

  getEndOfWeek(): moment {
    return this.endOfWeek;
  }

  year(): number {
    return this.endOfWeek.year();
  }
}
