import React, { useState } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  MenuItem,
  Select,
  TextField,
  Fade,
} from "@mui/material";
import Email from "domain/Email";
import FolloUserProfile from "domain/FolloUserProfile"
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { withTranslation } from 'react-i18next';
import i18n from "i18n/i18n"

// styles
import useStyles from "./styles";
import { useTheme } from "@mui/styles";

// components
import PageTitle from "../../components/PageTitle/PageTitle";
import Widget from "../../components/Widget/Widget";

import { useUserDispatch, obtainUserProfile, updateProfile } from "../../context/UserContext";

var EnErreurProfile={
  advertiserNameError: false,
  firstNameError: false,
  surnameError: false,
  emailError: false,
  emailNotValideError: false,
  emailConfError: false,
  emailConfNotValideError: false,
  passwordError: false,  
  passwordConfError: false,
  accesError: false,
  emailUpdateError: false,
  emailExistError: false,
  passwordAccesError:false,
  MajOk: false,
};

var EnChargement=false;

function SetEnErreur(vIndErreur){
  EnErreurProfile=vIndErreur;
}

function SetEnChargement(vIndCharge){
  EnChargement=vIndCharge;
}


function Profile(props) {
    const { t } = props;
    var classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    // global
    var userDispatch = useUserDispatch();
    var ProfileFollo:FolloUserProfile = obtainUserProfile();



    // local
    var [isLoading, setIsLoading] = useState(false);
    var [error, setError] = useState(EnErreurProfile);

    const [openDialogEmailChange, setOpenDialogEmailChange] = React.useState(false);    

    var [advertiserNameValue, setAdvertiserNameValue] = useState(ProfileFollo.advertiserName);
    var [firstNameValue, setFirstNameValue] = useState(ProfileFollo.firstName);
    var [SurnameValue, setSurnameValue] = useState(ProfileFollo.surname);
    var [LanguageValue, setLanguageValue] = useState(ProfileFollo.language);
    var [passwordValue, setPasswordValue] = useState(ProfileFollo.password);
    var [emailOrigValue, setEmailOrigValue] = useState(ProfileFollo.email);
    var [emailChangeValue, setEmailChangeValue] = useState(false);
    var [emailValue, setEmailValue] = useState(ProfileFollo.email);
    var [emailConfValue, setEmailConfValue] = useState("");
    var [passwordOrigValue, setPasswordOrigValue] = useState(ProfileFollo.password);
    var [passwordValue, setPasswordValue] = useState(ProfileFollo.password);
    var [confirmPasswordValue, setconfirmPasswordValue] = useState(ProfileFollo.confirmPassword);

    const ResetErr = () => {
      var tmp=  { emailError: error.emailError,
                  emailNotValideError: error.emailNotValideError,
                  emailConfError: error.emailConfError,
                  emailConfNotValideError: error.emailConfNotValideError,
                  passwordError: error.passwordError,  
                  advertiserNameError: error.advertiserNameError,
                  firstNameError: error.firstNameError,
                  surnameError: error.surnameError,
                  passwordConfError: error.passwordConfError,
                  accesError:false,
                  emailUpdateError:false,
                  emailExistError:false,
                  passwordAccesError:false,
                  MajOk: false,
                };
      return tmp;
    }

    if (ProfileFollo.Reset){
      var TmpErr=ResetErr();
      setError(TmpErr);
      ProfileFollo.Reset=false;
    }
    
    const handleEmailChange = (email: string) => {
      var TmpErr=ResetErr();
      TmpErr.emailError=false;
      TmpErr.emailNotValideError=false;
      setEmailValue(email);
      if (email ==''){
        TmpErr.emailError=true;
      }
      else{
         if (!Email.isValid(email)) {
          TmpErr.emailNotValideError=true;
         }
         else{
          if (email == emailOrigValue ) {
            setEmailChangeValue(false);
          }
          else {
              setEmailChangeValue(true);
          }
         }

      }
      setError(TmpErr);
    };

    const handleEmailConfChange = (email: string) => {
      var TmpErr=ResetErr();
      TmpErr.emailConfErrorError=false;
      TmpErr.emailConfNotValideError=false;
      setEmailConfValue(email);
      if (!Email.isValid(email)) {
          TmpErr.emailConfNotValideError=true;
      }
      else{
        if (email !== emailValue ) {
           TmpErr.emailConfErrorError=true;
        }
      }
     setError(TmpErr);
    };   

    const handlePassword2Change = (password: string, passwdConf: string) => {
      var TmpErr=ResetErr();
      TmpErr.passwordError=false;
      TmpErr.passwordConfError=false;
      setPasswordValue(password);
      if (password ==''){
        TmpErr.passwordError=true;
      }
  
      setconfirmPasswordValue(passwdConf);
      if (passwdConf ==''){
        TmpErr.passwordConfError=true;
      }
      else {
        if (passwdConf !== password ){
           TmpErr.passwordConfError=true;
        }
      }
      setError(TmpErr);
    };
    
    const handleAdvertiserNameChange = (advertiserName: string) => {
      var TmpErr=ResetErr();
      TmpErr.advertiserNameError=false;
      setAdvertiserNameValue(advertiserName);
      if (advertiserName ==''){
        TmpErr.advertiserNameError=true;
      }
      setError(TmpErr);
    };
  
    const handleFirstNameChange = (firstName: string) => {
      var TmpErr=ResetErr();
      TmpErr.firstNameError=false;
      setFirstNameValue(firstName);
      if (firstName ==''){
        TmpErr.firstNameError=true;
      }
      setError(TmpErr);
    }; 
    
    const handleSurnameChange = (Surname: string) => {
      var TmpErr=ResetErr();
      TmpErr.surnameError=false;
      setSurnameValue(Surname);
      if (Surname ==''){
        TmpErr.surnameError=true;
      }
      setError(TmpErr);
    }; 

    const IsCreateNotValid = () => {
      return advertiserNameValue.length === 0 ||  firstNameValue.length === 0 ||  SurnameValue.length === 0 || 
             emailValue.length === 0 ||   passwordValue.length === 0 ||  confirmPasswordValue.length === 0 || 
             error.advertiserNameError || error.firstNameError ||  error.surnameError ||
             error.emailError || error.passwordError || error.emailNotValideError || error.passwordConfError
    }

    const IsEmailConfNotValid = () => {
      return emailConfValue.length === 0 ||   error.emailConfErrorError || error.emailConfNotValideError
    }
    
    const handleCloseEmailChangeValid = () => {
       setOpenDialogEmailChange(false);      
       UpdateProfile();
    };

    const handleCloseEmailChangeAnnul = () => {
       setOpenDialogEmailChange(false);
    };

    const UpdateProfile = () => {
        ProfileFollo.advertiserName=advertiserNameValue;
        ProfileFollo.firstName=firstNameValue;
        ProfileFollo.surname=SurnameValue;
        ProfileFollo.email=emailValue;
        ProfileFollo.customer.advertiserName=advertiserNameValue;
        ProfileFollo.customer.contactEmail=emailValue;
        ProfileFollo.customer.contactName=firstNameValue+" "+SurnameValue;
        ProfileFollo.language=LanguageValue;
        ProfileFollo.password=passwordValue;
        ProfileFollo.confirmPassword=confirmPasswordValue;

        i18n.changeLanguage(LanguageValue);

        updateProfile( userDispatch,
             SetEnChargement,
             SetEnErreur,
             emailChangeValue,
             passwordOrigValue);
    }

  return (
    <React.Fragment>
      <PageTitle title={t('commonRegistration.libUserProfilTitle')}  />  
    
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
        <Widget disableWidgetMenu>

      <div className={classes.formContainer}>
        <div className={classes.form}>
        <div className={classes.dashedBorder}>

              <TextField
                id="CompanyName"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                required
                value={advertiserNameValue}
                label={t('commonRegistration.ediEnterprise')} 
                onChange={(e) => handleAdvertiserNameChange (e.target.value)} 
                margin="normal"
                type="text"
                fullWidth
                helperText={error.advertiserNameError ? t('commonRegistration.messEnterEnterprise'): undefined} 
                error={error.advertiserNameError}
              />

              <TextField
                id="email"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                required
                value={emailValue}
                onChange={(e) => handleEmailChange(e.target.value)  } 
                margin="normal"
                label={t('commonRegistration.ediEmail')} 
                type="email"
                fullWidth
                helperText={error.emailError ? t('commonRegistration.messEnterEmail') : error.emailNotValideError ? t('commonRegistration.messEmailValid') : undefined} 
                error={error.emailError || error.emailNotValideError}
            />
              <Select
                id="language"
                value={LanguageValue}
                onChange={e => setLanguageValue(e.target.value)}
               >
               <MenuItem value={"en"}>{t('commonRegistration.lisEnglish')} </MenuItem> 
               <MenuItem value={"fr"}>{t('commonRegistration.lisFrench')} </MenuItem> 
             </Select>

              <TextField
                id="name"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                required
                value={firstNameValue}
                onChange={(e) => handleFirstNameChange(e.target.value)} 
                margin="normal"
                label={t('commonRegistration.ediFirstName')}  
                type="text"
                fullWidth
                helperText={error.firstNameError ? t('commonRegistration.messEnterFirstName'): undefined}
                error={error.firstNameError}
              />

               <TextField
                id="surname"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                required
                value={SurnameValue}
                onChange={(e) => handleSurnameChange(e.target.value)}
                margin="normal"
                label={t('commonRegistration.ediSurname')}  
                type="text"
                fullWidth
                helperText={error.surnameError ? t('commonRegistration.messEnterSurname'): undefined}
                error={error.surnameError}
              />
              <TextField
                id="password"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                required
                value={passwordValue}
                onChange={(e) => handlePassword2Change(e.target.value,confirmPasswordValue)}  
                margin="normal"
                label={t('commonRegistration.ediPassWord')}  
                type="password"
                fullWidth
                helperText={error.passwordError ? t('commonRegistration.messEnterPassword'): undefined}
                error={error.passwordError}
            />

              <TextField
                id="passwordConf"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                required
                value={confirmPasswordValue}
                onChange={(e) => handlePassword2Change(passwordValue,e.target.value)}    
                margin="normal"
                label={t('commonRegistration.ediConfPassWord')}     
                type="password"
                fullWidth
                helperText={error.passwordConfError ? t('commonRegistration.messConfPassword'): undefined}
                error={error.passwordConfError}
              />

             <Fade in={error.accesError}>
                <Typography color="secondary" className={classes.errorMessage}>
                   {t('commonRegistration.messUpdProfilAccesErr')} 
                 </Typography>
             </Fade>
             <Fade in={error.emailExistError}>
                <Typography color="secondary" className={classes.errorMessage}>
                    {t('commonRegistration.messEmailAlreadyUseErr')} 
                 </Typography>
             </Fade>
             <Fade in={error.passwordAccesError}>
                <Typography color="secondary" className={classes.errorMessage}>
                    {t('commonRegistration.messPasswordChangeErr')} 
                 </Typography>
             </Fade>
             <Fade in={error.MajOk}>
                <Typography color="secondary" className={classes.OkMessage}>
                  {t('commonRegistration.messProfileSaveCorrectly')} 
                 </Typography>
             </Fade>
             
              <div className={classes.creatingButtonContainer}>
                {isLoading ? (
                  <CircularProgress size={26} />
                ) : (
                  <Button
                  onClick={e =>{
                    e.preventDefault();

                    if (emailChangeValue){
                      setOpenDialogEmailChange(true);
                    }
                    else {
                      UpdateProfile();
                    }

                    }
                  }
                  disabled={ IsCreateNotValid() }
                    size="large"
                    variant="contained"
                    color="primary"
                    fullWidth
                    className={classes.createAccountButton}
                  >
                    {t('commonRegistration.butUpdate')}  
                  </Button>
                )}
              </div>
    
        </div>

      </div>
      </div>
      </Widget>

      </Grid>
    </Grid>

    <Dialog
        fullScreen={fullScreen}
        open={openDialogEmailChange}
        onClose={handleCloseEmailChangeAnnul}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          { t('commonRegistration.libConfirmEmail') }
        </DialogTitle>
        <DialogContent>
            <TextField
                id="emailConf"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                required
                value={emailConfValue}
                onChange={(e) => handleEmailConfChange(e.target.value)  } 
                margin="normal"
                label={t('commonRegistration.ediEmail')} 
                type="email"
                fullWidth
                helperText={error.emailConfError ? t('commonRegistration.messConfirmEmail') : error.emailConfNotValideError ? t('commonRegistration.messValidEmail') : undefined} 
                error={error.emailConfError || error.emailConfNotValideError}
            />
           </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseEmailChangeAnnul}>
            {t('commonRegistration.butAbend')} 
          </Button>
          <Button onClick={handleCloseEmailChangeValid} autoFocus disabled={ IsEmailConfNotValid() }>
            {t('commonRegistration.butValid')} 
          </Button>
        </DialogActions>
      </Dialog>


    </React.Fragment>
  );
}

export default withTranslation()(Profile);