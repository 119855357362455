import { type AgeRangeReachMetadataJsonAPI } from 'common/domain/AgeRangeReachMetadata';
import { type AgeRange } from 'common/domain/AgeRangeReach';
import { type AgeRangeReachesData } from 'common/domain/AgeRangeReaches';

export default class AgeRangeReachesAPIHelper {
  static flatToJson(data: any, metadatas?: AgeRangeReachMetadataJsonAPI): AgeRangeReachesData {
    const ageRanges: AgeRange[] = Object.keys(data);

    const values = ageRanges.map((range: AgeRange) => ({
      range,
      reach: data[range],
      metadata: metadatas ? metadatas[range] : undefined,
    }));

    return {
      values,
    };
  }
}
