import React from "react";
import { createRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from "@mui/material";

import i18n from './i18n/i18n';
import Themes from "themes";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";
import { LayoutProvider } from "./context/LayoutContext";
import { UserProvider, obtainCallsSign, obtainInfoAdsSeller } from "./context/UserContext";
import CampaignProvider  from "./context/CampaignContext";

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript

root.render(
  <LayoutProvider>
    <UserProvider>
       <CampaignProvider>
         <ThemeProvider theme={Themes.default}>
           <I18nextProvider i18n={i18n}>
             <CssBaseline />
             <App />
           </I18nextProvider>                      
         </ThemeProvider>
      </CampaignProvider>
    </UserProvider>
  </LayoutProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
