import React from "react";
import SessionStorage from 'common/modules/session/services/SessionStorage';
import CampaignStorage from "modules/campaignStorage/campaignStorage";
import CampaignData, {CampaignAudioFile}  from '../domain/CampaignType';
import moment from 'moment';
import CampaignAPI from "modules/campaigns/services/CampaignAdsFolloAPI";
import DateRange from 'common/domain/DateRange';
import { Contract } from 'modules/campaigns/domain/contract';
import FolloUserProfile from "domain/FolloUserProfile"
import {obtainUserProfile,obtainCallsSign}  from "context/UserContext"    
import i18n from "i18n/i18n";

export var CampaignContextSec:CampaignData;
export var CampaignContexteFile:CampaignAudioFile;
export var CampaignContext = React.createContext();
export var PaiementDone=false;
export var ErreurCallAPI=false;

export default class CampaignProvider extends React.Component {
  constructor(props) {
    super(props);

    this.state=CampaignContextSec;

    CampaignContextSec=CampaignStorage.retrieveNotExpired(obtainCallsSign());

    if (CampaignContextSec === undefined) {
      CampaignContextSec=new CampaignData('',new moment().add(3, 'days'));
      CampaignStorage.updateCampaignToken(CampaignContextSec,obtainCallsSign());
    }
  }

    render() {
      return (
       <CampaignContext.Provider value={this.state}>
           {this.props.children}
       </CampaignContext.Provider>      
       );
    }
}


export function ObtCampaign() {
  if (CampaignContextSec === undefined) {
     CampaignContextSec=CampaignStorage.retrieveNotExpired(obtainCallsSign());
     
     if (CampaignContextSec === undefined) {
       NewCampaign()
     }
  }
  if (CampaignContextSec.CampaignSave===true ){
    CampaignContextSec.newState=false;
  }
  return CampaignContextSec;
}

export function CreatCampaignFile(DefFile:File):CampaignAudioFile{
  CampaignContexteFile=undefined;
  CampaignContexteFile=new CampaignAudioFile(DefFile);
  CampaignContextSec.audioFileName=CampaignContexteFile.DefFile.path;
  return CampaignContexteFile;
}

export function ObtainCampaignFile():CampaignAudioFile{
  return CampaignContexteFile;
}

export function FlushCampaignFile():CampaignAudioFile{
  if (CampaignContexteFile){
      CampaignContexteFile.flush(); 
      CampaignContexteFile=undefined;
  }
  return CampaignContexteFile;
}

export function FlushCampaign() {
  var ProfileFollo:FolloUserProfile = obtainUserProfile();
  CampaignContextSec=undefined;
  CampaignStorage.clear(ProfileFollo.callSign);
  PaiementDone=false;
}

export function NewCampaign() {
  var ProfileFollo:FolloUserProfile = obtainUserProfile();
  CampaignContextSec=undefined;
  CampaignContextSec=new CampaignData('',new moment().add(3, 'days'));
  CampaignContextSec.contactName=ProfileFollo.firstName + " "+ ProfileFollo.surname;
  CampaignContextSec.advertiserName=ProfileFollo.advertiserName;
  CampaignStorage.updateCampaignToken(CampaignContextSec,ProfileFollo.callSign);
  PaiementDone=false;
}

export function SauverStep01(name, objective) {
  CampaignContextSec.newState=false;
  CampaignContextSec.setStep01(name,objective);
  CampaignStorage.updateCampaignToken(CampaignContextSec,obtainCallsSign());
}    

export function SauverStep02(AgeRange,Demography) {
  CampaignContextSec.setStep02(AgeRange,Demography);
  CampaignStorage.updateCampaignToken(CampaignContextSec,obtainCallsSign());
}

export function SauverStep03(region) {
  CampaignContextSec.setStep03(region);
  CampaignStorage.updateCampaignToken(CampaignContextSec,obtainCallsSign());
}

export function SauverStep04(ImpressionValue,CustomAmountValue,PromoCodeValue) {
  CampaignContextSec.setStep04(ImpressionValue,CustomAmountValue,PromoCodeValue);
  CampaignStorage.updateCampaignToken(CampaignContextSec,obtainCallsSign());
}

export function SauverStep05(FromValue,ToValue) {
  var ProfileFollo:FolloUserProfile = obtainUserProfile();
  CampaignContextSec.setStep05(FromValue,ToValue);
  CampaignContextSec.contactName=ProfileFollo.firstName + " "+ ProfileFollo.surname;
  CampaignContextSec.advertiserName=ProfileFollo.advertiserName;
  CampaignStorage.updateCampaignToken(CampaignContextSec,ProfileFollo.callSign);
}

export function SauverStep06(SpotDurationValue,SpotVoiceValue,SpotSoundtrackValue,SpotTextValue,ContactNameValue,PhoneContactValue,PhoneExtensionContactValue) {
  var FileName="";
  if (CampaignContexteFile !== undefined){
    FileName=CampaignContexteFile.DefFile.path;
  }
  CampaignContextSec.setStep06(FileName,SpotDurationValue,SpotVoiceValue,SpotSoundtrackValue,SpotTextValue,ContactNameValue,PhoneContactValue,PhoneExtensionContactValue);
  CampaignStorage.updateCampaignToken(CampaignContextSec,obtainCallsSign());
}

export function SauverConfirmLocal() {
  CampaignStorage.updateCampaignToken(CampaignContextSec,obtainCallsSign());
}

export function FormatCampaignDesc(IdConfPayPal:string, name:string):string {
  return CampaignContextSec.getDescription()+"\n"+i18n.t('common.libPayPalIdConf')+IdConfPayPal +"\n"+i18n.t('common.libBy')+name;
}

export function FormatCampaignMessage(IdConfPayPal:string, name:string):string {
  return CampaignContextSec.spotText+"\n"+"\n"+"--------------"+"\n"+"\n"+CampaignContextSec.getDescription()+"\n"+i18n.t('common.libPayPalIdConf')+IdConfPayPal +"\n"+i18n.t('common.libBy')+name;
}

export function FormatCampaignMessageForMail():string {
  return CampaignContextSec.spotText+"%0D%0A%0D%0A"+"--------------"+"%0D%0A%0D%0A"+CampaignContextSec.getDescription().replaceAll("\n","%0D%0A");
}

export async function UpdateCampaign(FolloProfile:FolloUserProfile, IdConfPayPal:string, name:string) {
  ErreurCallAPI=false;
  const Session = SessionStorage.retrieveNotExpired();

  var DescMessageData = {
    description:  FormatCampaignDesc(IdConfPayPal,name),
    message: FormatCampaignMessage(IdConfPayPal,name),
  }
  
  let newCampaign;
  try {

    newCampaign = await CampaignAPI.updateCampaignDescMessage(Session.refreshToken.token, FolloProfile.callSign,  CampaignContextSec.reference, DescMessageData) 

  } catch (e) {
    ErreurCallAPI=true;
    alert(i18n.t('common.messInternalErrorUpdCampaign'));
    return;
  }

}

export async function SaveCampaign(FolloProfile:FolloUserProfile, IdConfPayPal:string, name:string) {
  ErreurCallAPI=false;
  const Session = SessionStorage.retrieveNotExpired();

  var DateRangeCampaign=new DateRange({ from:moment(CampaignContextSec.fromDate),to: moment(CampaignContextSec.toDate) });
  var newCampaignData = {
     name: CampaignContextSec.name,                                                                    
     description: FormatCampaignDesc(IdConfPayPal,name),
     message: FormatCampaignMessage(IdConfPayPal,name),
     dateRange: DateRangeCampaign,
     audioFilePath: CampaignContextSec.audioFileName,
     customer: FolloProfile.customer,
     giveAccessToFollo: true
   };
   var contract = Contract.empty(DateRangeCampaign);

   contract.months[0].cost.amount=CampaignContextSec.montant+CampaignContextSec.montProdMessage;
   contract.months[0].spots.amount=CampaignContextSec.GetNbSpot();

  let newCampaign;
  try {

    newCampaign = await CampaignAPI.createNewCampaign(Session.refreshToken.token, FolloProfile.callSign,  newCampaignData, contract);

    if (CampaignContexteFile) {
        await CampaignAPI.uploadAudioFileMessage(Session.refreshToken.token, FolloProfile.callSign, newCampaign.reference,  CampaignContexteFile.DefFile );    
    }        
    CampaignContextSec.CampaignSave=true;
    CampaignContextSec.reference=newCampaign.reference;
    SauverConfirmLocal();

  } catch (e) {
    ErreurCallAPI=true;
    CampaignContextSec.CampaignSave=false;
    alert(i18n.t('common.messInternalErrorSaveCampaign'));
    return;
  }

}