// @flow

import { BroadcastMonth } from 'modules/broadcastCalendar/BroadcastMonth';
import DateRange from 'common/domain/DateRange';

export interface ContractMonth {
  broadcastMonth: BroadcastMonth;
  spots: { amount: number };
  cost: { amount: number };
}

function createContractMonthJSON({ broadcastMonth, spots, cost }: ContractMonth) {
  return {
    broadcastMonth: broadcastMonth.toISO(),
    spots,
    cost,
  };
}

interface ContractData {
  months: ContractMonth[];
}

export class Contract implements ContractData {

  months: ContractMonth[];

  static empty(dateRange: DateRange) {
    const months = BroadcastMonth.between(dateRange);
    const contractMonths = months.map(broadcastMonth => ({ broadcastMonth, spots: { amount: 0 }, cost: { amount: 0 } }));

    return new Contract({ months: contractMonths });
  }

  constructor({ months }: ContractData) {
    this.months = months;
  }

  getTotalCost() {
    return this.months
      .map(month => month.cost.amount)
      .reduce((a, b) => a + b);
  }

  getTotalSpots() {
    return this.months
      .map(month => month.spots.amount)
      .reduce((a, b) => a + b);
  }

  getMonths() {
    return this.months;
  }

  change(month: ContractMonth): Contract {
    const newMonths = this.months.map((m) => {
      if (!m.broadcastMonth.isSame(month.broadcastMonth)) {
        return m;
      }

      return month;
    });

    return new Contract({ months: newMonths });
  }

  toJSON() {
    const months: Object[] = this.months.map(createContractMonthJSON);

    return {
      'months': months,
    };
  }
}
