/** @flow */
import Radio from 'common/domain/radio';

export interface NotificationConfiguration {
  dailyNotificationConfiguration: boolean;
  weeklyNotificationConfiguration: boolean;
  propositionNotificationConfiguration: boolean;
}

export default class User {

  email: string;
  surname: string;
  name: string;
  language: string;
  radios: Array<Radio>;

  notificationConfiguration: NotificationConfiguration;

  constructor(data: { email: string, surname: string, name: string, radios: Array<Radio>, language: string, notificationConfiguration: NotificationConfiguration }) {
    this.email = data.email;
    this.surname = data.surname;
    this.name = data.name;
    this.radios = data.radios;
    this.language = data.language;
    this.notificationConfiguration = data.notificationConfiguration;
  }

  setUserProfile(data: Object): void {
    this.name = data.name;
    this.surname = data.surname;
    this.language = data.language;
  }

  findRadio(callSign: string) {
    return this.radios.find((radio: Radio) => radio.is(callSign));
  }

  getLanguage(): string {
    return this.language;
  }

  hasLanguage(language: string): boolean {
    return this.language === language;
  }

  static parse(data: Object): User {
    const radios = data.radios.map(Radio.fromJSON);
    return new User({ ...data, radios });
  }
}
