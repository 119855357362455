// @flow
import api from 'common/services/api';
import User from 'common/domain/user';
import UserProfile from '../domain/UserProfile';

class UserProfileAPI {

  buildHeaders = (token: string): Object => ({ Authorization: 'Bearer ' + token });
  baseUrl = '/statsradio/users/';

  async fetchProfile(token: string): Promise<Object> {
    const url = this.baseUrl + 'me';

    const { data } = await api.get(url, {}, { headers: this.buildHeaders(token) });
    const profile = UserProfile.parse(data);
    return { email: data.email, profile: profile };
  }

  async updateProfile(token: string, userProfile: UserProfile): Promise<*> {
    const url = this.baseUrl + 'update-profile';

    const payload = { ...userProfile };

    const { data } = await api.put(url, payload, { headers: this.buildHeaders(token) });
    return User.parse({ ...data, radios: [] });
  }

  async updatePassword(token: string, passwords: Object): Promise<*> {
    const url = this.baseUrl + 'change-password';

    const data = { ...passwords };

    return await api.put(url, data, { headers: this.buildHeaders(token) });
  }

  async updateEmail(token: string, emails: Object): Promise<*> {
    const url = this.baseUrl + 'change-email';

    const data = { ...emails };

    return await api.post(url, data, { headers: this.buildHeaders(token) });
  }

  async updateEmailFollo(token: string, emails: Object): Promise<*> {
    const url = this.baseUrl + 'follo/change-email';

    const data = { ...emails };

    return await api.post(url, data, { headers: this.buildHeaders(token) });
  }

  async confirmEmail(token: string) {
    const url = this.baseUrl + `confirm-email/?token=${token}`;

    return await api.get(url, {}, {});
  }
}

export default new UserProfileAPI();
