// @flow
import * as React from 'react';
import styled, { withTheme } from 'styled-components';
import { translate } from 'react-i18next';
import type { GenderReachData } from 'common/domain/GenderReach';
import LoadingSpinner from 'common/components/LoadingSpinner';
import { Percent } from '../../common/components/Number';
import GenderPieGraph from './GenderPieGraph';
import Widget from "../Widget/Widget";


const Wrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const HorizontalCardWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
`;

const VerticalCardWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const CardLeft = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-end;
`;

const CardRight = styled(CardLeft)`
  align-items: flex-start;
`;

const VerticalGenderWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  font-size: 1.5rem;
`;

const HorizontalGenderWrapper = styled.div`
  width: 100%;
  font-size: ${({ fontSize }) => (`${fontSize}px` || '1.2rem')};
`;

type LegendPosition = 'around' | 'bottom';

type Props = {
  data: GenderReachData;
//  errorStyle?: Object;
  isLoading?: boolean;
  colors: any,
  legendPosition?: LegendPosition;
  animate?: boolean;
  fontSize?: number;

  theme: Object;
//  t: any;
};

type State = {
  pieData: Array<Object>;
  malePercent: number;
  femalePercent: number;
};


export default class GenderCard extends React.Component<Props, State> {
  static defaultProps = {
    legendPosition: 'around',
    animate: true,
  };

  state = { pieData: [], malePercent: 0, femalePercent: 0 };

  static getDerivedStateFromProps(props, state) {
    const { data, t } = props;
    if (!data) {
      return { pieData: [], malePercent: 0, femalePercent: 0 };
    }
    const total = data.male + data.female;
    const malePercent = data.male / total;
    const femalePercent = data.female / total;

    const pieData = [
      { name: 'Hommes', value: malePercent, color: 'male' },
      { name: 'Femmes', value: femalePercent, color: 'female' },
    ];

    return {
      pieData,
      malePercent,
      femalePercent,
    };
  }

  render() {
    const { data, legendPosition, isLoading, colors, animate, fontSize } = this.props;
   // const { fonts, colors } = this.props.theme;
    const { malePercent, femalePercent, pieData } = this.state;

    return (
      <Wrapper>
            <Widget
              title="Démographie"
              upperTitle
              disableWidgetMenu
            >
        </Widget>        
      </Wrapper>
    );
  }
}



/*
return (
  <Wrapper>
        <Widget
          title="Démographie"
          upperTitle
          disableWidgetMenu
        >
    {isLoading &&
    <LoadingSpinner />
    }
    {!data && !isLoading &&
    <span style={{ ...fonts.style.cardTitle, ...errorStyle }}>
        noDataAvailable
      </span>
    }
    {data && !isLoading && legendPosition === 'around' &&
    <HorizontalCardWrapper>
      <CardLeft>
        <VerticalGenderWrapper gender="male" style={{ color: colors.male }}>Hommes <Percent value={malePercent} /></VerticalGenderWrapper>
      </CardLeft>
      <GenderPieGraph pieData={pieData} colors={colors} animate={animate} />
      <CardRight>
        <VerticalGenderWrapper gender="female" style={{ color: colors.female }}>Femmes <Percent value={femalePercent} /></VerticalGenderWrapper>
      </CardRight>
    </HorizontalCardWrapper>
    }
    {data && !isLoading && legendPosition === 'bottom' &&
    <VerticalCardWrapper>
      <GenderPieGraph pieData={pieData} colors={colors} animate={animate} />
      <div style={{ textAlign: 'center' }}>
        <HorizontalGenderWrapper fontSize={fontSize} style={{ color: colors.male }}>Hommes <Percent value={malePercent} /></HorizontalGenderWrapper>
        <HorizontalGenderWrapper fontSize={fontSize} style={{ color: colors.female }}>Femmes <Percent value={femalePercent} /></HorizontalGenderWrapper>
      </div>
    </VerticalCardWrapper>
    }
    </Widget>        
  </Wrapper>
);
*/
