// @flow
import moment from 'moment';

export default class RefreshToken {
  value: string;
  token:String;
  expiration: moment;

  constructor(value: string, token:string, expiration: moment) {
    this.value = value;
    this.token=token;
    this.expiration = expiration;
  }

  isExpired() {
    return moment().isAfter(this.expiration);
  }

  withExpiration(expiration: moment) {
    return new RefreshToken(this.value, expiration);
  }

  static toJson = (tokenOrig: RefreshToken): string => {
    const { value, token, expiration } = tokenOrig;
    return JSON.stringify({ value, token, expiration });
  };

  static fromJson = (json: ?string): ?RefreshToken => {
    if (!json) {
      return undefined;
    }

    const converter = (key, value) => {
      if (key === 'expiration') {
        return moment(value);
      }
      return value;
    };

    const { value, token, expiration } = JSON.parse(json, converter);
    return new RefreshToken(value, token, expiration);
  }
}
