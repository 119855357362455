// @flow
import moment from 'moment';
import type { FeaturePermission } from 'common/domain/FeaturePermission';
import Geolocation, { type GeolocationData } from './Geolocation';

type RadioData = {
  callSign: string,
  name: string,
  logo: string,
  region: number,
  geolocation: GeolocationData,
  createdAt: string,
  featureEnabled: FeaturePermission[],
  configurationStep: RadioConfigurationStepName,
  configurationProgress: number,
  hasAnomalies: boolean;
  hasConnectors: boolean;
  isSmallMarket: boolean;
};

export default class Radio {
  callSign: string;
  name: string;
  logo: string;
  geolocation: Geolocation;
  region: number;
  createdAt: moment;
  featurePermissions: FeaturePermission[];
  configurationProgress: number;
  hasAnomalies: boolean;
  hasConnectors: boolean;
  isSmallMarket: boolean;

  constructor(callSign: string, name: string, logo: string, region: number, geolocation: Geolocation, createdAt: moment,
              featurePermissions: FeaturePermission[] = [], configurationStep: RadioConfigurationStepName, configurationProgress: number,
              hasAnomalies: boolean, hasConnectors: boolean, isSmallMarket: boolean) {
    this.callSign = callSign;
    this.name = name;
    this.logo = logo;
    this.region = region;
    this.geolocation = geolocation;
    this.createdAt = createdAt;
    this.featurePermissions = featurePermissions;
    this.configurationStep = configurationStep;
    this.configurationProgress = configurationProgress;
    this.hasAnomalies = hasAnomalies;
    this.hasConnectors = hasConnectors;
    this.isSmallMarket = isSmallMarket;
  }

  is(callSign: string): boolean {
    return this.callSign.toLowerCase() === callSign.toLowerCase();
  }

  configurationIs(stepName: RadioConfigurationStepName) {
    return this.configurationStep === stepName;
  }

  isSame(radio: Radio): boolean {
    return this.is(radio.callSign);
  }

  isReady() {
    return this.configurationStep === 'DONE';
  }

  isConfigured(): boolean {
    return this.configurationProgress >= 100;
  }

  getCountry(): string {
    return this.geolocation.country;
  }

  getCreatedAt(): moment {
    return this.createdAt;
  }

  hasFeaturePermission(permission: FeaturePermission): boolean {
    return this.featurePermissions.indexOf(permission) >= 0;
  }

  hasConnector(): boolean {
    return this.hasConnectors;
  }

  isInSmallMarket(): boolean {
    return this.isSmallMarket;
  }

  toJSON(): RadioData {
    return {
      callSign: this.callSign,
      name: this.name,
      logo: this.logo,
      region: this.region,
      geolocation: this.geolocation.toJSON(),
      createdAt: this.createdAt.format('YYYY-MM-DD'),
      featureEnabled: this.featurePermissions,
      configurationProgress: this.configurationProgress,
      configurationStep: this.configurationStep,
      hasAnomalies: this.hasAnomalies,
      hasConnectors: this.hasConnectors,
      isSmallMarket: this.isSmallMarket,
    };
  }

  static fromJSON(data: RadioData) {
    return new Radio(
      data.callSign,
      data.name,
      data.logo,
      data.region,
      Geolocation.parse(data.geolocation),
      moment(data.createdAt, 'YYYY-MM-DD'),
      data.featureEnabled ? data.featureEnabled : [],
      data.configurationStep,
      data.configurationProgress,
      data.hasAnomalies,
      data.hasConnectors,
      data.isSmallMarket,
    );
  }
}
