/** @flow */
import moment from 'moment';
import infoPromo  from  "../modules/adssellerradio/domain/InfoPromo"
import InfoTax from "../modules/adssellerradio/domain/InfoTax"
import  AdsSellerRadio  from  "../modules/adssellerradio/domain/AdsSellerRadio"
import i18n from "i18n/i18n";

export type genderType = 
{
  men: true,
  women: true,
}

export type stationType = ['CHAA-FM','CHAA-HD']

export class CampaignAudioFile {
   DefFile:File;
   PathTemp:string;

   constructor(DefFile: File) {
      this.DefFile=DefFile;
      this.PathTemp= URL.createObjectURL(DefFile);      
  }
  flush(): void  {
    URL.revokeObjectURL(this.PathTemp);
  }
}

var CPM_All =   {age18Plus:14.77, age1834:73.97, age2554:27.84, age3564:23.89, age35Plus:18.46}  
var CPM_Men =   {age18Plus:30.65, age1834:153.41,  age2554:57.69,  age3564:49.63, age35Plus:38.30}
var CPM_Women = {age18Plus:28.51, age1834:42.86, age2554:53.78, age3564:46.08, age35Plus:35.62}
var CostBySpot = 27;
var CostProduction = 200;
var infoPromoDef:infoPromo=undefined;

export default class CampaignData {
  name: string;
  contactName: string;
  phoneContact: string;
  phoneExtensionContact: string;
  advertiserName: string;
  objective:string;
  ages:string;
  genders:genderType;
  stations:string;
  nbImpression:number;
  montant:number;
  fromDate:Date;  
  toDate:Date;
  audioFileName:string;
  spotDuration:number;
  spotVoiceL:String;  
  spotSoundTrack:String;
  spotText:String;
  montProdMessage:number;
  Total:number;
  promoCode: string;
  IndUpdate:boolean;
  expiration: moment;
  PaiementDone:Boolean;
  CampaignSave:Boolean;
  TableTax:InfoTax[]=undefined;
  reference: string;

  constructor(
    name: string,
    expiration: moment
  ) {
    this.name = name;
    this.contactName = "";
    this.phoneContact="";
    this.phoneExtensionContact="";
    this.advertiserName="";
    this.objective = "N";
    this.age='age18Plus';
    this.genders = {men: true, women: true,};
    this.stations=  "";
    this.fromDate=new Date();
    this.fromDate.setDate(this.fromDate.getDate() +6);
    this.fromDate.setHours(0, 0, 0);
    this.toDate=new Date();
    this.toDate.setDate(this.toDate.getDate() +20);
    this.toDate.setHours(0, 0, 0);
    this.nbImpression=0;
    this.montant=500;
    this.montProdMessage=CostProduction;
    this.audioFileName="";
    this.spotDuration=30;
    this.spotVoice="W";  
    this.spotSoundTrack="Rhythm";
    this.spotText='';
    this.Total=0;
    this.TableTax=[{}];
    this.promoCode="";
    this.IndUpdate=false;
    this.newState=true;
    this.PaiementDone=false;
    this.CampaignSave=false;
    this.expiration = expiration;
    this.GetNbImpression();
    this.reference="";
    }

  setInfoSeller(param:AdsSellerRadio): AdsSellerRadio {
    CostBySpot = param.costBySpot;
    CostProduction = param.costProd;

    CPM_All = param.infoCPMs[0];
    CPM_Men = param.infoCPMs[1];
    CPM_Women = param.infoCPMs[2];

    this.TableTax=param.infoTaxTable;
    this.montProdMessage=CostProduction;
    
    return param;
  }  

  setInfoPromo(param:infoPromo): void {
    infoPromoDef=param;
  }  

  setStep01(name: string, objective:string): void {
    this.name = name;
    this.objective=objective;
    this.IndUpdate=false;
  }

  setStep02(age: string, genders:genderType): void {
    this.age = age;
    this.genders=genders;
    this.IndUpdate=false;
  }

  setStep03(stations: {} ): void {
    this.stations = stations;
    this.IndUpdate=false;
  }

  setStep04(nbImpression:number, montant:string, promoCode:string ): void {
    this.nbImpression=nbImpression;
    this.montant=parseInt(montant);
    this.promoCode=promoCode; 
    this.IndUpdate=false;
  }

  setStep05(fromDate:Date, toDate:Date ): void {
    this.fromDate=fromDate;
    this.toDate=toDate;
    this.IndUpdate=false;
  }

  setStep06(audioFileName:string, spotDuration:string, spotVoice:string, spotSoundtrack:string, spotText:string, 
            contactName:string, phoneContact:string, phoneExtensionContact:string ): void {
    this.audioFileName=audioFileName;
    this.spotDuration=parseInt(spotDuration);
    this.spotVoice=spotVoice;  
    this.spotSoundTrack=spotSoundtrack;
    this.spotText=spotText;
    this.contactName=contactName;
    this.phoneContact=phoneContact;
    this.phoneExtensionContact=phoneExtensionContact;
    this.IndUpdate=false;
  }

  getDateCommand():string {
    var DateJour:Date=new Date();
    var libel:string=DateJour.toLocaleDateString();
    return libel;
  }
  
  getDescPayPal(callSign:string):string {
    var libel:string;
    libel=callSign+"-"+this.advertiserName+"-"+this.name;
    return libel.substring(0,126);
  }

  getMessagePromo():String {
    var libel:string="";
    if (infoPromoDef !== undefined){
      libel=infoPromoDef.getMessRabais();
    }
    return libel;
  }

  getDescription():string {

   var TextDesc:string;

   TextDesc=i18n.t('step07Page.libOrderedOn')+" "+ this.getDateCommand() + "\n";
   TextDesc=TextDesc+ i18n.t('step07Page.libCampaignName')+" " + this.name + "\n";
   TextDesc=TextDesc+ i18n.t('step07Page.libObjective')+" "+ this.GetObjectiveLibel() + "\n";
   TextDesc=TextDesc+ i18n.t('step07Page.libAdvertiser')+" "+this.advertiserName + "\n";
   TextDesc=TextDesc+ i18n.t('step07Page.libContact')+" "+this.contactName + "\n";
   TextDesc=TextDesc+ i18n.t('step07Page.libPhone')+" "+this.phoneContact + "\n";
   TextDesc=TextDesc+ i18n.t('step07Page.libPhoneExt')+" "+this.phoneExtensionContact + "\n";
   TextDesc=TextDesc+ i18n.t('step07Page.libDemogr')+" "+this.GetGendersLibel() + "\n";
   TextDesc=TextDesc+ i18n.t('step07Page.libAgeGroup')+" "+ this.GetAgesGroupLibel() + "\n";
   TextDesc=TextDesc+ i18n.t('step07Page.libCallSign')+" "+ this.GetStationsLibel() + "\n";
   TextDesc=TextDesc+ i18n.t('step07Page.libImpressionCount')+" "+ this.nbImpression + "\n";
   TextDesc=TextDesc+ i18n.t('step07Page.libOccassCount')+" "+ this.GetNbSpot() + "\n";
   TextDesc=TextDesc+ i18n.t('step07Page.libPromoCode')+" "+ this.promoCode + "\n";
   TextDesc=TextDesc+ i18n.t('step07Page.libPromoMessage')+" "+ this.getMessagePromo() + "\n";
   TextDesc=TextDesc+ i18n.t('step07Page.libInvest')+" "+ this.GetMontantLibel() + "\n";
   TextDesc=TextDesc+ i18n.t('step07Page.libProductMess')+" "+ this.GetMontantProdMessLibel() + "\n";
   TextDesc=TextDesc+ i18n.t('step07Page.libStartCampaign')+" "+ this.GetFromDate() + "\n";
   TextDesc=TextDesc+ i18n.t('step07Page.libEndCampaign')+" "+ this.GetToDate() + "\n";

   if (this.getFilePresent() === true) {
      TextDesc=TextDesc+ i18n.t('step07Page.libAudioFile')+" "+ this.audioFileName + "\n";
   }                
   else {
      TextDesc=TextDesc+ i18n.t('step07Page.libDuration')+" "+ this.spotDuration  + " " + i18n.t('common.libSeconds') + "\n";
      TextDesc=TextDesc+ i18n.t('step07Page.libVoice')+" "+ this.GetSpotVoiceLibel() + "\n";
      TextDesc=TextDesc+ i18n.t('step07Page.libSoundYtack')+" "+ this.GetSpotSoundTrackLibel() + "\n";
   }
   return TextDesc;
  }

  getMontant():string {
    var listMontant = [500, 1000, 1500, 2000, 3000, 4000];
    if ( listMontant.includes(this.montant) ) {
      return this.montant.toString();
    }
    else {
      return "other";
    }
  }

  getFilePresent():boolean{
    if ( this.audioFileName === ""){
      return false;
    }
    else {
      return true;
    }
  }

  GetSpotVoiceLibel():string {
    var libel:string="";
    if (this.spotVoice === "W") 
    {libel=i18n.t('common.libWoman')}  
    else if (this.spotVoice === "M") 
         {libel=i18n.t('common.libMan')}  
         else  
         {libel=i18n.t('common.libNoPref')}  

    return libel;
  }    
 
  GetSpotSoundTrackLibel():string {
    var libel:string="";
    if (this.spotSoundTrack === "VeryRhythm") {libel=i18n.t('common.libVeryRhythm')}
    if (this.spotSoundTrack === "Rhythm") {libel=i18n.t('common.libRhythm')}
    if (this.spotSoundTrack === "Soft") {libel=i18n.t('common.libSoft')}
    if (this.spotSoundTrack === "Rock") {libel=i18n.t('common.libRock')}
    if (this.spotSoundTrack === "Pop") {libel=i18n.t('common.libPop')}
    if (this.spotSoundTrack === "None") {libel=i18n.t('common.libNoPref')}  
    return libel;
  }    

  GetFromDate():string {
    var libel:string=this.fromDate.toLocaleDateString();
    return libel;
  }      

  GetToDate():string {
    var libel:string=this.toDate.toLocaleDateString();
    return libel;
  }      

  GetMontantLibel():string {
    return this.montant.toLocaleString(this.GetLanguage(), {style:"currency", currency:"CAD"});
  }      

  GetMontantProdMessLibel():string {
    var MontProd:number;
    if (!this.getFilePresent()){
      MontProd=this.montProdMessage;
    }
    else{
      MontProd=0;
    }
    return MontProd.toLocaleString(this.GetLanguage(), {style:"currency", currency:"CAD"});
  }      

  GetStationsLibel():string {
    var libel:string=this.stations;

    if (!libel) {libel=this.callSign}
    return libel;
  }      

  GetAgesGroupLibel():string {
    var libel:string="";

    libel=i18n.t('common.lib'+this.age);

    return libel;
  }      

  GetCPM():number {
    var tab;

    if (this.genders.men && this.genders.women){
      tab=CPM_All;
    } 
    else {
        if (this.genders.men){
          tab=CPM_Men;
        } 
        else {
          tab=CPM_Women;
        }
    }
    return tab[this.age];
  }      

  GetLanguage():string {
    if (i18n.language === 'fr')  {
      return "fr-CA"
    }
    else {
      return "en-CA"
    }
  }

  GetCPMLibel():string {
    return this.GetCPM().toLocaleString(this.GetLanguage(), {style:"currency", currency:"CAD"});
  } 

  GetNbImpressionLibel():string {
    return this.nbImpression.toLocaleString();
  }      

  GetNbImpression():number{
    this.nbImpression=Math.round((this.montant/ this.GetCPM()) * 1000);

    if (infoPromoDef !== undefined){
      if (this.promoCode.toLocaleUpperCase() === infoPromoDef.code  && infoPromoDef.particEffect === "E-MUL")
      { this.nbImpression = this.nbImpression * 2 }
    }
 
    return this.nbImpression;
  }

  GetNbImpressionFormat(nbImpress):string{
    return nbImpress.toLocaleString();
  }

  GetNbImpressionForMount(mount:number,promCode:string):number{
    var NbImpress:number=Math.round((mount/ this.GetCPM()) * 1000);

    if (infoPromoDef !== undefined){
      if (promCode.toLocaleUpperCase() === infoPromoDef.code && infoPromoDef.particEffect === "E-MUL")
      { NbImpress=NbImpress + Math.round((NbImpress * (infoPromoDef.multiImpress/100)))}
    }

    return NbImpress;
  }

  GetNbSpot():number{
     var NbSpot:number=Math.round(this.montant / CostBySpot);
     this.montProdMessage=CostProduction;

     if (infoPromoDef !== undefined){
       if (this.promoCode.toLocaleUpperCase() === infoPromoDef.code && infoPromoDef.particEffect === "E-MUL")
       { NbSpot=NbSpot +  Math.round((NbSpot * (infoPromoDef.multiSpot/100)))}
      }
 
     return NbSpot ;
  }

  GetGendersLibel():string {
    var libel:string="";
    if (this.genders.women) {libel=i18n.t('common.libWomen')+" "}   
    if (this.genders.men) {libel=libel+i18n.t('common.libMen')}  
    return libel;
  }      

  GetObjectiveLibel():string {
    var libel:string="";

    if (this.objective === "N")
    { libel=i18n.t('step01Page.radChoiceNotoriety');}  
    else
    {libel=i18n.t('step01Page.radChoiceCallAction');}  

    return libel;
  }      

  GetTaxeLibel():[] {
    var libel:string="";
    var Tableau=[];

    for ( let I=0; I<this.TableTax.length; I++)
    {
      libel=this.TableTax[I].codeTax+": "+this.TableTax[I].MontTaxe.toLocaleString(this.GetLanguage(), {style:"currency", currency:"CAD"});
      Tableau.push(libel);
    }    
    return Tableau;
  }      

  GetTotalLibel():string {
    return this.Total.toLocaleString(this.GetLanguage(), {style:"currency", currency:"CAD"});
  }      

  GetRabais():number {
    var Rabais:number=0;
    if (infoPromoDef !== undefined){
      if (this.promoCode.toLocaleUpperCase() === infoPromoDef.code && infoPromoDef.particEffect === "E-POURC")
      { Rabais=Number(parseFloat(this.montant * (infoPromoDef.discountPercentage/100)).toFixed(2));  }
     }
    return Rabais; 
  }

  GetRabaisLibel():string {
    return this.GetRabais().toLocaleString(this.GetLanguage(), {style:"currency", currency:"CAD"});
  }      

  Calcul():void {
    var Mont=this.montant;
    var Rabais=this.GetRabais();
    var MontTaxe=0.0;

    this.GetNbImpression();

    if (!this.getFilePresent()){
      Mont=Mont+this.montProdMessage;
    }
    Mont=Mont-Rabais;

    for ( let I=0; I<this.TableTax.length; I++)
    {
      this.TableTax[I].MontTaxe=Number(parseFloat(Mont * (this.TableTax[I].taxPercent/100)).toFixed(2));
      MontTaxe=MontTaxe+this.TableTax[I].MontTaxe;
    }

    this.Total=Number(parseFloat(Mont+MontTaxe).toFixed(2));
  }      

  isExpired() {
    return moment().isAfter(this.expiration);
  }
  static toJson = (token: CampaignData): string => {
      return JSON.stringify(token);
    };

  static fromJson = (json: ?string): CampaignData => {
      if (!json) {
        return undefined;
      }
  
      const converter = (key, value) => {
        if (key === 'expiration') {
          return moment(value);
        }
        if (key === 'fromDate') {
          return new Date(value);
        }
        if (key === 'toDate') {
          return new Date(value);
        }

        return value;
      };
  
      var data  = JSON.parse(json, converter);
      var Campaign= new CampaignData(data.name, data.expiration);
      Campaign.contactName= data.contactName;
      Campaign.advertiserName= data.advertiserName;
      Campaign.phoneContact= data.phoneContact;
      Campaign.phoneExtensionContact= data.phoneExtensionContact;
      Campaign.objective = data.objective;
      Campaign.agesGroup = data.agesGroup;
      Campaign.genders = data.genders;
      Campaign.stations=  data.stations;
      Campaign.fromDate=data.fromDate;
      Campaign.toDate=data.toDate;
      Campaign.nbImpression=data.nbImpression;
      Campaign.montant=data.montant;
      Campaign.audioFileName=data.audioFileName;
      Campaign.spotDuration=data.spotDuration;
      Campaign.spotVoice=data.spotVoice;
      Campaign.spotSoundTrack=data.spotSoundTrack;
      Campaign.spotText=data.spotText;
      Campaign.TableTax=data.TableTax;
      Campaign.montProdMessage=data.montProdMessage;
      Campaign.Total=data.Total;
      Campaign.promoCode=data.promoCode; 
      Campaign.CampaignSave=data.CampaignSave;
      Campaign.reference=data.reference;
  
      return Campaign 
    }

}
