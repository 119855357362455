const translations = {
  fr: {
    general: {
      commonRegistration: {
        libCreateCampaign1: 'Créez votre campagne',
        libCreateCampaign2: 'publicitaire',
        libCreateCampaign3: 'en 7 étapes faciles.',
        libWelcome: 'Bienvenue!',
        libCreateAccount: 'Créer votre compte',
        libStatsRadio: 'StatsRadio.', 
        libTrademark: 'Tous droits réservés.',
        libForgotPwTitle: "Mot de passe oublié - Entrez votre adresse de courriel",
        libConfirmEmail: "Follo-Confirmer votre adresse de courriel",
        libUserProfilTitle: "Profil de l'utilisateur",
        ediUserName: 'Nom d’utilisateur',
        ediPassWord: 'Mot de passe',
        ediEnterprise:'Entreprise',
        ediFirstName: 'Prénom',  
        ediSurname: 'Nom de famille',
        ediEmail: 'Courriel', 
        ediConfPassWord: 'Confirmation du mot de passe',
        lisFrench: 'Français', 
        lisEnglish: 'Anglais', 
        butConnTitle: 'Se connecter',
        butForgotPwTitle: 'Mot de passe oublié',
        butCreateAccount: 'Créer votre compte', 
        butAbend: 'Annuler',
        butValid: 'Valider',
        butUpdate: 'Mise à jour',
        tabConnName: 'Connexion',
        tabCreateName: 'Créer un compte',
        messEnterEmail: 'Veuiller saisir votre adresse courriel',
        messEmailValid: "L'adresse de courriel doit être valide",
        messEnterPassword: 'Veuillez saisir votre mot de passe',
        messInvalidAccount: "Le mot de passe est invalide ou le compte n'existe pas.",
        messSendInitPw: "Le courriel de réinitialisation de mot de passe a été envoyer. Veuillez vérifier votre boîte de courriel.",
        messEnterEnterprise: "Veuillez saisir le nom d'entreprise",
        messEnterFirstName: 'Veuillez saisir votre prénom',
        messEnterSurname: 'Veuillez saisir votre nom de famille',
        messValidEmail: "L'adresse de courriel doit être valide",
        messConfPassword: 'Veuillez confirmer votre mot de passe',
        messCreateAccountErr: "Le compte existe déjà pour ce courriel ou il y a une erreur d'accès.",
        messUpdProfilAccesErr: "Un erreur d'accès lors de la mise à jour du profile.", 
        messEmailAlreadyUseErr : "Cette adresse de courriel est déjà utilisée.",
        messPasswordChangeErr: "Erreur au changement de mot de passe. C'est le même mot de passe que le précédent ou ce nouveau n'est pas assez sécuritaire.",
        messProfileSaveCorrectly: "Le profile est enregistré correctement.",
        messConfirmEmail:"Veuillez confirmer votre adresse courriel",
        messVisuTotu: "C'est simple d'acheter de la pub en ligne!",
        messVisuTotu2: "Regardez notre tutoriel ici.",
        adrLinkTuto: "https://youtu.be/HoK2Mnrg7Ek",
      }, 
      header: {
        libTitle: 'Achat de publicité en ligne',
        libProfil: 'Profile',
        mnuSignOut: 'Se déconnecter',
      },  
      sidebar:{
        libDevCampTitle: 'ÉLABORER CAMPAGNE',
        libStep01Title: 'Étape 1 - Pourquoi?',
        libStep02Title: 'Étape 2 - Qui?',
        libStep03Title: 'Étape 3 - Où?',
        libStep04Title: 'Étape 4 - Combien?',
        libStep05Title: 'Étape 5 - Quand?',
        libStep06Title: 'Étape 6 - Quoi?',
        libStep07Title: 'Étape 7 - Payer',
        libMonitorCampTitle: 'SUIVI CAMPAGNE',
        libFollo: 'Follo',   
        libTuto: 'Tutoriels',   
        libPowerBy: 'Propulsé par',   
      },
      errorPage:{
        libAdFolloTitle: 'Achat de publicité en ligne',
        messPageNotExist: "Oups. Il semble que la page que vous recherchez n'existe plus.",
        messReturnGoodPlace: "Mais nous sommes ici pour vous ramener au bon endroit.",
        butReturn: "Retour à l'accueil",
      },
      common:{
        libPayPalIdConf: "PayPal Id Confirmation: ",
        libBy: "Par: ",
        libMan: 'Homme',
        libWoman: 'Femme',
        libMen: 'Hommes',
        libWomen: 'Femmes',
        libNoPref: 'Pas de préférence',
        libVeryRhythm: "Très rythmé",
        libRhythm: "Rythmé",
        libSoft: "Douce",
        libRock: "Rock",
        libPop: "Pop",
        libage18Plus: "18 et +",
        libage1824: "18 à 24",
        libage1834: "18 à 34",
        libage2534: "25 à 34",
        libage2554: "25 à 54",        
        libage3544: "35 à 44",
        libage3564: "35 à 64",
        libage35Plus: "35 et +",
        libage4554: "45 à 54", 
        libage5564: "55 à 64",
        libage65Plus: "65 et +",
        libSeconds: 'secondes',
        messPromoPercent: "Rabais de {{val}}% sur les impressions.",  
        messPromoMulti: "Bonification de {{val}}% sur les impressions.",
        messInternalErrorUpdCampaign:"Erreur technique lors de la mise à jour de la campagne. Utiliser le lien 'SIGNALER L'ERREUR' pour nous transmettre les informations sur cette erreur",
        messInternalErrorSaveCampaign:"Erreur technique lors de la sauvegarde de la campagne. Utiliser le lien 'SIGNALER L'ERREUR' pour nous transmettre les informations sur cette erreur",
      },
      commonStepPages:{
        butNew: 'Nouveau',
        butNext: 'Suivant',
        butPrev: 'Précédent',
        butYes: 'Oui',
        butNo: 'Non',
        butNewCampaign: 'Créer une nouvelle campagne',
        butSaveConfirm: 'Confirmer et sauvegarder la campagne',
        butCancelCampaign: 'Annuler cette campagne',
        libFolloTitle: "Follo-Achat de publicité en ligne",
        libFieldRequired: '* Champ obligatoire',
        messConfirmAnnulCamp: "Êtes-vous certain de vouloir annuler l'élaboration de la campagne courante?"
      },
      step01Page:{
        libEnterInfoStepTitle: "Veuillez indiquer le nom et l'objectif de votre campagne.", 
        ediCampaignName: 'Nom de la campagne',
        radObjective: 'Objectifs',
        radChoiceNotoriety: 'Notoriété',
        radChoiceCallAction: 'Appel à l’action',
        messEnterCampaignName: "Veuillez saisir le nom de la campagne",
        messSelectObjective: "Vous devez sélectionner au moins un choix pour l'objectifs de la campagne.",
        messChoiceNotoriety: "Une campagne dont les impressions sont diffusées sur une plus longue période avec une fréquence moindre afin d’augmenter la notoriété spontanée chez la cible.",
        messChoiceCallAction: "Une campagne concentrée sur quelques jours dont la fréquence est élevée afin de faire passer à l’action une clientèle potentielle.",
      },
      step02Page:{
        libEnterInfoStepTitle: "Veuillez indiquer qui vous voulez atteindre.", 
        libSelectTips01: 'Pour le maximum d’impressions,',
        libSelectTips02: 'cochez 18 et plus',
        libSelectTips03: 'ainsi qu’hommes et femmes.',
        libDemogrDataTitle: 'Données démographiques ',
        libDemogrTitle: 'Démographie',       
        libAgeRangeTitle: "Tranche d'âge",
        radSelectAgeTitle: "Sélectionner la tranche d'âge souhaitée *",
        radSelectAgeChoice01: '18 et plus',
        radSelectAgeChoice02: '18-34',
        radSelectAgeChoice03: '25-54',
        radSelectAgeChoice04: '35-64',
        radSelectAgeChoice05: '35 et plus',
        chkSelectDemogrTitle: 'Sélectionner la démographie voulue *',
        chkSelectDemogrChoice01: 'Hommes',
        chkSelectDemogrChoice02: 'Femmes',
        messSelectAge01: 'Vous devez sélectionner au moins un choix',
        messSelectAge02: "pour la tranche d'âge.",
        messSelectDemogr01: 'Vous devez sélectionner',
        messSelectDemogr02: "au moins un choix pour la démographie.",
      },
      step03Page:{
        libEnterInfoStepTitle: "Il est temps de nous dire où vous voulez être entendu.",
        libZoneGeo: "Zone géographique de couverture",
        libCity: 'Villes:',
      },
      step04Page:{
        libEnterInfoStepTitle: "Il est temps de nous dire combien vous voulez investir pour atteindre votre objectif.", 
        libImpression: 'Impressions:',
        libImpressionDef: "Est le nombre d’expositions au message publicitaire, c’est-à-dire le nombre de fois que votre message sera entendu.",
        ediCustomAmount: "Montant personnalisé (minimum: 500$)",
        ediPromoCode:"Code promotionnel",
        radInvesTitle: 'Investissement',
        radInvesChoices01: "500 $",
        radInvesChoices02: "1000 $",
        radInvesChoices03: "1500 $",
        radInvesChoices04: "2000 $",
        radInvesChoices05: "3000 $",
        radInvesChoices06: "4000 $",
        radInvesChoices07: "Montant personnalisé",
        messAmountValid: 'Le montant doit être égale ou supérieur à 500$',
        messPromoCodeInvalid: 'Code promotionnel invalide',
        messPromoCodeInvalidSince: ' depuis ',
      },
      step05Page:{
        libEnterInfoStepTitle: "Il est temps de nous dire quand vous voulez être entendu." , 
        ediStartDate: "Début de la campagne *",
        ediEndDate: "Fin de la campagne *",
        messDelayBeforeStart: "Un délai minium de 5 jours est nécessaire avant le début de la campagne",
        messEndDateAfter: "La date de fin doit arrivée après la date de début",
        messModifyCampaignDates:"Veuillez modifier les dates de la campagne.",
      },
      step06Page:{
        libEnterInfoStepTitle: "Il est temps de nous dire ce que vous souhaitez annoncer.", 
        libFileLoaded: 'Fichier courant chargé',
        libBytes: 'octets',
        libRejectFile: 'Fichiers rejetés',
        libContactTitle: "Si vous voulez être contacté par téléphone pour l’approbation, veuillez inscrire votre numéro ici :",
        ediContactName: "Nom du contact",
        ediPhone: "Téléphone",
        ediPhoneExt: "Poste",
        butChangeFile: 'Changer de fichier',
        radDurationTitle: 'Choisir la durée:',
        radDurationChoice01: '15 secondes',
        radDurationChoice02: '30 secondes',
        radVoiceTitle: 'Choisir la voix:',        
        radVoiceChoice01: "Voix femme",        
        radVoiceChoice02: "Voix homme",        
        radVoiceChoice03: "Pas de préférence",     
        radSoundtrackTypeTitle: 'Choisir la trame sonore:',
        radSoundtrackTypeChoice01: "Très rythmée",
        radSoundtrackTypeChoice02: "Rythmée",
        radSoundtrackTypeChoice03: "Douce",
        radSoundtrackTypeChoice04: "Pas de préférence",
        messQualityRespect: "Votre message doit respecter les règles de qualité tant niveau du contenu que de la production. Celui-ci sera soumis à la station pour approbation avant la diffusion.",
        messProdAnnounce: "Si vous n'avez pas de fichier audio, nous produirons votre message pour des frais de {{val}}$ et un délai supplémentaire peut être requis avant la diffusion. Vous pourrez insérer votre texte déjà rédigé",
        messProdAnnounce2: " ou simplement écrire les informations à diffuser en points de forme, et notre service de rédaction composera un message pour vous.",
        messProdAnnounce3: "  La station se réserve le droit de refuser si elle juge que le contenu ne cadre pas avec son auditoire.",
        messDurationQuest: 'Avez-vous dèjà votre message audio de 15 ou 30 secondes?',
        messTextLength:'Texte du message ({{val}} caractères maximum)',
        messSelectFile: 'Veuillez sélectionner un fichier pour la campagne.',
        messReloadFile: "Vous avez choisi le fichier {{val}} mais vous devez recharger celui-ci ou un autre.",
        messTooMuchFile: "Trop de fichier - sélectionner un seul fichier",
        messTypeFileInvalid: "Mauvais type de fichier - Seulement les fichiers audio *.mp3 et *.wav seront acceptés",
        messFileTooBig: 'Ce fichier est trop grand. Taille maximale: {{val}} octets', 
        messUploadFile:"Pour téléverser le fichier de votre message, vous pouvez le glisser-déplacer (Drag 'n' drop) ici OU cliquer pour le sélectionner.",
        messTypeFile:"(Seulement les fichiers audio *.mp3 et *.wav seront acceptés.)",
        messEnterSpotText:'Veuillez saisir le texte du message de la campagne.',
      },
      step07Page:{
        libEnterInfoStepTitle: "Voici le résumé de votre campagne.", 
        libPayYourCampaign: "Cliquez sur le bouton Paypal et payez afin de vous assurer d’être en ondes à la date demandée.",
        libThankForPayYourCampaign: "Merci pour le paiement de votre campagne.",
        libCancelCampaign: "L'élaboration de votre campagne est annulée.",  
        libCampaignName: 'Nom de la campagne:',
        libOrderedOn: 'Commandée le:',
        libAdvertiser: 'Annonceur:',
        libContact: 'Contact:',
        libPhone: 'Téléphone:',
        libPhoneExt: 'Poste:',
        libObjective: 'Ojectifs:',
        libDemogr: 'Démographie:',
        libAgeGroup: "Groupe d'âge:",
        libCallSign: 'Station:',
        libImpressionCount: "Nombre d'impressions:",
        libOccassCount: "Nombre d'occasions:",
        libPromoCode: 'Code promo:',
        libPromoMessage: 'Message promo:',
        libInvest: 'Investissement:',
        libProductMess: 'Production du message:',
        libStartCampaign: 'Début de la campagne:',
        libEndCampaign: 'Fin de la campagne:',
        libAudioFile: 'Fichier audio:',
        libDuration: 'Durée:',
        libVoice: 'Voix:',
        libSoundYtack: 'Trame sonore:',
        libText: 'Texte:',
        libAmountToPay: 'À payer:',
        libProdMessAmountToPay: 'Prod message:',
        libDiscountToPay: 'Rabais:',
        libTotalToPay: 'Total:',
        libCampaignSave: 'Campagne sauvegardée',
        libCampaignCancel: 'Campagne cancellée',
        libNomPayeurCampaignSave: 'Nom pas disponible',
        messLinkMailError: "SIGNALER L'ERREUR. Veuillez cliquer ici pour nous transmettre les informations de l'erreur qui vient de se produire.",
        messSubjectMailError: "AdsFollo-Erreur interne à la sauvegarde de la campagne.",
        messBodyMailError: "Voici l'information de la campagne lors de l'erreur: ",
        messReceiveEmail: 'Vous recevrez votre reçu par courriel.',
        messDelayReceiveYourSpot01:'Votre message sera approuvé dans les 72h.',
        messDelayReceiveYourSpot02: "Votre message vous sera envoyé dans un délai de 72 heures pour approbation.",
        messActivFollo:"Vous pouvez activer le suivi de la campagne sur Follo qui vous permettra de suivre la performance de votre publicité.",
      },
   
    },
  },
  en: {
    general: {
      commonRegistration: {
        libCreateCampaign1: 'Create your',
        libCreateCampaign2: 'advertising campaign',
        libCreateCampaign3: 'in 7 easy steps.',
        libWelcome: 'Welcome!',
        libCreateAccount: 'Create your account',   
        libStatsRadio: 'StatsRadio.', 
        libTrademark: 'All rights reserved.', 
        libForgotPwTitle:"Forgot Password - Enter your email address",
        libConfirmEmail: "Follo-Confirm your email address",
        libUserProfilTitle: "User profile",
        ediUserName: 'User name',
        ediPassWord: 'Password',
        ediEnterprise:'Company',
        ediFirstName: 'First name',  
        ediSurname:'Surname',
        ediEmail: 'E-mail',
        ediConfPassWord: 'Password Confirmation', 
        lisFrench: 'French', 
        lisEnglish: 'English', 
        butAbend: 'Cancel',
        butValid: 'Validate',
        butConnTitle: 'Sign in',
        butForgotPwTitle: 'Forgot your password',
        butCreateAccount:'Create your account', 
        butUpdate: 'Update',
        tabConnName: 'Login',
        tabCreateName: 'Create Account',
        messEnterEmail: 'Please enter your email address',
        messEmailValid: "The email address must be valid",
        messEnterPassword: 'Please enter your password',
        messInvalidAccount: "The password is invalid or the account does not exist.",
        messSendInitPw: "Password reset email has been sent. Please check your email.",
        messEnterEnterprise: "Please enter the company name",
        messEnterFirstName: 'Please enter your first name',
        messEnterSurname: 'Please enter your last name',
        messValidEmail: "The email address must be valid",
        messConfPassword: 'Please confirm your password',
        messCreateAccountErr: "The account already exists for this email or there is an access error.",
        messUpdProfilAccesErr: "An access error during profile update.", 
        messEmailAlreadyUseErr : "This email address is already in use.",
        messPasswordChangeErr: "Error when changing password. It is the same password as the previous one or this new one is not secure enough.",
        messProfileSaveCorrectly: "The profile is saved successfully.",
        messConfirmEmail:"Please confirm your email address",
        messVisuTotu: "It's easy to buy advertising online! ",
        messVisuTotu2: "Watch our tutorial here.",
        adrLinkTuto: "https://www.youtube.com/watch?v=1n--6f5yvQo",
      },
      header: {
        libTitle: 'Online Advertising Purchase',
        libProfil: 'Profil',
        mnuSignOut: 'Sign out',
      },  
      sidebar:{
        libDevCampTitle: 'DEVELOP CAMPAIGN',
        libStep01Title: 'Step 1 - Why?',
        libStep02Title: 'Step 2 - Who?',
        libStep03Title: 'Step 3 - Where?',
        libStep04Title: 'Step 4 - How Much?',
        libStep05Title: 'Step 5 - When?',
        libStep06Title: 'Step 6 - What?',
        libStep07Title: 'Step 7 - Pay',        
        libMonitorCampTitle: 'CAMPAIGN FOLLOW-UP',
        libFollo: 'Follo',   
        libTuto: 'Tutorials',   
        libPowerBy: 'Powered by',   
      },
      errorPage:{
        libAdFolloTitle: 'Online Advertising Purchase',
        messPageNotExist: "Oops. It looks like the page you are looking for no longer exists.",
        messReturnGoodPlace: "But we are here to bring you back to the right place.",
        butReturn: "Back home",
      },
      common:{
        libPayPalIdConf: "PayPal Id Confirmation: ",
        libBy: "By: ",
        libMan: 'Man',
        libWoman: 'Woman',
        libMen: 'Men',
        libWomen: 'Women',
        libNoPref: 'No preference',
        libVeryRhythm: "Very rhythmic",
        libRhythm: "Rhythm",
        libSoft: "Soft",
        libRock: "Rock",
        libPop: "Pop",
        libage18Plus: "18 and +",
        libage1824: "18 to 24",
        libage1834: "18 to 34",
        libage2534: "25 to 34",
        libage2554: "25 to 54",
        libage3544: "35 to 44",
        libage3564: "35 to 64",
        libage35Plus: "35 and +",
        libage4554: "45 to 54",
        libage5564: "55 to 64",
        libage65Plus: "65 and +",        
        libSeconds: 'seconds',
        messPromoPercent: "{{val}}% discount on impressions.",  
        messPromoMulti: "{{val}}% bonus on impressions.",
        messInternalErrorUpdCampaign:"Technical error while updating the campaign. Use the 'REPORT THE ERROR' link to send us information about this error.",
        messInternalErrorSaveCampaign:"Technical error while saving the campaign. Use the 'REPORT THE ERROR' link to send us information about this error",
      },
      commonStepPages:{
        butNew: 'New',
        butNext: 'Next',
        butPrev: 'Previous',
        butYes: 'Yes',
        butNo: 'No',
        butNewCampaign: 'Create New Campaign',
        butSaveConfirm: 'Confirm and save the campaign',
        butCancelCampaign: 'Cancel this campaign',
        libFolloTitle: "Follo-Online Advertising Purchase",
        libFieldRequired: '* Required Field',
        messConfirmAnnulCamp: "Are you sure you want to cancel editing the current campaign?"
      },
      step01Page:{
        libEnterInfoStepTitle: "Please provide the name and objective of your campaign.", 
        ediCampaignName: 'Campaign name',
        radObjective: 'Objectives',
        radChoiceNotoriety:'Branding',
        radChoiceCallAction: 'Call to action',
        messEnterCampaignName: "Please enter the campaign name",
        messSelectObjective: "You must select at least one choice for the campaign objective.",
        messChoiceNotoriety: "A campaign whose impressions are broadcast over a longer period with less frequency in order to increase spontaneous branding among the target.",
        messChoiceCallAction: "A campaign concentrated on a few days with a high frequency in order to get potential customers to take action.",
      },
      step02Page:{
        libEnterInfoStepTitle: "Please indicate who you want to reach.", 
        libSelectTips01: 'For maximum impressions,',
        libSelectTips02: 'check 18 and over',
        libSelectTips03: 'as well as men and women.',
        libDemogrDataTitle: 'Demographic data for ',        
        libDemogrTitle: 'Demography',
        libAgeRangeTitle: "Age range",
        radSelectAgeTitle: "Select the desired age group *",
        radSelectAgeChoice01: '18 and over',
        radSelectAgeChoice02: '18-34',
        radSelectAgeChoice03: '25-54',
        radSelectAgeChoice04: '35-64',
        radSelectAgeChoice05: '35 and over',
        chkSelectDemogrTitle: 'Select the desired demography *',
        chkSelectDemogrChoice01: 'Men',
        chkSelectDemogrChoice02: 'Women',
        messSelectAge01: 'You must select at least one choice',  
        messSelectAge02: "for the age group.",
        messSelectDemogr01: 'You must select',
        messSelectDemogr02: "at least one choice for the demography.",
      },
      step03Page:{
        libEnterInfoStepTitle: "It's time to tell us where you want to be heard.", 
        libZoneGeo: "Geographic area of coverage",
        libCity: 'Cities:',
      },
      step04Page:{
        libEnterInfoStepTitle: "It's time to tell us how much you want to invest to reach your goal.", 
        libImpression: 'Impressions:',
        libImpressionDef: "The number of exposures to the advertising message, i.e. the number of times your message will be heard.",   
        ediCustomAmount: "Personalized amount (minimum: $500)",
        ediPromoCode:"Promo code",
        radInvesTitle: 'Investment',
        radInvesChoices01: "500 $",
        radInvesChoices02: "1000 $",
        radInvesChoices03: "1500 $",
        radInvesChoices04: "2000 $",
        radInvesChoices05: "3000 $",
        radInvesChoices06: "4000 $",
        radInvesChoices07: "Personalized amount",
        messAmountValid: 'The amount must be equal or greater than $500',
        messPromoCodeInvalid: 'Promo code invalid',
        messPromoCodeInvalidSince: ' since ',
      },
      step05Page:{
        libEnterInfoStepTitle: "It's time to tell us when you want to be heard." , 
        ediStartDate: "Start date *",
        ediEndDate: "End date *",
        messDelayBeforeStart: "A minimum delay of 5 days is required before the campaign starts",
        messEndDateAfter: "End date must be after start date",
        messModifyCampaignDates:"Please modify the campaign dates.",
      },
      step06Page:{
        libEnterInfoStepTitle: "It's time to tell us what you want to advertise.", 
        libFileLoaded: 'Current file loaded',
        libBytes: 'bytes',
        libRejectFile: 'Rejected files',
        libContactTitle: "If you would like to be contacted by phone for approval, please enter your number here:",
        ediContactName: "Contact name",
        ediPhone: "Phone number ",
        ediPhoneExt: "Ext",        
        butChangeFile: 'Change file',
        radDurationTitle: 'Select the duration:',
        radDurationChoice01: '15 seconds',
        radDurationChoice02: '30 seconds',
        radVoiceTitle: 'Select the voice:',
        radVoiceChoice01: "Female voice",
        radVoiceChoice02: "Male voice",
        radVoiceChoice03: "No preference",
        radSoundtrackTypeTitle: 'Select the soundtrack type:',
        radSoundtrackTypeChoice01: "Very upbeat",
        radSoundtrackTypeChoice02: "Upbeat",
        radSoundtrackTypeChoice03: "Soft",
        radSoundtrackTypeChoice04: "No preference",
        messQualityRespect: "Your message must respect the quality rules both in terms of content and production. This will be submitted to the station for approval prior to broadcast.",
        messProdAnnounce: "If you do not have an audio file, we will produce your message for a {{val}}$ fee and additional time may be required before broadcasting. You can insert your already written text ",
        messProdAnnounce2: " or simply write the information to be broadcasted in point form, and our writing service will compose a message for you.",
        messProdAnnounce3: " The station reserves the right to reject any content it considers inappropriate for its audience.",
        messDurationQuest: 'Do you already have your 15 or 30 second audio message?',
        messTextLength: 'Message text ({{val}} characters maximum)',
        messSelectFile: 'Please select a file for the campaign.',
        messReloadFile: "You have chosen the file {{val}} but you must reload this one or another.",
        messTooMuchFile: "Too many files - select only one file",
        messTypeFileInvalid: "Wrong file type - Only audio files *.mp3 and *.wav will be accepted",
        messFileTooBig: 'This file is too big. Maximum size: {{val}} bytes', 
        messUploadFile:"To upload your message file, you can drag and drop it here OR click to select it.",
        messTypeFile:"(Only *.mp3 and *.wav audio files will be accepted.)",
        messEnterSpotText:'Please enter the message text of the campaign.',        
      },
      step07Page:{
        libEnterInfoStepTitle: "Here is the summary of your campaign.",
        libPayYourCampaign: "Click on the Paypal button and pay to ensure the advertising starts on time.",
        libThankForPayYourCampaign: "Thank you for your campaign payment.",
        libCancelCampaign: "The creation of your campaign is cancelled.",  
        libCampaignName: 'Campaign Name:',
        libOrderedOn: 'Ordered on:',
        libAdvertiser: 'Advertiser:',
        libContact: 'Contact:',
        libPhone: 'Phone:',
        libPhoneExt:'Ext:',
        libObjective: 'Objectives:',
        libDemogr: 'Demography:',
        libAgeGroup: "Age Group:",
        libCallSign: 'Station:',
        libImpressionCount: "Number of impressions:",
        libOccassCount: "Number of occasions:",
        libPromoCode: 'PromoCode:',
        libPromoMessage: 'Promo message:',
        libInvest: 'Investment:',
        libProductMess: 'Message production:',
        libStartCampaign: 'Start of campaign:',
        libEndCampaign: 'End of campaign:',
        libAudioFile: 'Audio file:',
        libDuration: 'Duration:',
        libVoice: 'Voice:',
        libSoundYtack: 'Soundtrack:',
        libText: 'Text:',
        libAmountToPay: 'To pay:',
        libProdMessAmountToPay: 'Prod message:',
        libDiscountToPay: 'Discount:',
        libTotalToPay: 'Total:',
        libCampaignSave: 'Saved campaign',
        libCampaignCancel: 'Campaign canceled',
        libNomPayeurCampaignSave: 'Name not available',
        messLinkMailError: "REPORT THE ERROR. Please click here to send us information about the error that just occurred.",
        messSubjectMailError: "AdsFollo-Internal error when saving the campaign.",
        messBodyMailError: "Here is the campaign information at the time of the error:",
        messReceiveEmail: 'You will receive your receipt by email.',
        messDelayReceiveYourSpot01:'Your message will be approved within 72 hours.',
        messDelayReceiveYourSpot02: "Your message will be sent within 72 hours for approval.",
        messActivFollo:"You can activate campaign tracking on Follo which will allow you to track the performance of your advertising.",        
      },

    },
  },
};

export default translations;
