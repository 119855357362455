import InfoPromo from './InfoPromo';
import InfoCpm from './InfoCpm';
import InfoTax from './InfoTax';

class AdsSellerRadio {

    callSign: string;
    name: string;
    logo: string;
    supportMessage: string;
    imagAgeGroups: string;
    imagDemography: string;
    imagGeoAreaCover: string;
    infoCity: string;

    infoPromos: InfoPromo[];
    infoCPMs: InfoCpm[];
    infoTaxTable: InfoTax[];

    costBySpot:number;
    costProd:number;
    headerColor: string;
    supportEmail: string;

    constructor(data: { callSign: string, name: string, logoLogin: string, supportMessage: string, imagAgeGroups: string,
                        imagDemography: string, imagGeoAreaCover: string, infoCity: string, InfoPromos:Array, InfoCPMs:Array,
                        costBySpot:number,   costProd:number, headerColor: string, supportEmail: string, logo: string }) {

      var supportMessage;
      var TableInfoTax;
      var InfoEmailSupport;
                          
      this.callSign = data.callSign;
      this.name = data.name;
      this.logoLogin = data.logoLogin;
      this.supportMessage = data.supportMessage;
      this.imagAgeGroups = data.imagAgeGroups;
      this.imagDemography = data.imagDemography;
      this.imagGeoAreaCover = data.imagGeoAreaCover;
      this.infoCity = data.infoCity;
  
      this.infoPromos = data.InfoPromos.map(info => { return InfoPromo.parse(info)  });
      this.infoCPMs = data.InfoCPMs.map(info => { return InfoCpm.parse(info) });

      supportMessage=this.supportMessage.split("|");
      TableInfoTax=JSON.parse(supportMessage[2]);
      this.infoTaxTable = TableInfoTax.map(info => { return InfoTax.parse(info) }); 

      this.costBySpot = data.costBySpot;
      this.costProd = data.costProd;
      this.headerColor = data.headerColor;
      InfoEmailSupport=JSON.parse(data.supportEmail);
      this.supportEmail = InfoEmailSupport.support;
      this.logo = data.logo;
    }
  
    toObject(): Object {
      return {
        email: this.email,
        name: this.name,
        surname: this.surname,
        callSign: this.callSign,
        logoLogin: this.logoLogin,
        supportMessage: this.supportMessage,
        imagAgeGroups: this.imagAgeGroups,
        imagDemography: this.imagDemography,
        imagGeoAreaCover: this.imagGeoAreaCover,
        infoCity: this.infoCity,
    
        infoPromos: this.infoPromos,
        infoCPMs: this.InfoCPMs,
        infoTaxTable: this.infoTaxTable,
    
        costBySpot: this.costBySpot,
        costProd: this.costProd,
        headerColor: this.headerColor,
        supportEmail: this.supportEmail,
        logo: this.logo
      };
    }
    
    obtLogoLogon(lang:String):String{
      var codeLang = ""
      if (lang !== 'fr'){
        codeLang=lang;
      }
      return this.logoLogin.replace('{lang}',codeLang);
    }

    obtImagAgeGroups(lang:String):String{
      var codeLang = ""
      if (lang !== 'fr'){
        codeLang=lang;
      }
      return this.imagAgeGroups.replace('{lang}',codeLang);
    }

    obtImagDemography(lang:String):String{
      var codeLang = ""
      if (lang !== 'fr'){
        codeLang=lang;
      }
      return this.imagDemography.replace('{lang}',codeLang);
    }

    ObtInfoCity(lang:String):String{
      var tmp =""
      tmp=JSON.parse(this.infoCity)[lang];
      return tmp;
    }

    validCodeProm(codeProm:String):infoPromos {
       var result:infoPromos=undefined; 
       
       for(var i= 0; i < this.infoPromos.length; i++)
       {
          if (this.infoPromos[i].code.toUpperCase() === codeProm.toUpperCase()){
            result=this.infoPromos[i];
            return result;
          }
       }
      return result;
    }

    static vide(): AdsSellerRadio {
      return this.parse({
        callSign: "VIDE",
        email: "",
        name: "",
        surname: "",
        logoLogin: "",
        supportMessage: "||"+JSON.stringify([{ codeTax:"", taxPercent: 0 }]),
        imagAgeGroups: "",
        imagDemography: "",
        imagGeoAreaCover: "",
        infoCity: "",
    
        InfoPromos: [{}],
        InfoCPMs: [{}],
    
        costBySpot:"",
        costProd: "",
        headerColor: "",
        supportEmail: "",
        logo: ""

      });
    }

    static parse(data: Object): AdsSellerRadio {
      return new AdsSellerRadio(data); 
    }
  }
  
export default AdsSellerRadio;
