import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  dashedBorder: {
    border: "1px dashed",
    borderColor: theme.palette.primary.main,
    padding: theme.spacing(2),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    marginTop: theme.spacing(1),
  },
  text: {
    marginBottom: theme.spacing(2),
  },
  errorMessage: {
    textAlign: "center",
    fontWeight: "bold !important",    
    color: `${theme.palette.messErr.main} !important`,
  },
  OkMessage: {
    textAlign: "center",
    fontWeight: "bold !important",    
    color: `${theme.palette.success.main} !important`,
  },
}));
