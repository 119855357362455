// @flow
import moment from 'moment';
import i18n from 'i18next';
import { changeNumeralLocale } from './numeral';

export const SUPPORTED_LANGUAGES = ['en', 'fr'];

export const pruneLanguage = (lng: string) => {
  const language = lng.substring(0, 2);
  if (!SUPPORTED_LANGUAGES.some(l => l === language)) {
    return 'en';
  }

  return language;
};

export const setLanguageForLib = (language: string) => {
  moment.locale(language);
  changeNumeralLocale(language);
};

export function getCurrentLanguage() {
  return pruneLanguage(i18n.language);
}

export const switchLanguage = (callBack: Function) => {
  const language = pruneLanguage(i18n.language);
  const newLanguage = SUPPORTED_LANGUAGES.find(l => l !== language);
  i18n.changeLanguage(newLanguage, callBack);
};
