// @flow

export type GeolocationData = {
  region?: string;
  state?: string;
  country?: string;
}

export default class Geolocation {
  region: string;
  state: string;
  country: string;

  constructor(region: string, state: string, country: string) {
    this.region = region;
    this.state = state;
    this.country = country;
  }

  getUniqueId() {
    return `${this.country}:${this.state}:${this.region}`;
  }

  toJSON(): Object {
    return {
      region: this.region,
      state: this.state,
      country: this.country,
    };
  }

  static parse(data: GeolocationData) {
    return new Geolocation(
      data.region || '',
      data.state || '',
      data.country || '',
    );
  }
}
