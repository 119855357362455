import moment from 'moment';
import numeral from 'numeral';

export default function (value, format, lng) {
  if (format === 'uppercase') {
    return value.toUpperCase();
  }

  if (format === 'lowercase') {
    return value.toLowerCase();
  }

  if (moment.isMoment(value)) {
    return value.locale(lng).format(format);
  }

  if (typeof value === 'number') {
    return numeral(Number(value)).format(format);
  }

  return value;
}
