// @flow

import moment from 'moment';
import { BroadcastWeek } from './BroadcastWeek';
import { createEndOfWeek, getIsoMonth, zFill } from './Helpers';

function createWeeks(year: number, month: number): BroadcastWeek[] {
  const firstOfMonth = moment(`${year}-${getIsoMonth(month)}-01`);
  let sunday = createEndOfWeek(firstOfMonth);

  const sundays: moment[] = [];
  while (sunday.month() === month - 1) {
    sundays.push(sunday);
    sunday = sunday.clone().add(1, 'weeks');
  }

  return sundays.map(BroadcastWeek.on);
}

export class BroadcastMonth {
  year: number;
  month: number;
  weeks: BroadcastWeek[];


  static of(year: number, month: number): BroadcastMonth {
    return new BroadcastMonth(year, month);
  }

  static on(date: moment): BroadcastMonth {
    const sunday = createEndOfWeek(date);
    return new BroadcastMonth(sunday.year(), sunday.month() + 1);
  }

  static onWeek(week: BroadcastWeek) {
    return BroadcastMonth.on(week.getStartOfWeek());
  }

  static between({ from, to }: { from: moment, to: moment }): BroadcastMonth[] {
    const firstMonth = BroadcastMonth.on(from);
    const lastMonth = BroadcastMonth.on(to);

    let currentMonth = firstMonth;
    const months = [firstMonth];

    while (!currentMonth.isSame(lastMonth)) {
      currentMonth = currentMonth.next();
      months.push(currentMonth);
    }

    return months;
  }

  static now(): BroadcastMonth {
    return BroadcastMonth.on(moment());
  }

  static parse(value: string): BroadcastMonth {
    const items = value.split('-');
    return BroadcastMonth.of(Number(items[0]), Number(items[1]));
  }

  constructor(year: number, month: number) {
    this.year = year;
    this.month = month;
    this.weeks = createWeeks(year, month);
  }

  next() {
    const lastWeek = this.weeks[this.weeks.length - 1];
    const firstWeekOfNextMonth = lastWeek.next();
    return BroadcastMonth.onWeek(firstWeekOfNextMonth);
  }

  isSame(other: BroadcastMonth) {
    return this.year === other.year && this.month === other.month;
  }

  getYear(): number {
    return this.year;
  }

  getMonth(): number {
    return this.month;
  }

  lastDay(): moment {
    const lastWeek = this.weeks[this.weeks.length - 1];
    return lastWeek.getEndOfWeek();
  }

  firstDay(): moment {
    return this.weeks[0].getStartOfWeek();
  }

  getWeeks(): BroadcastWeek[] {
    return this.weeks;
  }

  format(pattern: string): string {
    return moment(`${this.year}-${zFill(this.month, 2)}-01`).format(pattern);
  }

  toISO(): string {
    return `${this.year}-${zFill(this.month, 2)}`;
  }
}
