import i18n from 'i18next';
import LanguageDetector from "i18next-browser-languagedetector";
import formatter from './formatter';
import { pruneLanguage, setLanguageForLib } from './i18nhelper';
import translations from './general';

i18n
  .use(LanguageDetector)
  .init({
    fallbackLng: ['fr','en'],
    debug: false,

    resources: translations,

    defaultNS: 'general',
    fallbackNS: 'common',

    interpolation: {
      escapeValue: false, // not needed for react
      format: formatter,
    },

    react: {
      defaultTransParent: 'span',
    },

    detection: {
      order: [ 'localStorage', 'navigator', 'htmlTag', 'querystring' ],

      lookupQuerystring: 'lang',
      lookupLocalStorage: 'lang',

      caches: ['localStorage'],
      excludeCacheFor: ['cimode'],
    },
  });

const language = pruneLanguage(i18n.language);

i18n.changeLanguage(language, () => {
  setLanguageForLib(language);
  i18n.on('languageChanged', setLanguageForLib);
});

export default i18n;

