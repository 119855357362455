import * as React from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts';
import styled, { withTheme } from 'styled-components';
import { translate } from 'react-i18next';
import { displayPercent, roundUpToNSignificativeDigits} from '../../common/components/Number';

const PieWrapper = styled.div`
  display: flex;
  flex:1;
  min-height: 170px;
`;

type Props = {
  pieData: Object,
  colors: any,
  animate: ?boolean,

}

export default function GenderPieGraph({ pieData, colors, animate }: Props) {
  return (
    <PieWrapper>
      <ResponsiveContainer height={150} width="99%">
        <PieChart>
          <Pie startAngle={90} endAngle={450} data={pieData} dataKey="value" isAnimationActive={animate}>
            {pieData.map(entry => <Cell key={entry.name} fill={colors[entry.color]} />)}
          </Pie>
          <Tooltip formatter={label => ' '+roundUpToNSignificativeDigits(label*100,2)+'%'} />
        </PieChart>
      </ResponsiveContainer>
    </PieWrapper>
  );
}
