import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  dashedBorder: {
    border: "1px dashed",
    borderColor: theme.palette.primary.main,
    padding: theme.spacing(2),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    marginTop: theme.spacing(1),
  },
  text: {
    marginBottom: theme.spacing(2),
  },
  title: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    marginBottom: theme.spacing(1),
  },  
  controlContainer: {
    display: "flex",
    justifyContent: "space-between",
    margin: theme.spacing(1) * -2,
    marginTop: theme.spacing(1),
  },
  controlCell: {
    padding: theme.spacing(2),
  },
  image1Container: {
    // backgroundColor: theme.palette.primary.main,

    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundSize: "80%",
    backgroundPosition: "center",

    [theme.breakpoints.down("xs")]: {
      //display: "none",
      width: "100%",
      height: "400px !important",
      backgroundSize: "80%",
      backgroundPosition: "center",
    },
    [theme.breakpoints.down("md")]: {
      //display: "none",
      width: "50%",
      height: "400px !important",
      backgroundSize: "100%",
      backgroundPosition: "center",
    },
    [theme.breakpoints.down("sm")]: {
      //display: "none",
      width: "100%",
      height: "400px !important",
      backgroundSize: "70%",
      backgroundPosition: "center",
    },
  },
  container1: {
    height: "21vh",
    width: "30vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    top: 0,
    left: 0,
  },


}));
