// @flow
import api from 'common/services/api';
import type { CustomerInvitation } from '../types/RadioCustomer';
import RadioCustomer from '../types/RadioCustomer';

class CustomersAdsFolloAPI {

  buildHeaders = (token: string): Object => ({ Authorization: 'Bearer ' + token });

  async inviteCustomer(callSign: string, token: string, customer: CustomerInvitation): Promise<RadioCustomer> {
    const url = `/statsradio/radios/${callSign}/adsfollo-customers`;

    const toSend = { ...customer };
    const { data } = await api.post(url, toSend, { headers: this.buildHeaders(token) });

    return RadioCustomer.parse(data);
  }

  async fetchCustomersForStation(callSign: string, token: string): Promise<RadioCustomer[]> {
    const url = `/statsradio/radios/${callSign}/adsfollo-customers`;
    const { data } = await api.get(url, { limit: 999999, page: 1 }, { headers: this.buildHeaders(token) });

    return data.map(customer => RadioCustomer.parse(customer));
  }

  async editCustomer(callSign: string, token: string, customer: RadioCustomer): Promise<RadioCustomer[]> {
    const url = `/statsradio/radios/${callSign}/adsfollo-customers/${customer.getReference()}`;

    const { data } = await api.post(url, customer.toObject(), { headers: this.buildHeaders(token) });

    return data.map(radioCustomer => RadioCustomer.parse(radioCustomer));
  }
}


export default new CustomersAdsFolloAPI();
