// @flow

import moment from 'moment';

export function zFill(data: any, size: number) {
  let value = data + '';

  while (value.length < size) {
    value = '0' + value;
  }

  return value;
}

export function getIsoMonth(month: number) {
  return zFill(month.valueOf(), 2);
}

export function getFirstSundayOf(year: number) {
  const startOfYear = moment(`${year}-${getIsoMonth(1)}-01`);
  return createEndOfWeek(startOfYear);
}

export function createEndOfWeek(date: moment): moment {
  return date.clone().endOf('isoWeek');
}

export function createStartOfDay(date: moment): moment {
  return date.clone().startOf('isoWeek');
}
