// @flow
import DateRange from 'common/domain/DateRange';

type Data = {
  reference: string,
  callSign: string,
  name: ?string,
  description: ?string,
  dateRange: ?DateRange,
  customerReference: ?string,
  salesmanReference: ?string,
};

export default class ExternalCampaign {

  reference: string;
  callSign: string;
  name: ?string;
  description: ?string;
  dateRange: ?DateRange;
  customerReference: ?string;
  salesmanReference: ?string;

  constructor(data: Data) {
    this.reference = data.reference;
    this.callSign = data.callSign;
    this.name = data.name;
    this.description = data.description;
    this.dateRange = data.dateRange;
    this.customerReference = data.customerReference;
    this.salesmanReference = data.salesmanReference;
  }

  toListing(): ExternalCampaignListing {
    return new ExternalCampaignListing({
      reference: this.reference,
      name: this.name || undefined,
    });
  }

  static parse({ reference, callSign, name, description, from, to, salesmanReference, customerReference }: any) {
    return new ExternalCampaign({
      reference,
      callSign,
      name,
      description,
      dateRange: from && to && DateRange.fromISO({ from, to }),
      salesmanReference,
      customerReference,
    });
  }
}

export function sortByName(campaigns: ExternalCampaignListing[]): ExternalCampaignListing[] {
  return campaigns.sort((a, b) => a.compareNames(b));
}

export class ExternalCampaignListing {

  reference: string;
  name: string | void;

  constructor({ reference, name }: $ReadOnly<ExternalCampaignListing>) {
    this.reference = reference;
    this.name = name;
  }

  getReference() {
    return this.reference;
  }

  getName() {
    return this.name || `ref. ${this.reference}`;
  }

  compareNames(other: ExternalCampaignListing) {
    return this.getName().localeCompare(other.getName());
  }

  static from(campaign: ExternalCampaign) {
    return new ExternalCampaignListing({ reference: campaign.reference, name: campaign.name || undefined });
  }
}
