// @flow
import moment from 'moment';
import DateRange from 'common/domain/DateRange';
import Advertiser from 'common/modules/advertiser/domain/Advertiser';
import Salesman from 'common/modules/salesman/domain/Salesman';

export type CampaignListingData = {
  reference: string;
  name: string;
  callSign: string;

  dateRange: DateRange;
  completedAt: ?moment;
  totalCost: number;
  linkedExternalCampaigns: string[];

  salesman: Salesman;
  advertiser: Advertiser;
}

export default class CampaignListing {

  reference: string;
  name: string;
  callSign: string;

  totalCost: number;
  dateRange: DateRange;
  completedAt: ?moment;
  linkedExternalCampaigns: string[];

  salesman: Salesman;
  advertiser: Advertiser;

  constructor(data: CampaignListingData) {
    this.reference = data.reference;
    this.name = data.name;
    this.callSign = data.callSign;
    this.dateRange = data.dateRange;
    this.completedAt = data.completedAt;
    this.totalCost = data.totalCost;
    this.linkedExternalCampaigns = data.linkedExternalCampaigns;
    this.salesman = data.salesman;
    this.advertiser = data.advertiser;
  }

  hasReference(reference: string) {
    return this.reference === reference;
  }

  getAdvertiserName() {
    return this.advertiser.name;
  }

  isBooked(): boolean {
    return this.dateRange.from.isAfter(moment());
  }

  isRunning(): boolean {
    return !this.isBooked() && !this.isCompleted();
  }

  isCompleted(): boolean {
    return Boolean(this.completedAt);
  }

  shouldHaveCompleted(): boolean {
    return this.dateRange.to.isBefore(moment());
  }

  isLinked(): boolean {
    return Boolean(this.linkedExternalCampaigns && this.linkedExternalCampaigns.length > 0);
  }

  static parse(data: Object) {
    return new CampaignListing({
      reference: data.reference,
      name: data.name,
      callSign: data.callSign,
      dateRange: DateRange.fromISO({ from: data.from, to: data.to }),
      completedAt: data.completedAt && moment(data.completedAt),
      totalCost: data.totalCost,
      linkedExternalCampaigns: data.linkedExternalCampaigns || [],
      salesman: Salesman.parse(data.salesman),
      advertiser: Advertiser.parse(data.advertiser),
    });
  }
}
