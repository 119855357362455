// @flow
import User from 'common/domain/user';
import api from './api';

class UserAPI {

  buildHeaders = (token: string) => ({ Authorization: 'Bearer ' + token });

  async fetchGeneralUserInformation(token: string) {
    const url = '/statsradio/users/me';
    const { data } = await api.get(url, {}, {
      headers: this.buildHeaders(token),
    });

    return User.parse(data);
  }

  async fetchUserInformationForStation(token: string, callSign: string) {
    const url = `/statsradio/users/${callSign}/me`;

    const { data } = await api.get(url, {}, {
      headers: this.buildHeaders(token),
    });

    return data;
  }
}

export default new UserAPI();
