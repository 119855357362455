// @flow
import  CampaignData  from 'domain/CampaignType';

const CAMPAIGN_TOKEN_KEY = 'CampaignToken';

class CampaignStorage {

  storage: Storage;

  constructor(storage: Storage) {
    this.storage = storage;
  }

  retrieveNotExpired = (callSign:String): CampaignData => {
    const campaignToken = CampaignData.fromJson(this.storage.getItem(CAMPAIGN_TOKEN_KEY+"_"+callSign));

    if (campaignToken && !campaignToken.isExpired()) {
      return campaignToken;
    }

    this.clear(callSign);
    return undefined;
  }

  updateCampaignToken = (campaignToken: CampaignData, callSign:String) => {
    this.storage.setItem(CAMPAIGN_TOKEN_KEY+"_"+callSign, CampaignData.toJson(campaignToken));
  }

  clear = (callSign:String) => {
    this.storage.removeItem(CAMPAIGN_TOKEN_KEY+"_"+callSign);
  }

}

export default new CampaignStorage(window.localStorage);
