// @flow
import moment from 'moment';

import { ALL_WEEK } from 'common/domain/week';
import type { DayOfWeek } from 'common/domain/DayOfWeek';
import { ISO_DATE } from 'common/services/api';

import DateRange from '../../../domain/DateRange';

const TIME_FORMAT = 'HH:mm:ss';

type OpportunityData = {
  queryId: number,
  startTime: moment,
  endTime: moment,
  numberOfOpportunities: number,
  costPerOpportunities: number,
  totalCost: number,
  days: Array<DayOfWeek>,
  reach?: ?number,
  impressions?: ?number,
  costPerThousand?: ?number,
  averageFrequency?: ?number,
  fromDate: moment,
  toDate: moment,
  description: string,
}

export default class Opportunity {
  queryId: number;
  startTime: moment;
  endTime: moment;
  numberOfOpportunities: number;
  costPerOpportunities: number;
  totalCost: ?number;
  days: Array<DayOfWeek>;
  reach: ?number;
  impressions: ?number;
  costPerThousand: ?number;
  averageFrequency: ?number;
  fromDate: moment;
  toDate: moment;
  description: string;

  constructor(data: OpportunityData) {
    this.queryId = data.queryId;
    this.startTime = data.startTime;
    this.endTime = data.endTime;
    this.numberOfOpportunities = data.numberOfOpportunities;
    this.costPerOpportunities = data.costPerOpportunities;
    this.totalCost = data.totalCost;
    this.days = data.days;
    this.reach = data.reach;
    this.impressions = data.impressions;
    this.costPerThousand = data.costPerThousand;
    this.averageFrequency = data.averageFrequency;
    this.fromDate = data.fromDate;
    this.toDate = data.toDate;
    this.description = data.description;
  }

  getDateRange() {
    return new DateRange({ from: this.fromDate, to: this.toDate });
  }

  toJSON(): Object {
    return {
      ...this,
      startTime: this.startTime && this.startTime.format(TIME_FORMAT),
      endTime: this.endTime && this.endTime.format(TIME_FORMAT),
      fromDate: this.fromDate.format(ISO_DATE),
      toDate: this.toDate.format(ISO_DATE),
    };
  }

  static parse(data: Object): Opportunity {
    return new Opportunity({
      queryId: data.queryId,
      startTime: data.startTime && moment(data.startTime, TIME_FORMAT),
      endTime: data.endTime && moment(data.endTime, TIME_FORMAT),
      numberOfOpportunities: data.numberOfOpportunities,
      costPerOpportunities: data.costPerOpportunities,
      totalCost: data.totalCost,
      days: data.days,
      reach: data.reach,
      impressions: data.impressions,
      costPerThousand: data.costPerThousand,
      averageFrequency: data.averageFrequency,
      fromDate: moment(data.fromDate, ISO_DATE),
      toDate: moment(data.toDate, ISO_DATE),
      description: data.description,
    });
  }

  static createDefault(id: number = 1, dateRange: DateRange) {
    return new Opportunity({
      queryId: id,
      numberOfOpportunities: 1,
      costPerOpportunities: 0,
      startTime: moment().startOf('day').hour(5),
      endTime: moment().startOf('day').hour(22).subtract(15, 'minute'),
      days: ALL_WEEK,
      fromDate: dateRange.from,
      toDate: dateRange.to,
      description: '',
      totalCost: 0,
    });
  }
}
