// @flow
type UserProfileData = {
  surname: string;
  name: string;
  language: string;
  dailyNotificationConfiguration: boolean;
  weeklyNotificationConfiguration: boolean;
}

class UserProfile {
  surname: string;
  name: string;
  language: string;
  dailyNotificationConfiguration: boolean;
  weeklyNotificationConfiguration: boolean;

  constructor(data: UserProfileData) {
    this.name = data.name;
    this.surname = data.surname;
    this.language = data.language;
    this.dailyNotificationConfiguration = data.dailyNotificationConfiguration;
    this.weeklyNotificationConfiguration = data.weeklyNotificationConfiguration;
  }

  getLanguage(): string {
    return this.language;
  }

  hasLanguage(language: string): boolean {
    return this.language === language;
  }

  toObject(): Object {
    return {
      name: this.name,
      surname: this.surname,
      language: this.language,
    };
  }

  static parse(data: Object): UserProfile {
    return new UserProfile({ name: data.name,
      surname: data.surname,
      language: data.language,
      dailyNotificationConfiguration: data.notificationConfiguration.dailyNotificationConfiguration,
      weeklyNotificationConfiguration: data.notificationConfiguration.weeklyNotificationConfiguration,
    });
  }
}

export default UserProfile;
